import styled from 'styled-components';
import { shade } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
  min-width: 140px;
  max-width: 140px;
  min-height: 150px;
  max-height: 150px;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  .file-box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    svg {
      font-size: 60px;
      color: ${colors.accent1};
    }
  }
  .file-box-footer {
    display: flex;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 5px;
    color: ${colors.accent1};
    font-size: 14px;
    text-align: center;
  }
  > button {
    color: ${colors.white};
    font-size: 12px;
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 6px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > button.file-box-download {
    background-color: ${colors.primary};
    top: 10px;
    left: 10px;
    &:hover {
      background-color: ${shade(0.2, colors.primary)};
    }
  }
  > button.file-box-edit {
    background-color: ${colors.primary};
    top: 40px;
    left: 10px;
    &:hover {
      background-color: ${shade(0.2, colors.primary)};
    }
  }
  > button.file-box-delete {
    background-color: ${colors.danger};
    top: 10px;
    right: 10px;
    &:hover {
      background-color: ${shade(0.2, colors.danger)};
    }
  }
`;
