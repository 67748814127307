/* eslint-disable react/no-array-index-key */
import React from 'react';
import { FaPlus, FaPen, FaTrash } from 'react-icons/fa';
// import { FaTrash, FaEye, FaPen, FaShare, FaInfo } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import icon from '~/assets/images/icons/notification.svg';

import { Container, AddSetting, Table } from './styles';
import HasPermission from '~/components/HasPermission';

export default function SettingItem({
  title,
  subtitle,
  settings,
  onNew,
  onEdit,
  onRemove,
  id,
}) {
  const { t } = useTranslation();

  const weekNames = [
    t('settings.setting-item.week-names.sunday'),
    t('settings.setting-item.week-names.monday'),
    t('settings.setting-item.week-names.tuesday'),
    t('settings.setting-item.week-names.wednesday'),
    t('settings.setting-item.week-names.thursday'),
    t('settings.setting-item.week-names.friday'),
    t('settings.setting-item.week-names.saturday'),
  ];

  return (
    <Container id={id}>
      <div className="container-icon">
        <img src={icon} alt="Logo" />
      </div>
      <div className="container-body">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
        <h3>{t('settings.setting-item.notifications')}</h3>
        <Table>
          <tbody>
            {settings.map((item, index) => (
              <tr key={index}>
                <td>
                  {`
                      ${item.hour} -
                      ${item.periods.map(week => weekNames[week]).join(', ')}
                    `}
                </td>
                <td width="1%">
                  <div className="table-action">
                    <HasPermission>
                      <button
                        data-tip={t('drive.drive-item.edit')}
                        type="button"
                        className="table-action-edit"
                        onClick={() => {
                          onEdit(index);
                        }}
                      >
                        <FaPen />
                      </button>
                      <button
                        data-tip={t('drive.drive-item.delete')}
                        type="button"
                        className="table-action-delete"
                        onClick={() => {
                          onRemove(index);
                        }}
                      >
                        <FaTrash />
                      </button>
                    </HasPermission>
                  </div>
                </td>
              </tr>
            ))}
            {settings.length === 0 && (
              <tr className="table-no-holver">
                <td align="center">
                  {t('settings.setting-item.no-registered-settings')}
                </td>
              </tr>
            )}
            <HasPermission>
              <tr className="table-no-holver">
                <td colSpan={2} style={{ paddingBottom: 0 }}>
                  <AddSetting
                    title={t('settings.setting-item.new-configuration')}
                    icon={FaPlus}
                    onClick={() => {
                      onNew();
                    }}
                  />
                </td>
              </tr>
            </HasPermission>
          </tbody>
        </Table>
      </div>
    </Container>
  );
}

SettingItem.defaultProps = {
  onNew: () => {},
  onEdit: () => {},
  onRemove: () => {},
  id: '',
};

SettingItem.propTypes = {
  settings: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  onNew: PropTypes.func,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};
