import amex from './image/amex.png';
import diners from './image/diners.png';
import discover from './image/discover.png';
import elo from './image/elo.png';
import hipercard from './image/hipercard.png';
import mastercard from './image/mastercard.png';
import visa from './image/visa.png';

const brandList = [
  {
    label: 'MasterCard',
    active: true,
    image: mastercard,
    brand: 'Master',
    mask: '9999 9999 9999 9999',
  },
  {
    label: 'Visa',
    active: true,
    image: visa,
    brand: 'Visa',
    mask: '9999 9999 9999 9999',
  },
  {
    label: 'Elo',
    active: true,
    image: elo,
    brand: 'Elo',
    mask: '9999 9999 9999 9999',
  },
  {
    label: 'Amex',
    active: true,
    image: amex,
    brand: 'Amex',
    mask: '9999 999999 99999',
  },
  {
    label: 'Aura',
    active: false,
    image: '',
    brand: 'Aura',
    mask: '9999 9999 9999 9999',
  },
  {
    label: 'JCB',
    active: false,
    image: '',
    brand: 'JCB',
    mask: '9999 9999 9999 9999',
  },
  {
    label: 'Diners',
    active: true,
    image: diners,
    brand: 'Diners',
    mask: '9999 999999 9999',
  },
  {
    label: 'Discover',
    active: true,
    image: discover,
    brand: 'Discover',
    mask: '9999 9999 9999 9999',
  },
  {
    label: 'Hipercard',
    active: true,
    image: hipercard,
    brand: 'Hipercard',
    mask: '9999 9999 9999 9999',
  },
  { label: 'Hiper', active: false, image: '', brand: 'Hiper', mask: '' },
];

export default brandList.filter(i => i.active);
