import types from './types';

// Subject

export function subjectRequest(periodId) {
  return {
    type: types.SUBJECT_REQUEST,
    payload: { periodId },
  };
}

export function subjectRequestSuccess(subjects) {
  return {
    type: types.SUBJECT_REQUEST_SUCCESS,
    payload: { subjects },
  };
}

export function subjectSetEdit(subjectEdit) {
  return {
    type: types.SUBJECT_SET_EDIT,
    payload: { subjectEdit },
  };
}

export function updateSubjectRequest(subject) {
  return {
    type: types.UPDATE_SUBJECT_REQUEST,
    payload: { subject },
  };
}

export function subjectSetModalCreate(subjectModalCreate) {
  return {
    type: types.SUBJECT_SET_MODAL_CREATE,
    payload: { subjectModalCreate },
  };
}

export function createSubjectRequest(subject) {
  return {
    type: types.CREATE_SUBJECT_REQUEST,
    payload: { subject },
  };
}

export function deleteSubjectRequest(subject) {
  return {
    type: types.DELETE_SUBJECT_REQUEST,
    payload: { subject },
  };
}

// Test

export function subjectTestRequest(subjectId) {
  return {
    type: types.TESTS_REQUEST,
    payload: { subjectId },
  };
}

export function subjectTestRequestSuccess(tests) {
  return {
    type: types.TESTS_REQUEST_SUCCESS,
    payload: { tests },
  };
}

export function subjectTestSet(testForm) {
  return {
    type: types.TEST_SET,
    payload: { testForm },
  };
}

export function createTestRequest(test) {
  return {
    type: types.CREATE_TEST_REQUEST,
    payload: { test },
  };
}

export function updateTestRequest(test) {
  return {
    type: types.UPDATE_TEST_REQUEST,
    payload: { test },
  };
}

export function deleteTestRequest(test) {
  return {
    type: types.DELETE_TEST_REQUEST,
    payload: { test },
  };
}

// Work

export function subjectWorkRequest(subjectId) {
  return {
    type: types.WORKS_REQUEST,
    payload: { subjectId },
  };
}

export function subjectWorkRequestSuccess(works) {
  return {
    type: types.WORKS_REQUEST_SUCCESS,
    payload: { works },
  };
}

export function subjectWorkSet(workForm) {
  return {
    type: types.WORK_SET,
    payload: { workForm },
  };
}

export function createWorkRequest(work) {
  return {
    type: types.CREATE_WORK_REQUEST,
    payload: { work },
  };
}

export function updateWorkRequest(work) {
  return {
    type: types.UPDATE_WORK_REQUEST,
    payload: { work },
  };
}

export function deleteWorkRequest(work) {
  return {
    type: types.DELETE_WORK_REQUEST,
    payload: { work },
  };
}

// PDF

export function exportPdfClasses(classList) {
  return {
    type: types.EXPORT_PDF_CLASSES,
    payload: { classList },
  };
}
