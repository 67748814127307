import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import period from './period/sagas';
import subject from './subject/sagas';
import classe from './class/sagas';
import friend from './friend/sagas';
import certificate from './certificate/sagas';
import drive from './drive/sagas';
import faq from './faq/sagas';
import shared from './shared/sagas';
import agenda from './agenda/sagas';
import studyContent from './studyContent/sagas';
import chat from './chat/sagas';
import search from './search/sagas';
import dashboard from './dashboard/sagas';
import tour from './tour/sagas';
import myPlan from './myPlan/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    period,
    subject,
    classe,
    friend,
    certificate,
    drive,
    faq,
    shared,
    agenda,
    studyContent,
    chat,
    search,
    dashboard,
    tour,
    myPlan,
  ]);
}
