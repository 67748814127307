import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import {
  dashboardRequestSuccess,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* requestDashboard() {
  try {
    const response = yield call(api.get, 'dashboard');
    yield put(dashboardRequestSuccess(response.data));
  } catch (error) {
    //
  }
}

export default all([
  takeLatest(types.DASHBOARD_REQUEST, requestDashboard),
]);
