import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import CPF from 'cpf-check';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  FaCheck,
  FaSignature,
  FaIdCard,
  FaUniversity,
  FaToolbox,
  FaMobileAlt,
  FaImage,
  FaRegEnvelope,
} from 'react-icons/fa';
import { AiFillLock } from 'react-icons/ai';
import { MdGTranslate, MdAccessTime, MdDateRange } from 'react-icons/md';
import config from '~/config';

import colors from '~/styles/colors';
import { updateProfileRequest } from '~/store/modules/user/actions';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import { languagesList, getValidationErrors } from '~/utils';

import {
  HeaderButton,
  Unform,
  UnformInputFile,
  UnformInput,
  UnformSelect,
  // ///
  // Content
  Content,
  AvatarBox,
  Avatar,
  ImageBox,
  ProfileBox,
  InputGroup,
  ProfileTitle,
} from './styles';

const Profile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);
  const [avatar, setAvatar] = useState(null);
  const profile = useSelector(state => state.user.profile);

  const schema = Yup.object().shape({
    // UserData validation
    name: Yup.string().required(t('profile.field.name.message.required')),
    document: Yup.string()
      // .test('cpf', t('profile.field.document.message.test'), value =>
      //   CPF.validate(value)
      // )
      .required(t('profile.field.document.message.required')),
    email: Yup.string()
      .email(t('profile.field.email.message.email'))
      .required(t('profile.field.email.message.required')),
    password: Yup.string(),
    language: Yup.string().required(
      t('profile.field.language.message.required')
    ),
    college: Yup.string().required(t('profile.field.college.message.required')),
    careers: Yup.string().required(t('profile.field.careers.message.required')),
    cell_phone: Yup.string().required(
      t('profile.field.cell-phone.message.required')
    ),
    avatar: Yup.string(),
    // Addres validation
    locale_config: Yup.object({
      dateFormat: Yup.string().required(
        t('profile.field.locale_config.dateFormat.message.required')
      ),
      tzCode: Yup.string().required(
        t('profile.field.locale_config.tzCode.message.required')
      ),
    }),
  });

  const handleSubmit = data => {
    const user = data;
    if (!avatar) {
      delete user.avatar;
    }
    dispatch(
      updateProfileRequest({
        id: profile.id,
        role: profile.role,
        ...user,
      })
    );
  };

  const renderHeader = () => (
    <PageHeader
      breadcrumb={[t('profile.breadcrumb.1')]}
      actionElements={() => (
        <HeaderButton
          type="button"
          title={t('profile.save')}
          icon={FaCheck}
          onClick={() => {
            formRef.current.submitForm();
          }}
          color={colors.secondary}
        />
      )}
    />
  );

  const renderContent = () => (
    <>
      <Unform
        onSubmit={async data => {
          console.log('data', data);
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            console.log('err', err);
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={profile}
        ref={formRef}
      >
        <Content>
          <AvatarBox>
            <ImageBox>
              <Avatar src={avatar || profile.avatar_url} />
              <UnformInputFile
                icon={FaImage}
                name="avatar"
                accept="image/*"
                title={t('profile.field.avatar.label')}
                onStartProgress={setAvatar}
                maxSize={1000000}
              />
            </ImageBox>
            <ProfileBox>
              <h1>{profile.name}</h1>
              <h2>{profile.email}</h2>
            </ProfileBox>
          </AvatarBox>
          <InputGroup>
            <ProfileTitle>{t('profile.register')}</ProfileTitle>
            <UnformInput
              icon={FaSignature}
              type="text"
              label={t('profile.field.name.label')}
              name="name"
              style={{ marginBottom: 20 }}
            />
            <UnformInput
              icon={FaIdCard}
              type="text"
              label={t('profile.field.document.label')}
              name="document"
              style={{ marginBottom: 20 }}
            />
            <UnformInput
              icon={FaRegEnvelope}
              type="text"
              label={t('profile.field.email.label')}
              name="email"
              style={{ marginBottom: 20 }}
            />
            <UnformInput
              icon={AiFillLock}
              type="password"
              label={t('profile.field.password.label')}
              name="password"
              style={{ marginBottom: 20 }}
            />
            <UnformSelect
              icon={MdGTranslate}
              label={t('profile.field.language.label')}
              name="language"
              style={{ marginBottom: 20 }}
              options={languagesList.map(item => ({
                label: t(`language.${item}`),
                value: item,
              }))}
            />
            <UnformInput
              icon={FaUniversity}
              type="text"
              label={t('profile.field.college.label')}
              name="college"
              style={{ marginBottom: 20 }}
            />
            <UnformInput
              icon={FaToolbox}
              type="text"
              label={t('profile.field.careers.label')}
              name="careers"
              style={{ marginBottom: 20 }}
            />
            <UnformInput
              icon={FaMobileAlt}
              type="text"
              label={t('profile.field.cell-phone.label')}
              name="cell_phone"
            />
            <ProfileTitle>
              {t('profile.field.locale_config.section')}
            </ProfileTitle>
            <UnformSelect
              icon={MdAccessTime}
              label={t('profile.field.locale_config.tzCode.label')}
              name="locale_config.tzCode"
              style={{ marginBottom: 20 }}
              options={config.timezones}
              menuPlacement="top"
            />
            <UnformSelect
              icon={MdDateRange}
              label={t('profile.field.locale_config.dateFormat.label')}
              name="locale_config.dateFormat"
              style={{ marginBottom: 20 }}
              options={config.formatDates.map(i => ({
                ...i,
                label: `${i.label} (${moment().format(i.value)})`,
              }))}
              menuPlacement="top"
            />
          </InputGroup>
        </Content>
      </Unform>
    </>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>{renderContent()}</PageContent>
    </>
    /* <Form onSubmit={handleSubmit} schema={schema} initialData={profile}>
      {renderHeader()}
      {renderContent()}
    </Form>
    */
  );
};

export default Profile;
