import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import {
  // FaHome,
  FaUser,
  FaUsers,
  FaInfoCircle,
  FaBook,
  FaRegCalendarCheck,
  FaCertificate,
  FaFolder,
  FaShareSquare,
  FaCogs,
  FaSignOutAlt,
  FaRegCopy,
  // FaSyncAlt,
} from 'react-icons/fa';
import { AiOutlineShopping } from 'react-icons/ai';
import { BsInfoCircleFill } from 'react-icons/bs';

import { dashboardRequest } from '~/store/modules/dashboard/actions';
import { verifyExpired } from '~/store/modules/myPlan/actions';
import history from '~/services/history';
import colors from '~/styles/colors';
import {
  Container,
  Menu,
  MenuItem,
  ProfileButton,
  ProfilePopupBox,
  Avatar,
  // SyncButton
} from './styles';

export default function MenuLeft({ disabled }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profile = useSelector(state => state.user.profile);
  const dashboard = useSelector(state => state.dashboard.dashboard);
  const { expiredSubscription } = useSelector(state => state.myPlan);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const menuList = [
    {
      path: '/periods',
      icon: FaBook,
      label: t('menu-left.my-notebook'),
      badge: 0,
      class: 'left-menu-my-notebook',
      hasInfo: false,
    },
    {
      path: '/study-for-test',
      icon: FaRegCopy,
      label: t('menu-left.study-for-test'),
      badge: 0,
      class: 'left-menu-study-for-test',
      hasInfo: false,
    },
    {
      path: '/friends',
      icon: FaUsers,
      label: t('menu-left.friends'),
      badge: dashboard.invites,
      class: 'left-menu-friends',
      hasInfo: false,
    },
    {
      path: '/agenda',
      icon: FaRegCalendarCheck,
      label: t('menu-left.agenda'),
      badge: 0,
      class: 'left-menu-agenda',
      hasInfo: false,
    },
    {
      path: '/certificates',
      icon: FaCertificate,
      label: t('menu-left.my-certificates'),
      badge: 0,
      class: 'left-menu-my-certificates',
      hasInfo: false,
    },
    {
      path: '/drive',
      icon: FaFolder,
      label: t('menu-left.my-files'),
      badge: 0,
      class: 'left-menu-my-files',
      hasInfo: false,
    },
    {
      path: '/shared-with-me',
      icon: FaShareSquare,
      label: t('menu-left.shared-with-me'),
      badge: dashboard.sharedWithMe,
      class: 'left-menu-shared-with-me',
      hasInfo: false,
    },
    {
      path: '/settings',
      icon: FaCogs,
      label: t('menu-left.configure-notification'),
      badge: 0,
      class: 'left-menu-configure-notification',
      hasInfo: false,
    },
    {
      path: '/help',
      icon: FaInfoCircle,
      label: t('menu-left.help'),
      badge: 0,
      class: 'left-menu-help',
      hasInfo: false,
    },
    {
      path: '/my-plan',
      icon: AiOutlineShopping,
      label: t('menu-left.my-plan'),
      badge: 0,
      class: 'left-menu-my-plan',
      hasInfo: expiredSubscription,
    },
  ];

  const loadDashboardData = () => {
    dispatch(dashboardRequest());
    dispatch(verifyExpired());
    setInterval(() => {
      dispatch(dashboardRequest());
    }, 30000);
  };

  useEffect(loadDashboardData, []);

  return (
    <Container>
      <Menu disabled={disabled}>
        {menuList.map(item => (
          <MenuItem to={item.path} exact className={item.class}>
            <item.icon />
            {item.label}
            {!!item.badge && item.badge > 0 && (
              <span className="menu-badge">{item.badge}</span>
            )}
            {!!item.hasInfo && <BsInfoCircleFill className="menu-info" />}
          </MenuItem>
        ))}
      </Menu>
      <Popover
        isOpen={isPopoverOpen}
        position={['top', 'right', 'left', 'bottom']}
        onClickOutside={() => setIsPopoverOpen(false)}
        padding={0}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={colors.white}
            arrowSize={10}
          >
            <ProfilePopupBox>
              <button type="button" onClick={() => history.push('/profile')}>
                <FaUser />
                {t('profile.breadcrumb.1')}
              </button>
              <button type="button" onClick={() => history.push('/signOut')}>
                <FaSignOutAlt />
                {t('profile.exit')}
              </button>
            </ProfilePopupBox>
          </ArrowContainer>
        )}
      >
        <ProfileButton
          className="left-menu-profile"
          disabled={disabled}
          onClick={() => {
            setIsPopoverOpen(true);
          }}
        >
          <Avatar src={profile.avatar_url || ''} />
          <span>{profile.name ? profile.name.substring(26, 0) : ''}</span>
        </ProfileButton>
      </Popover>
    </Container>
  );
}

MenuLeft.defaultProps = {
  disabled: false,
};

MenuLeft.propTypes = {
  disabled: PropTypes.bool,
};
