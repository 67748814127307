import types from './types';

export function periodRequest() {
  return {
    type: types.PERIOD_REQUEST,
  };
}

export function periodRequestSuccess(periods) {
  return {
    type: types.PERIOD_REQUEST_SUCCESS,
    payload: { periods },
  };
}

export function periodSetEdit(periodEdit) {
  return {
    type: types.PERIOD_SET_EDIT,
    payload: { periodEdit },
  };
}

export function updatePeriodRequest(period) {
  return {
    type: types.UPDATE_PERIOD_REQUEST,
    payload: { period },
  };
}

export function periodSetModalCreate(periodModalCreate) {
  return {
    type: types.PERIOD_SET_MODAL_CREATE,
    payload: { periodModalCreate },
  };
}

export function createPeriodRequest(period) {
  return {
    type: types.CREATE_PERIOD_REQUEST,
    payload: { period },
  };
}

export function deletePeriodRequest(periodId) {
  return {
    type: types.DELETE_PERIOD_REQUEST,
    payload: { periodId },
  };
}
