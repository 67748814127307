import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.img`
  display: flex;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  min-width: ${props => props.width}px;
  min-height: ${props => props.height}px;
  border-radius: 50%;
  background-color: ${colors.white};
  -webkit-box-shadow: 0px 0px 8px 0px ${colors.black}70;
  -moz-box-shadow: 0px 0px 8px 0px ${colors.black}70;
  box-shadow: 0px 0px 8px 0px ${colors.black}70;
  object-fit: cover;
  padding: ${props => props.padding}px;
`;
