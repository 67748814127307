import types from './types';

export function certificateRequest() {
  return {
    type: types.CERTIFICATE_REQUEST,
  };
}

export function certificateRequestSuccess(certificates) {
  return {
    type: types.CERTIFICATE_REQUEST_SUCCESS,
    payload: { certificates },
  };
}

export function certificateSetEdit(certificateEdit) {
  return {
    type: types.CERTIFICATE_SET_EDIT,
    payload: { certificateEdit },
  };
}

export function updateCertificateRequest(certificate) {
  return {
    type: types.UPDATE_CERTIFICATE_REQUEST,
    payload: { certificate },
  };
}

export function certificateSetModalCreate(certificateModalCreate) {
  return {
    type: types.CERTIFICATE_SET_MODAL_CREATE,
    payload: { certificateModalCreate },
  };
}

export function createCertificateRequest(certificate) {
  return {
    type: types.CREATE_CERTIFICATE_REQUEST,
    payload: { certificate },
  };
}

export function deleteCertificateRequest(certificateId) {
  return {
    type: types.DELETE_CERTIFICATE_REQUEST,
    payload: { certificateId },
  };
}
