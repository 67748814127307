import types from './types';

// Class
export function classesRequest(subjectId) {
  return {
    type: types.CLASSES_REQUEST,
    payload: { subjectId },
  };
}

export function classesRequestSuccess(classes) {
  return {
    type: types.CLASSES_REQUEST_SUCCESS,
    payload: { classes },
  };
}

export function deleteClassRequest(classe) {
  return {
    type: types.DELETE_CLASS_REQUEST,
    payload: { classe },
  };
}

export function classRequest(classId) {
  return {
    type: types.CLASS_REQUEST,
    payload: { classId },
  };
}

export function classRequestSuccess(classe) {
  return {
    type: types.CLASS_REQUEST_SUCCESS,
    payload: { classe },
  };
}

export function updateClassRequest(classe) {
  return {
    type: types.UPDATE_CLASS_REQUEST,
    payload: { classe },
  };
}

export function classSetEdit(classeEdit) {
  return {
    type: types.CLASS_SET_EDIT,
    payload: { classeEdit },
  };
}

export function classSetModalCreate(classeModalCreate) {
  return {
    type: types.CLASS_SET_MODAL_CREATE,
    payload: { classeModalCreate },
  };
}

export function createClassRequest(classe) {
  return {
    type: types.CREATE_CLASS_REQUEST,
    payload: { classe },
  };
}

// Question

export function classQuestionRequest(classId) {
  return {
    type: types.QUESTIONS_REQUEST,
    payload: { classId },
  };
}

export function classQuestionRequestSuccess(questions) {
  return {
    type: types.QUESTIONS_REQUEST_SUCCESS,
    payload: { questions },
  };
}

export function classQuestionSet(questionForm) {
  return {
    type: types.QUESTION_SET,
    payload: { questionForm },
  };
}

export function createQuestionRequest(question) {
  return {
    type: types.CREATE_QUESTION_REQUEST,
    payload: { question },
  };
}

export function updateQuestionRequest(question) {
  return {
    type: types.UPDATE_QUESTION_REQUEST,
    payload: { question },
  };
}

export function deleteQuestionRequest(question) {
  return {
    type: types.DELETE_QUESTION_REQUEST,
    payload: { question },
  };
}

// Keyword

export function classKeywordRequest(classId) {
  return {
    type: types.KEYWORDS_REQUEST,
    payload: { classId },
  };
}

export function classKeywordRequestSuccess(keywords) {
  return {
    type: types.KEYWORDS_REQUEST_SUCCESS,
    payload: { keywords },
  };
}

export function classKeywordSet(keywordForm) {
  return {
    type: types.KEYWORD_SET,
    payload: { keywordForm },
  };
}

export function createKeywordRequest(keyword) {
  return {
    type: types.CREATE_KEYWORD_REQUEST,
    payload: { keyword },
  };
}

export function updateKeywordRequest(keyword) {
  return {
    type: types.UPDATE_KEYWORD_REQUEST,
    payload: { keyword },
  };
}

export function deleteKeywordRequest(keyword) {
  return {
    type: types.DELETE_KEYWORD_REQUEST,
    payload: { keyword },
  };
}

// Files

export function classFileRequest(classId) {
  return {
    type: types.FILES_REQUEST,
    payload: { classId },
  };
}

export function classFileRequestSuccess(files) {
  return {
    type: types.FILES_REQUEST_SUCCESS,
    payload: { files },
  };
}

export function classFileSetCreate(fileCreate) {
  return {
    type: types.FILES_SET_CREATE,
    payload: { fileCreate },
  };
}

export function classFileCreate(classId, file) {
  return {
    type: types.FILES_CREATE,
    payload: { classId, file },
  };
}

export function classFileUpdate(fileData) {
  return {
    type: types.FILES_UPDATE,
    payload: { fileData },
  };
}

export function classSetFileUpdate(formFileEdit) {
  return {
    type: types.FILES_SET_UPDATE,
    payload: { formFileEdit },
  };
}

export function classFileDelete(file) {
  return {
    type: types.FILES_DELETE,
    payload: { file },
  };
}

// Mindmap

export function classMindmapRequest(classId) {
  return {
    type: types.MINDMAP_REQUEST,
    payload: { classId },
  };
}

export function classMindmapRequestSuccess(mindmaps) {
  return {
    type: types.MINDMAP_REQUEST_SUCCESS,
    payload: { mindmaps },
  };
}

export function classMindmapSet(mindmapForm) {
  return {
    type: types.MINDMAP_SET,
    payload: { mindmapForm },
  };
}

export function classMindmapEditorSet(mindmapEditorForm) {
  return {
    type: types.MINDMAP_EDITOR_SET,
    payload: { mindmapEditorForm },
  };
}

export function createMindmapRequest(mindmap) {
  return {
    type: types.CREATE_MINDMAP_REQUEST,
    payload: { mindmap },
  };
}

export function updateMindmapRequest(mindmap) {
  return {
    type: types.UPDATE_MINDMAP_REQUEST,
    payload: { mindmap },
  };
}

export function deleteMindmapRequest(mindmap) {
  return {
    type: types.DELETE_MINDMAP_REQUEST,
    payload: { mindmap },
  };
}
