import React from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa';

import chromeLogo from '~/assets/images/icons/chrome.svg';
import firefoxLogo from '~/assets/images/icons/firefox.svg';

import {
  Container,
  ScreenBoxPage,
  BrowserBox,
  BrowserItem,
  BrowserButton,
} from './styles';

export default function BlockBrowser() {
  return (
    <Container>
      <ScreenBoxPage>
        <h1>Hippocampus</h1>
        <p>
          Para acessar o sistema hippocampus e obter a melhor experiência em
          seus estudos, recomendamos acessa-lo utilizando os navegadores{' '}
          <b>Google Chrome</b> ou o <b>Mozilla Firefox</b>.
        </p>
        <BrowserBox>
          <BrowserItem style={{ marginRight: 20 }}>
            <img src={chromeLogo} alt="Chrome" title="Chrome" />
            <BrowserButton
              icon={FaCloudDownloadAlt}
              title="Baixar"
              onClick={() => {
                window.location.href =
                  'https://www.google.com/intl/pt-BR/chrome/';
              }}
            />
          </BrowserItem>
          <BrowserItem>
            <img src={firefoxLogo} alt="Firefox" title="Firefox" />
            <BrowserButton
              icon={FaCloudDownloadAlt}
              title="Baixar"
              onClick={() => {
                window.location.href =
                  'https://www.mozilla.org/pt-BR/firefox/download/';
              }}
            />
          </BrowserItem>
        </BrowserBox>
      </ScreenBoxPage>
    </Container>
  );
}
