import styled, { css } from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  border-radius: 10px;
  background-color: ${colors.light1}90;
  outline: none;
  padding: 10px;
  margin-bottom: 10px;
  .friend-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    flex: 1;
    position: relative;
    .friend-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      font-size: 15px;
      color: ${colors.primary};
      font-weight: 500;
      text-align: left;
    }
    .friend-last-update {
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      font-size: 13px;
      font-weight: 300;
      color: ${colors.primary};
      svg {
        color: ${colors.secondary};
        font-size: 20px;
        margin-right: 1px;
      }
    }
    .badge {
      position: absolute;
      right: 0;
      color: ${colors.white};
      background-color: ${colors.primary};
      display: inline-block;
      padding: 1px 4px;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      border-radius: 5px;
      margin-left: 3px;
    }
  }
  .friend-avatar {
    border-radius: 50%;
    background-color: ${colors.white};
    width: 45px;
    height: 45px;
    object-fit: contain;
    margin-right: 5px;
  }
  &:hover {
    background-color: ${colors.primary}30;
  }
  ${props =>
    props.active &&
    css`
      background-image: linear-gradient(
        90deg,
        ${colors.accent2} 0%,
        ${colors.accent2} 30%,
        ${colors.accent1} 90%
      );
      .friend-content {
        .friend-name {
          color: ${colors.white};
        }
        .friend-last-update {
          color: ${colors.white};
          svg {
            color: ${colors.secondary};
          }
        }
        .badge {
          color: ${colors.white};
          background-color: ${colors.primary};
        }
      }
    `}
`;
