import { useEffect } from 'react';

import history from '~/services/history';

const Main = () => {
  useEffect(() => {
    history.push('/periods');
  }, []);
  return null;
};

export default Main;
