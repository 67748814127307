import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FaSignature } from 'react-icons/fa';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  classSetModalCreate,
  createClassRequest,
} from '~/store/modules/class/actions';

import Modal from '~/components/Modal';
import { getValidationErrors } from '~/utils';
import { ButtonSave, Unform, UnformInput, CheckboxGroup } from './styles';

export default function ModalCreate({ subjectId }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tests } = useSelector(state => state.subject);

  const schema = Yup.object().shape({
    title: Yup.string().required(
      t('classes.modal-new.field.title.message.required')
    ),
    subject_tests_ids: Yup.array(),
  });

  const handleSubmit = classe => {
    dispatch(
      createClassRequest({
        ...classe,
        subject_id: subjectId,
      })
    );
  };

  return (
    <Modal
      visible
      width="450px"
      height="auto"
      effect="fadeInUp"
      title={t('classes.modal-new.title')}
      onClose={() => {
        dispatch(classSetModalCreate(false));
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('classes.modal-new.field.title.label')}
          name="title"
          autoFocus
        />
        {tests.length > 0 && (
          <>
            <h2>{t('classes.modal-new.tests')}</h2>
            <CheckboxGroup
              name="subject_tests_ids"
              options={tests.map(item => ({
                id: item.id,
                value: item.id,
                label: `${item.description} - ${item.date}`,
              }))}
            />
            <small>{t('classes.modal-new.description')}</small>
          </>
        )}
        <ButtonSave
          type="submit"
          title={t('classes.modal-new.save')}
          gradient
        />
      </Unform>
    </Modal>
  );
}

ModalCreate.propTypes = {
  subjectId: PropTypes.string.isRequired,
};
