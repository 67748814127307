import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  span.error {
    color: ${colors.secondary};
    font-size: 13px;
    margin-top: 5px;
  }
`;

export const Button = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  padding: 0 10px;
  border-radius: 5px;
  border: 2px solid ${props => props.color};
  color: ${props => props.color};
  font-size: 14px;
  background: ${colors.white};
  .icon {
    margin-right: 5px;
  }
`;

export const File = styled.input.attrs({
  type: 'file',
})`
  display: none;
`;
