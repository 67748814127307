import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';
import FileSaver from 'file-saver';
import i18n from '~/i18n';

import types from './types';
import api from '~/services/api';
import history from '~/services/history';
import {
  // Subject
  subjectRequestSuccess,
  subjectSetEdit,
  subjectRequest,
  subjectSetModalCreate,
  // Test
  subjectTestRequest,
  subjectTestRequestSuccess,
  subjectTestSet,
  // Work
  subjectWorkRequest,
  subjectWorkRequestSuccess,
  subjectWorkSet,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

// Subject

export function* request({ payload }) {
  // Start loading
  yield put(startLoader());
  const { periodId } = payload;
  try {
    const response = yield call(api.get, `subjects/period/${periodId}`);
    const subjects = response.data.map(item => ({
      ...item,
      created_at: moment(item.created_at).format('DD/MM/YYYY'),
      updated_at: moment(item.updated_at).format('DD/MM/YYYY'),
    }));
    // Stop loading
    yield put(stopLoader());

    yield put(subjectRequestSuccess(subjects));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.request.error'));
    history.push('/');
  }
}

export function* update({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...subject } = payload.subject;

    yield call(api.put, `subjects/${id}`, subject);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectSetEdit(null));
    yield put(subjectRequest(subject.period_id));

    toast.success(i18n.t('store.subject.update.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.update.error'));
  }
}

export function* create({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { subject } = payload;

    yield call(api.post, 'subjects', subject);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectSetModalCreate(false));
    yield put(subjectRequest(subject.period_id));

    toast.success(i18n.t('store.subject.create.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.create.error'));
  }
}

export function* remove({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { subject } = payload;

    yield call(api.delete, `subjects/${subject.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectRequest(subject.period_id));
    toast.success(i18n.t('store.subject.remove.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.remove.error'));
  }
}

// Test

export function* requestTest({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { subjectId } = payload;
    const response = yield call(api.get, `subjecttests/subject/${subjectId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(
      subjectTestRequestSuccess(
        response.data.map(item => ({
          ...item,
          date: moment(item.date).format('DD/MM/YYYY HH:mm'),
        }))
      )
    );
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.request-test.error'));
  }
}

export function* createTest({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { test } = payload;
    yield call(api.post, 'subjecttests', test);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectTestRequest(test.subject_id));
    toast.success(i18n.t('store.subject.create-test.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.create-test.error'));
  }
}

export function* updateTest({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { test_id: testId, ...test } = payload.test;
    yield call(api.put, `subjecttests/${testId}`, test);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectTestRequest(test.subject_id));
    yield put(subjectTestSet(null));
    toast.success(i18n.t('store.subject.update-test.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.update-test.error'));
  }
}

export function* deleteTest({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { test } = payload;

    yield call(api.delete, `subjecttests/${test.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectTestRequest(test.subject_id));
    yield put(subjectTestSet(null));
    toast.success(i18n.t('store.subject.delete-test.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.delete-test.error'));
  }
}

// Work

export function* requestWork({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { subjectId } = payload;
    const response = yield call(api.get, `subjectworks/subject/${subjectId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(
      subjectWorkRequestSuccess(
        response.data.map(item => ({
          ...item,
          date: moment(item.date).format('DD/MM/YYYY HH:mm'),
        }))
      )
    );
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.request-work.error'));
  }
}

export function* createWork({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { work } = payload;
    yield call(api.post, 'subjectworks', work);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectWorkRequest(work.subject_id));
    toast.success(i18n.t('store.subject.create-work.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.create-work.error'));
  }
}

export function* updateWork({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { work_id: workId, ...work } = payload.work;
    yield call(api.put, `subjectworks/${workId}`, work);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectWorkRequest(work.subject_id));
    yield put(subjectWorkSet(null));
    toast.success(i18n.t('store.subject.update-work.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.update-work.error'));
  }
}

export function* deleteWork({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { work } = payload;

    yield call(api.delete, `subjectworks/${work.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(subjectWorkRequest(work.subject_id));
    yield put(subjectWorkSet(null));
    toast.success(i18n.t('store.subject.delete-work.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.delete-work.error'));
  }
}

export function* exportPdf({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { classList } = payload;
    const response = yield call(
      api.post,
      `subjects/pdf`,
      {
        classes: classList,
      },
      {
        responseType: 'blob',
      }
    );
    FileSaver.saveAs(
      response.data,
      `download_${moment().format('DDMMYYYY')}.pdf`
    );
    // Stop loading
    yield put(stopLoader());
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.subject.export-pdf.error'));
  }
}

export default all([
  // Subject
  takeLatest(types.SUBJECT_REQUEST, request),
  takeLatest(types.UPDATE_SUBJECT_REQUEST, update),
  takeLatest(types.CREATE_SUBJECT_REQUEST, create),
  takeLatest(types.DELETE_SUBJECT_REQUEST, remove),
  // test
  takeLatest(types.TESTS_REQUEST, requestTest),
  takeLatest(types.CREATE_TEST_REQUEST, createTest),
  takeLatest(types.UPDATE_TEST_REQUEST, updateTest),
  takeLatest(types.DELETE_TEST_REQUEST, deleteTest),
  // Work
  takeLatest(types.WORKS_REQUEST, requestWork),
  takeLatest(types.CREATE_WORK_REQUEST, createWork),
  takeLatest(types.UPDATE_WORK_REQUEST, updateWork),
  takeLatest(types.DELETE_WORK_REQUEST, deleteWork),
  // PDF
  takeLatest(types.EXPORT_PDF_CLASSES, exportPdf),
]);
