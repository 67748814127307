import styled from 'styled-components';

import ButtonComponent from '~/components/Button';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 10px;
`;

export const HeaderButton = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginLeft: 10,
  },
})``;

export const ClassList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;
