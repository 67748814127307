import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-top: 10px;

  span.error {
    color: ${colors.secondary};
    font-size: 13px;
    margin-top: 5px;
  }

  .select-custom {
    ${props =>
      props.hasIcon &&
      css`
        .select-custom__value-container {
          padding-left: 25px !important;
        }
      `}
  }
  .select-label {
    ${props =>
      props.hasIcon &&
      css`
        text-indent: 25px;
      `}
    ${props =>
      props.isFocused &&
      css`
        top: -10px;
        pointer-events: none;
        text-indent: 0;
      `}
  }
  .select-icon {
  }
`;

export const Select = styled(ReactSelect).attrs({
  className: 'select-custom',
  classNamePrefix: 'select-custom',
})`
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(
    to right,
    ${colors.secondary},
    ${colors.primary}
  );

  .select-custom__control,
  .select-custom__control--is-focused,
  .select-custom__control--menu-is-open {
    border: 0;
    margin: 0;
    border-radius: 0;
    min-height: 50px;
    max-height: 50px;
    box-shadow: none !important;
    background: transparent;
    .select-custom__value-container {
      padding: 0;
    }
  }
  .select-custom__placeholder,
  .select-custom__single-value,
  .select-custom__input {
    color: ${colors.white};
    font-size: 14px;
    font-weight: 400;
  }
  .select-custom__menu {
    .select-custom__menu-list {
      .select-custom__option {
        color: ${colors.primary};
        font-size: 14px;
        font-weight: 400;
      }
      .select-custom__option.select-custom__option--is-focused {
        background-color: ${colors.primary}20;
      }
      .select-custom__option.select-custom__option--is-selected {
        background-color: ${colors.primary};
        color: ${colors.white};
      }
    }
  }
  .select-custom__menu {
    z-index: 2;
  }
  .select-custom__indicator,
  .select-custom__indicator:hover {
    color: ${colors.light3};
  }
  .select-custom__indicator-separator {
    display: none;
  }
`;

export const Label = styled.label.attrs({
  className: 'select-label',
})`
  font-size: 14px;
  color: ${colors.white};
  font-weight: 400;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  width: 100%;
  height: 20px;
  position: absolute;
  top: 15px;
  left: 0;
  pointer-events: none;
`;

export const WrapIcon = styled.div.attrs({
  className: 'select-icon',
})`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 15px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: 16px;
  pointer-events: none;
`;
