import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';
import UnformTextareaComponent from '~/components/Unform/Textarea';
import UnformInputMaskComponent from '~/components/Unform/InputMask';
import UnformInputFileComponent from '~/components/Unform/InputFile';

import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Row = styled.div`
  display: flex;
`;

export const SaveButton = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    marginTop: 30,
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
`;

export const UnformInput = styled(UnformInputComponent)``;

export const UnformTextarea = styled(UnformTextareaComponent)``;

export const UnformInputMask = styled(UnformInputMaskComponent)``;

export const UnformInputFile = styled(UnformInputFileComponent)``;
