/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  loading: false,
};

export default function loader(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.START_LOADER: {
        draft.loading = true;
        break;
      }
      case types.STOP_LOADER: {
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
