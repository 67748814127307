import * as Yup from 'yup';
import momentTimezone from 'moment-timezone';
import { getTimezoneFromUser } from '~/services/timezone';

export const languagesList = ['en', 'pt', 'es'];

export const getValidationErrors = err => {
  const validationErrors = {};
  if (err instanceof Yup.ValidationError) {
    err.inner.forEach(error => {
      validationErrors[error.path] = error.message;
    });
  }
  return validationErrors;
};

export const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const momentTz = date => {
  const tzFromUser = getTimezoneFromUser();
  return momentTimezone.tz(date, tzFromUser.value);
};

export const numberToCurrency = num => {
  const str = `${parseFloat(num).toFixed(2)}`;
  return str.replace('.', ',');
};

export default {};
