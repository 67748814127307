import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FaSignature } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  subjectSetModalCreate,
  createSubjectRequest,
} from '~/store/modules/subject/actions';

import Modal from '~/components/Modal';
import { Unform, UnformInput, PeriodSave } from './styles';
import { getValidationErrors } from '~/utils';

export default function ModalCreate({ periodId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    description: Yup.string().required(
      t('subjects.modal-new.field.description.message.required')
    ),
  });

  const handleSubmit = subject => {
    dispatch(
      createSubjectRequest({
        ...subject,
        period_id: periodId,
      })
    );
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('subjects.modal-new.title')}
      onClose={() => {
        dispatch(subjectSetModalCreate(false));
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('subjects.modal-new.field.description.label')}
          name="description"
          autoFocus
        />
        <PeriodSave
          type="submit"
          title={t('subjects.modal-new.save')}
          gradient
        />
      </Unform>
    </Modal>
  );
}

ModalCreate.propTypes = {
  periodId: PropTypes.string.isRequired,
};
