/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import config from '~/config';

const api = axios.create({
  baseURL: config.API_URI,
});

// api.interceptors.response.use(undefined, ({ response: { status } }) => {
//   if (status === 401) {
//     console.log('status', status);
//     history.push('/signOut');
//   }
// });

export default api;
