import styled from 'styled-components';
import ButtonComponent from '~/components/Button';
import TableComponent from '~/components/Table';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.accent3};
  border: 1px solid ${colors.accent3};
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  .container-icon {
    display: flex;
    padding-right: 10px;
    > img {
      width: 50px;
      height: 50px;
      background-color: ${colors.white};
      border-radius: 10px;
      padding: 5px;
      object-fit: contain;
      object-position: center;
    }
  }
  .container-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    > h1 {
      line-height: 1;
      color: ${colors.secondary};
      font-size: 22px;
      font-weight: 500;
    }
    > h2 {
      line-height: 1;
      color: ${colors.white};
      font-size: 13px;
      font-weight: 300;
      margin-top: 5px;
    }
    > h3 {
      line-height: 1;
      color: ${colors.white};
      font-size: 18px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
`;

export const AddSetting = styled(ButtonComponent)`
  height: 30px;
  font-size: 14px;
  margin: 0 auto;
`;

export const Table = styled(TableComponent)``;
