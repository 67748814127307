/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  plans: [],
  myPlans: [],
  expiredSubscription: false,
  daysLeft: null,
  lifeLong: false,
  couponForm: null,
};

export default function reducer(baseState = INITIAL_STATE, action) {
  return produce(baseState, draftState => {
    switch (action.type) {
      case types.MY_PLAN_SET_STATE: {
        Object.keys(action.payload.myPlanState).forEach(objectKey => {
          draftState[objectKey] = action.payload.myPlanState[objectKey];
        });
        break;
      }
      default:
    }
  });
}
