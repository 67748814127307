/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  friends: [],
  searchFriend: '',
  friendsBySearch: [],
  friendsInvites: [],
};

export default function period(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.FRIEND_REQUEST_SUCCESS: {
        draft.friends = action.payload.friends;
        break;
      }
      case types.FRIEND_SEARCH_REQUEST_SUCCESS: {
        draft.friendsBySearch = action.payload.friendsBySearch;
        break;
      }
      case types.FRIEND_INVITES_REQUEST_SUCCESS: {
        draft.friendsInvites = action.payload.friendsInvites;
        break;
      }
      case types.FRIEND_SET_SEARCH: {
        draft.searchFriend = action.payload.searchFriend;
        break;
      }
      default:
    }
  });
}
