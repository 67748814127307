import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { exportPdfClasses } from '~/store/modules/subject/actions';
import { classesRequest } from '~/store/modules/class/actions';
import { getValidationErrors } from '~/utils';

import BlankContent from '~/components/BlankContent';
import Modal from '~/components/Modal';
import { ClassExport, Unform, CheckboxGroup, ClassList } from './styles';

const schema = Yup.object().shape({
  classeIds: Yup.array(),
});

export default function ModalExportClasses({ onClose, subjectId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useSelector(state => state.classe);
  const formRef = useRef(null);

  useEffect(() => {
    dispatch(classesRequest(subjectId));
  }, []);

  const handleSubmit = classesForm => {
    if (classesForm.classeIds.length > 0) {
      dispatch(exportPdfClasses(classesForm.classeIds));
    }
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('subjects.modal-export.title')}
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formRef}
      >
        <ClassList>
          {classes.length > 0 ? (
            <CheckboxGroup
              name="classeIds"
              options={classes.map(item => ({
                id: item.id,
                value: item.id,
                label: `${item.title}`,
              }))}
            />
          ) : (
            <BlankContent>
              {t('subjects.modal-export.no-classes-listed')}
            </BlankContent>
          )}
        </ClassList>
        {classes.length > 0 && (
          <ClassExport
            type="submit"
            title={t('subjects.modal-export.export')}
            icon={FaCloudDownloadAlt}
            gradient
          />
        )}
      </Unform>
    </Modal>
  );
}

ModalExportClasses.propTypes = {
  onClose: PropTypes.func.isRequired,
  subjectId: PropTypes.string.isRequired,
};
