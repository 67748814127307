import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import Modal from '~/components/Modal';
import SearchBox from '~/components/SearchBox';
import Table from '~/components/Table';
import colors from '~/styles/colors';

import {
  friendSearchRequest,
  friendInviteFriendRequest,
  friendSearchRequestSuccess,
  friendSetSearch,
} from '~/store/modules/friend/actions';

import { ModalBody, ListBox, Image, TableButton } from './styles';

export default function ModalSearch({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { friendsBySearch, searchFriend } = useSelector(state => state.friend);

  useEffect(() => {
    if (searchFriend.length >= 3) {
      dispatch(friendSearchRequest());
    } else {
      dispatch(friendSearchRequestSuccess([]));
    }
  }, [searchFriend]);

  const renderSearchBox = () => (
    <SearchBox
      placeholder={t('friends.modal-search.search')}
      value={searchFriend}
      autoFocus
      onChange={val => {
        dispatch(friendSetSearch(val));
      }}
    />
  );

  const handleAddFriend = friendId => {
    confirmAlert({
      title: t('friends.modal-search.confirm-alert.add-friend.title'),
      message: t('friends.modal-search.confirm-alert.add-friend.message'),
      buttons: [
        {
          label: t(
            'friends.modal-search.confirm-alert.add-friend.buttons.cancel'
          ),
        },
        {
          label: t('friends.modal-search.confirm-alert.add-friend.buttons.yes'),
          onClick: () => {
            dispatch(friendInviteFriendRequest(friendId));
          },
        },
      ],
    });
  };

  const renderTable = () => (
    <ModalBody>
      <ListBox>
        <Table>
          <tbody>
            {friendsBySearch.map(item => (
              <tr key={item.id}>
                <td width="1%">
                  <Image src={item.avatar_url} />
                </td>
                <td width="50%">{item.name}</td>
                <td width="50%">{item.email}</td>
                <td width="1%">
                  <TableButton
                    type="button"
                    title={t('friends.modal-search.add-friend')}
                    icon={FaPlus}
                    onClick={() => {
                      handleAddFriend(item.id);
                    }}
                    color={colors.secondary}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ListBox>
    </ModalBody>
  );

  return (
    <Modal
      visible
      width="600px"
      height="auto"
      effect="fadeInUp"
      title={t('friends.modal-search.title')}
      onClose={() => {
        onClose();
      }}
    >
      <>
        {renderSearchBox()}
        {renderTable()}
      </>
    </Modal>
  );
}

ModalSearch.propTypes = {
  onClose: PropTypes.func.isRequired,
};
