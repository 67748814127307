import styled from 'styled-components';
import Modal from 'react-awesome-modal';
import { shade } from 'polished';
import colors from '~/styles/colors';
import PageHeaderContent from '~/components/PageHeader';

export const Container = styled(Modal)``;

export const ModalClose = styled.button.attrs({
  className: 'modal-component-close',
})`
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 15px;
  color: ${colors.white};
  background-color: ${colors.secondary};
  &:hover {
    background-color: ${shade(0.2, colors.secondary)};
  }
`;

export const ModalWrap = styled.div.attrs({
  className: 'modal-component-wrap',
})`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: ${prop => (prop.overflow ? 'auto' : 'unset')};
  background-color: ${colors.accent1};
`;

export const PageHeader = styled(PageHeaderContent).attrs({
  className: 'modal-component-header',
})`
  background-color: ${colors.accent3};
  .header-title {
  }
  .header-action {
  }
`;
