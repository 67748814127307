import types from './types';

export function userPlanRequest() {
  return {
    type: types.USER_PLAN_REQUEST,
  };
}

export function verifyExpired() {
  return {
    type: types.VERIFY_EXPIRED,
  };
}

export function planRequest() {
  return {
    type: types.PLANS_REQUEST,
  };
}

export function myPlanSetState(myPlanState) {
  return {
    type: types.MY_PLAN_SET_STATE,
    payload: { myPlanState },
  };
}

export function checkout(checkoutData) {
  return {
    type: types.CHECKOUT,
    payload: { checkoutData },
  };
}

export function useCoupon(couponForm) {
  return {
    type: types.USE_COUPON,
    payload: { couponForm },
  };
}
