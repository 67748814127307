import styled from 'styled-components';
import colors from '~/styles/colors';

export default styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-width: 1000px;
  min-height: calc(100% - 55px);
  max-height: calc(100% - 55px);
  overflow: auto;
  background-color: ${colors.accent1};
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
`;
