import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { updateMindmapRequest } from '~/store/modules/class/actions';
import Mindmap from './components/Mindmap';
import { Modal, ModalContent } from './styles';

export default function ModalMindmapEditor({ onClose }) {
  const dispatch = useDispatch();
  const { mindmapEditorForm } = useSelector(state => state.classe);

  const handleUpdate = async ({ title, content }) => {
    dispatch(
      updateMindmapRequest({
        class_id: mindmapEditorForm.class_id,
        title,
        mindmap_id: mindmapEditorForm.id,
        content,
      })
    );
    // onClose();
  };

  return (
    <Modal
      visible
      width="90%"
      height="90%"
      effect="fadeInUp"
      title={mindmapEditorForm.title}
      onClose={() => {
        onClose();
      }}
      overflow={false}
    >
      <ModalContent>
        <Mindmap
          onSave={model => {
            handleUpdate({
              ...mindmapEditorForm,
              content: JSON.parse(model),
            });
          }}
          content={mindmapEditorForm.content}
        />
      </ModalContent>
    </Modal>
  );
}

ModalMindmapEditor.propTypes = {
  onClose: PropTypes.func.isRequired,
};
