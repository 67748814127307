/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import colors from '~/styles/colors';

import { Container } from './styles';

const Button = ({
  disabled,
  title,
  icon: Icon,
  color,
  color2,
  type,
  bordered,
  gradient,
  badge,
  style,
  className,
  ...rest
}) => (
  <Container
    disabled={disabled}
    color={color}
    color2={color2}
    type={type}
    bordered={bordered}
    gradient={gradient}
    style={style}
    className={className}
    {...rest}
  >
    {Icon && <Icon className="icon" />}
    <span>{title}</span>
    {badge !== '' && <span className="badge">{badge}</span>}
  </Container>
);

Button.defaultProps = {
  icon: null,
  color: colors.primary,
  color2: `${colors.primary}60`,
  type: 'button',
  bordered: false,
  gradient: false,
  style: null,
  disabled: false,
  badge: '',
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.func,
  color: PropTypes.string,
  color2: PropTypes.string,
  type: PropTypes.string,
  bordered: PropTypes.bool,
  gradient: PropTypes.bool,
  style: PropTypes.shape(),
  disabled: PropTypes.bool,
  className: PropTypes.string.isRequired,
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Button;
