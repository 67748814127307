import styled from 'styled-components';
import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.white};
  min-width: 800px;
`;

export const HeaderButton = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginLeft: 10,
  },
})``;

export const NewFriend = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid ${colors.accent3};
  border-radius: 10px;
  padding: 0;
  margin-left: 5px;
  width: 250px;
  height: 250px;
  font-size: 20px;
  font-weight: 400;
  background-color: ${colors.accent3};
  color: ${colors.white};
`;

export const HeaderNewFriend = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    height: 35,
    fontSize: 14,
  },
})``;

export const HeaderInvites = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginRight: 10,
  },
})``;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const FriendList = styled.div`
  display: flex;
  flex-flow: wrap;
  padding: 0 5px 5px 5px;
  justify-content: ${props => props.justifyContent};
`;

export const FriendBoxName = styled.h1`
  display: flex;
  width: 100%;
  color: ${colors.dark3};
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
  white-space: nowrap;
`;

export const FriendBoxEmail = styled.h2`
  display: flex;
  width: 100%;
  color: ${colors.dark2};
  justify-content: center;
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
  margin-top: 10px;
`;

export const FriendBoxRole = styled.h2`
  display: flex;
  width: 100%;
  color: ${colors.dark3};
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  margin-top: 10px;
`;
