/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  drive: null,
  driveModalFolderCreate: false,
  driveModalFileCreate: false,
  folderEdit: null,
};

export default function drive(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.DRIVE_REQUEST_SUCCESS: {
        draft.drive = action.payload.drive;
        break;
      }
      case types.DRIVE_SET_MODAL_FOLDER_CREATE: {
        draft.driveModalFolderCreate = action.payload.driveModalFolderCreate;
        break;
      }
      case types.DRIVE_SET_MODAL_FILE_CREATE: {
        draft.driveModalFileCreate = action.payload.driveModalFileCreate;
        break;
      }
      case types.DRIVE_FOLDER_SET_EDIT: {
        draft.folderEdit = action.payload.folderEdit;
        break;
      }
      default:
    }
  });
}
