import styled, { css } from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';
import UnformInputMaskComponent from '~/components/Unform/InputMask';
import UnformSelectComponent from '~/components/Unform/Select';

import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const FooterButton = styled(ButtonComponent).attrs({
  color: colors.secondary,
  style: {
    height: 35,
    fontSize: 14,
    margin: 0,
  },
})``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  width: 100%;
  height: 80px;
  padding: 0 15px;
  * {
    pointer-events: none;
  }
  .header-divider {
    width: calc(100% - 30px);
    height: 2px;
    position: absolute;
    top: 28px;
    background-color: ${colors.secondary};
    z-index: 0;
  }
`;

export const HeaderStep = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
  justify-content: center;
  height: 80px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid ${colors.secondary};
    background-color: ${colors.primary};
    color: ${colors.secondary};
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    ${props =>
      props.active &&
      css`
        background-color: ${colors.secondary};
        color: ${colors.white};
      `}
  }
  small {
    margin-top: 5px;
    color: ${colors.secondary};
    font-size: 16px;
    font-weight: 400;
  }
`;

export const PlanPriceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h2 {
    color: ${colors.white};
    font-size: 18px;
    font-weight: 400;
    ${props =>
      props.active &&
      css`
        color: ${colors.secondary};
      `}
  }
`;

export const PlanPrice = styled.button.attrs({
  type: 'button',
})`
  width: 150px;
  height: 150px;
  border: 3px solid ${colors.secondary};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  background-color: transparent;
  margin-top: 20px;
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: ${colors.primary};
    border: 1px solid ${colors.secondary};
    border-radius: 50%;
    bottom: -25px;
  }

  ${props =>
    props.active &&
    css`
      background-color: ${colors.secondary};
      &::before {
        background-color: ${colors.secondary};
      }
    `}

  > span {
    width: 100%;
    text-align: left;
    padding: 0px 10px;
    font-size: 25px;
    font-weight: bold;
  }
  > h2 {
    width: 100%;
    text-align: left;
    padding: 0px 10px;
    font-size: 40px;
    font-weight: 400;
  }
  > small {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
  }
`;

export const Step1 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .step1-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-evenly;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .step1-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    border-top: 1px solid ${colors.secondary}50;
  }
`;

export const Step2 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .step2-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .step2-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid ${colors.secondary}50;
  }
`;

export const Step3 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .step3-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .step3-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid ${colors.secondary}50;
  }
`;

export const Step4 = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .step4-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px 15px;
  }
  .step4-footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid ${colors.secondary}50;
  }
`;

export const BrandList = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const BrandItem = styled.button`
  width: 60px;
  height: 40px;
  background-color: ${colors.white};
  background-image: url(${props => props.image});
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid ${colors.primary};
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before {
    content: ' ';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: ${colors.primary};
    border: 1px solid ${colors.secondary};
    border-radius: 50%;
    bottom: -20px;
  }
  ${props =>
    props.active &&
    css`
      border: 1px solid ${colors.secondary};
      &::before {
        background-color: ${colors.secondary};
      }
    `}
`;

export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding: 10px 15px 0 15px;
  .form-row {
    display: flex;
    width: 100%;
  }
`;

export const UnformInput = styled(UnformInputComponent)``;

export const UnformSelect = styled(UnformSelectComponent)``;

export const UnformInputMask = styled(UnformInputMaskComponent)``;
