import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border: 0;
  background-color: ${colors.secondary};
  color: ${colors.white};
  border-radius: 50%;
  font-size: 20px;
`;
