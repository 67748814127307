import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';
import { PeriodSave, Unform, UnformCheckbox, UnformSelect } from './styles';

import { friendRequest } from '~/store/modules/friend/actions';
import { sharedCreate } from '~/store/modules/shared/actions';
import { getValidationErrors } from '~/utils';

export default function ModalShare({ onClose, periodToShare }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { friends } = useSelector(state => state.friend);

  const schema = Yup.object().shape({
    friend: Yup.string().required(
      t('period.modal-share.field.friend.message.required')
    ),
    subjects: Yup.bool(),
    classes: Yup.bool(),
    classKeywords: Yup.bool(),
    classQuestions: Yup.bool(),
    editTogether: Yup.bool(),
  });

  useEffect(() => {
    dispatch(friendRequest());
  }, []);

  const [formInputs, setFormInputs] = useState({
    subjects: false,
    classes: false,
    classKeywords: false,
    classQuestions: false,
    editTogether: false,
  });

  const handleSubmit = share => {
    const dataPost = {
      user_id_to: share.friend,
      content_id: periodToShare,
      type: 'period',
      config: {
        subjects: share.subjects,
        classes: share.classes,
        classKeywords: share.classKeywords,
        classQuestions: share.classQuestions,
        editTogether: share.editTogether,
      },
    };
    dispatch(sharedCreate(dataPost));
    onClose();
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('period.modal-share.title')}
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={formInputs}
        ref={formRef}
      >
        <UnformSelect
          name="friend"
          label={t('period.modal-share.field.friend.label')}
          style={{ marginBottom: 20 }}
          options={friends.map(item => ({
            label: item.name,
            value: item.id,
          }))}
        />
        <UnformCheckbox
          name="subjects"
          label={t('period.modal-share.field.check.subject')}
          checked={formInputs.subjects}
          onChange={({ target: { checked } }) => {
            setFormInputs({
              ...formInputs,
              subjects: checked,
              classes: checked ? formInputs.classes : false,
              classKeywords: checked ? formInputs.classKeywords : false,
              classQuestions: checked ? formInputs.classQuestions : false,
            });
          }}
        />
        <UnformCheckbox
          disabled={!formInputs.subjects}
          name="classes"
          label={t('period.modal-share.field.check.classes')}
          checked={formInputs.classes}
          onChange={({ target: { checked } }) => {
            setFormInputs({
              ...formInputs,
              classes: checked,
              classKeywords: checked ? formInputs.classKeywords : false,
              classQuestions: checked ? formInputs.classQuestions : false,
              editTogether: checked ? formInputs.editTogether : false,
            });
          }}
          style={{ marginLeft: 20 }}
        />
        <UnformCheckbox
          disabled={!formInputs.classes}
          name="classKeywords"
          label={t('period.modal-share.field.check.keywords')}
          checked={formInputs.classKeywords}
          onChange={({ target: { checked } }) => {
            setFormInputs({
              ...formInputs,
              classKeywords: checked,
            });
          }}
          style={{ marginLeft: 40 }}
        />
        <UnformCheckbox
          disabled={!formInputs.classes}
          name="classQuestions"
          label={t('period.modal-share.field.check.questions')}
          checked={formInputs.classQuestions}
          onChange={({ target: { checked } }) => {
            setFormInputs({
              ...formInputs,
              classQuestions: checked,
            });
          }}
          style={{ marginLeft: 40 }}
        />
        <small>{t('period.modal-share.observation')}</small>
        {!!formInputs.classes && (
          <UnformCheckbox
            name="editTogether"
            label={t('period.modal-share.field.check.editTogether')}
            checked={formInputs.editTogether}
            onChange={evnt => {
              setFormInputs({
                ...formInputs,
                editTogether: evnt.target.checked,
              });
            }}
            style={{ marginTop: 20, marginBottom: 0 }}
          />
        )}
        <PeriodSave
          type="submit"
          title={t('period.modal-share.share')}
          gradient
        />
      </Unform>
    </Modal>
  );
}

ModalShare.defaultProps = {
  onClose: () => {},
  periodToShare: '',
};

ModalShare.propTypes = {
  onClose: PropTypes.func,
  periodToShare: PropTypes.string,
};
