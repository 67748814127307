import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import iconSubjects from '~/assets/images/icons/subjects.svg';
import SearchBox from '~/components/SearchBox';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import CardBox from '~/components/CardBox';
import Route from '~/services/history';
// import Tour from '~/components/Tour';
// import TourButton from '~/components/TourButton';

import { studyAllTestsRequest } from '~/store/modules/studyContent/actions';
// import { tourSetState } from '~/store/modules/tour/actions';

import { Content, TestsList, TestBoxButton, Table } from './styles';

const StudyContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { allTests } = useSelector(state => state.studyContent);
  // const { showedStudyForTest } = useSelector(state => state.tour);
  const [searchValue, setSearchValue] = useState('');
  // const [turIsOpen, setTurIsOpen] = useState(false);
  // const tourSteps = [
  //   {
  //     selector: '#study-for-test-search',
  //     content: t('tour.study-for-test.search'),
  //   },
  // ];

  useEffect(() => {
    dispatch(studyAllTestsRequest());
    // if (!showedStudyForTest) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const renderHeader = () => {
    const breadcrumb = [t('study-for-test.title')];
    return (
      <PageHeader
        breadcrumb={breadcrumb}
        actionElements={() => {
          /* <TourButton onClick={() => setTurIsOpen(true)} /> */
        }}
      />
    );
  };

  const renderSearchBox = () => (
    <SearchBox
      id="study-for-test-search"
      placeholder={t('period.search')}
      value={searchValue}
      onChange={val => setSearchValue(val)}
    />
  );

  const renderTests = () => {
    const testList = allTests.filter(tst => {
      const testUpper = tst.test.toUpperCase();
      const subjectUpper = tst.subject.toUpperCase();
      const periodUpper = tst.period.toUpperCase();
      const dateUpper = tst.date.toUpperCase();
      const searchUpper = searchValue.toUpperCase();
      return (
        testUpper.includes(searchUpper) ||
        subjectUpper.includes(searchUpper) ||
        periodUpper.includes(searchUpper) ||
        dateUpper.includes(searchUpper)
      );
    });
    return (
      <TestsList
        justifyContent={allTests.length > 3 ? 'space-between' : 'unset'}
      >
        {allTests.length > 0 ? (
          testList.map(item => (
            <CardBox key={item.id} imageCard={iconSubjects} width={250}>
              <div className="card-box-title">{item.test}</div>
              <div className="card-box-subtitle">
                <b>{item.subject}</b>
              </div>
              <div className="card-box-subtitle">{item.period}</div>
              <div className="card-box-subtitle">{item.date}</div>
              <TestBoxButton
                title={t('study-for-test.btn-open')}
                onClick={() => Route.replace(`/study-content/${item.id}`)}
              />
            </CardBox>
          ))
        ) : (
          <Table>
            <tbody>
              <tr>
                <td align="center">{t('study-for-test.no-items')}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </TestsList>
    );
  };

  const renderContent = () => (
    <Content>
      {renderSearchBox()}
      {renderTests()}
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {/*
          <Tour
            steps={tourSteps}
            isOpen={turIsOpen}
            onRequestClose={() => setTurIsOpen(false)}
            closeWithMask={false}
            startAt={0}
            onClose={() => {
              dispatch(tourSetState({ showedStudyForTest: true }));
            }}
          />
        */}
      </PageContent>
    </>
  );
};

StudyContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      testId: PropTypes.string,
    }),
  }).isRequired,
};

export default StudyContent;
