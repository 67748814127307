import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

function DropZoneFiles({ onDropOk, accept, maxSize, multiple }) {
  const { t } = useTranslation();
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      onDropOk(multiple ? acceptedFiles : acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxSize,
    multiple,
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>{t('class-view.modal-file-list.message-upload-drop')}</p>
      ) : (
        <p>{t('class-view.modal-file-list.message-upload-message')}</p>
      )}
    </Container>
  );
}

DropZoneFiles.defaultProps = {
  onDropOk: () => {},
  accept: '', // 'image/png,image/jpg,image/jpeg',
  maxSize: null,
  multiple: false,
};

DropZoneFiles.propTypes = {
  onDropOk: PropTypes.func,
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
};

export default DropZoneFiles;
