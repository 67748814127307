import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';
import UnformInputMaskComponent from '~/components/Unform/InputMask';

import ButtonComponent from '~/components/Button';
import colors from '~/styles/colors';

export const ButtonSave = styled(ButtonComponent).attrs({
  color: colors.secondary,
  style: {
    height: 35,
    fontSize: 14,
  },
})``;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

export const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  max-height: 200px;
  overflow-y: auto;
`;

const QuestionActionButton = styled.button.attrs({
  type: 'button',
})`
  color: ${colors.white};
  font-size: 12px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClearButton = styled(QuestionActionButton)`
  border: 1px solid ${colors.secondary};
  color: ${colors.secondary};
  background-color: transparent;
  margin-right: 20px;
  margin-left: 0;
`;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
`;

export const UnformInput = styled(UnformInputComponent)``;

export const UnformInputMask = styled(UnformInputMaskComponent)``;
