import styled from 'styled-components';
import AvatarComponent from '~/components/Avatar';
import colors from '~/styles/colors';

export const Container = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.white};
  border: 1px solid ${colors.light2};
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px ${colors.black}30;
  -moz-box-shadow: 0px 0px 5px 0px ${colors.black}30;
  box-shadow: 0px 0px 5px 0px ${colors.black}30;
  &:hover {
    background-color: ${colors.light1};
  }
`;

export const Image = styled(AvatarComponent).attrs({
  width: 50,
  height: 50,
})``;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10px;
`;

export const CertificateTitle = styled.h1`
  width: 100%;
  color: ${colors.dark3};
  font-size: 20px;
  font-weight: 500;
  text-align: left;
`;

export const CertificateType = styled.h2`
  width: 100%;
  color: ${colors.dark2};
  font-size: 16px;
  font-weight: 300;
  text-align: left;
`;
