/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  certificates: [],
  certificateModalCreate: false,
  certificateEdit: null,
};

export default function certificate(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.CERTIFICATE_REQUEST_SUCCESS: {
        draft.certificates = action.payload.certificates;
        break;
      }
      case types.CERTIFICATE_SET_EDIT: {
        draft.certificateEdit = action.payload.certificateEdit;
        break;
      }
      case types.CERTIFICATE_SET_MODAL_CREATE: {
        draft.certificateModalCreate = action.payload.certificateModalCreate;
        break;
      }
      default:
    }
  });
}
