import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FaSignature, FaCalendar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import {
  periodSetModalCreate,
  createPeriodRequest,
} from '~/store/modules/period/actions';
import { getValidationErrors } from '~/utils';

import Modal from '~/components/Modal';
import { PeriodSave, Unform, UnformInput, UnformInputMask } from './styles';

export default function ModalCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    description: Yup.string().required(
      t('period.modal-new.field.description.message.required')
    ),
    initial_date: Yup.string().required(
      t('period.modal-new.field.initial.message.required')
    ),
    final_date: Yup.string().required(
      t('period.modal-new.field.final.message.required')
    ),
  });

  const handleSubmit = period => {
    dispatch(createPeriodRequest(period));
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('period.modal-new.title')}
      onClose={() => {
        dispatch(periodSetModalCreate(false));
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('period.modal-new.field.description.label')}
          name="description"
          autoFocus
          style={{ marginBottom: 20 }}
        />
        <UnformInputMask
          icon={FaCalendar}
          type="text"
          label={t('period.modal-new.field.initial.label')}
          name="initial_date"
          mask="99/99/9999"
          style={{ marginBottom: 20 }}
        />
        <UnformInputMask
          icon={FaCalendar}
          type="text"
          label={t('period.modal-new.field.final.label')}
          name="final_date"
          mask="99/99/9999"
        />
        <PeriodSave type="submit" title={t('period.modal-new.save')} gradient />
      </Unform>
    </Modal>
  );
}
