import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import { faqRequestSuccess } from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* request() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'faqs');
    // Stop loading
    yield put(stopLoader());

    yield put(faqRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.faq.request.error'));
  }
}

export default all([takeLatest(types.FAQ_REQUEST, request)]);
