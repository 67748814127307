import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  span.error {
    color: ${colors.secondary};
    font-size: 13px;
    margin-top: 5px;
  }
`;

export const InputTextarea = styled.textarea.attrs({
  className: 'input-field',
})`
  display: flex;
  flex: 1;
  resize: none;
  border: 0;
  outline: none;
  background-color: ${colors.white};
  color: ${colors.primary};
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
  padding: 10px;

  &::placeholder {
    color: ${colors.primary};
  }
`;
