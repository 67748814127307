import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { numberToCurrency } from '~/utils';
import { Container, CardBody, PlanPrice } from './styles';

export default function ClassItem({ userPlan }) {
  const { t } = useTranslation();
  return (
    <Container
      expired={!userPlan.plan.life_long && userPlan.expired_subscription}
    >
      <PlanPrice>
        <span>{t(`my-plan.item.currency.${userPlan.plan.currency}`)}</span>
        <h2>{numberToCurrency(userPlan.plan.label_price / 100)}</h2>
        <small>{t('my-plan.item.payment')}</small>
      </PlanPrice>
      <CardBody>
        <h1>{t(`my-plan.plan-name.${userPlan.plan.language_key}`)}</h1>
        <h2>
          {t('my-plan.item.start-in', {
            date: moment(userPlan.initial_date).format('DD/MM/YYYY'),
          })}
        </h2>
        <h2>
          {t('my-plan.item.end-in', {
            date: userPlan.plan.life_long
              ? '-'
              : moment(userPlan.final_date).format('DD/MM/YYYY'),
          })}
        </h2>
      </CardBody>
    </Container>
  );
}

ClassItem.defaultProps = {};

ClassItem.propTypes = {
  userPlan: PropTypes.shape().isRequired,
};
