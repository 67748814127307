import { createGlobalStyle } from 'styled-components';
import colors from './colors';

import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Roboto Slab', sans-serif;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    background: ${colors.white};
    color: ${colors.black};
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialised !important;
  }

  body, input, button, select {
    font: 14px 'Roboto Slab', sans-serif;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.secondary};
    border-radius: 5px;
    height: 30px;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  .bp3-portal, .bp3-context-menu {
    z-index: 999999 !important;
  }

  .react-tiny-popover-container {
    z-index: 9999999 !important;
  }

  .rbc-overlay,
  .rbc-calendar {
    .rbc-event {
      background-color: ${colors.primary};
      outline: none;
      &.rbc-selected {
        background-color: ${colors.primary}80;
      }
    }
    .rbc-show-more {
      color: ${colors.primary};
      text-align: center;
      font-weight: bold !important;
    }
  }

  .react-confirm-alert-overlay {
    z-index: 9999999999999 !important;
    background-color: ${colors.accent3}99 !important;
    .react-confirm-alert {
      box-shadow: 0 0 26px rgba(32,32,32,0.15);
      .react-confirm-alert-body {
        font-size: 16px !important;
        padding: 20px;
        h1 {
          font-size: 30px !important;
        }
        .react-confirm-alert-button-group {
          justify-content: space-between;
          button {
            background: ${colors.primary};
            padding: 10px 20px;
            font-size: 14px;
            margin: 0;
          }
        }
      }
    }
  }

  .Toastify__toast-container {
    z-index: 9999999999999 !important;
  }

  .react-tour {
    .reactour__close {
      top: 10px;
      right: 10px;
    }
  }

  .react-tour-mask {
    opacity: 0.8;
    color: #000;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    position: fixed;
    z-index: 99999;
    pointer-events: none;
  }
`;

// https://css-tricks.com/custom-scrollbars-in-webkit/
/*
::-webkit-scrollbar              {1}
::-webkit-scrollbar-button       {2}
::-webkit-scrollbar-track        {3}
::-webkit-scrollbar-track-piece  {4}
::-webkit-scrollbar-thumb        {5}
::-webkit-scrollbar-corner       {6}
::-webkit-resizer                {7}
*/
