import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: ${props => (props.itsMe ? 'row' : 'row-reverse')};
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  color: ${colors.primary};
  font-size: 14px;
  .message-date {
    font-weight: 300;
  }
  .message-separator {
    margin: 0 5px;
  }
  .message-user {
    font-weight: 500;
  }
  > svg {
    color: ${props => (props.itsMe ? colors.primary : colors.accent2)};
    font-size: 10px;
    margin: 0 10px;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 20px;
  align-self: ${props => (props.itsMe ? 'flex-end' : 'flex-start')};
  color: white;
  padding: 18px 20px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 300;
  border-radius: 7px;
  letter-spacing: 0.5px;
  width: 90%;
  position: relative;
  background-color: ${props => (props.itsMe ? colors.primary : colors.accent2)};

  &:after {
    bottom: calc(100% - 1px);
    left: ${props => (props.itsMe ? 93 : 7)}%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${props =>
      props.itsMe ? colors.primary : colors.accent2};
    border-width: 10px;
    margin-left: -10px;
  }
`;
