import styled from 'styled-components';
import colors from '~/styles/colors';

export default styled.h2`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  color: ${colors.dark1};
  font-size: 25px;
  font-weight: 400;
`;
