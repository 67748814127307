import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0;
  background-color: ${colors.primary};
  outline: none;
  svg {
    color: ${colors.white};
    font-size: 18px;
  }
  .badge {
    color: ${colors.white};
    background-color: ${colors.danger};
    display: inline-block;
    padding: 1px 4px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    border-radius: 5px;
    margin-left: 3px;
    position: absolute;
    top: 0;
    right: 45px;
  }
`;
