import styled from 'styled-components';

import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  background-color: ${colors.primary};
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  color: ${colors.accent3};
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 3px dotted ${colors.white}50;
  height: 88px;
`;
