import React from 'react';
import {
  FaTrash,
  FaPen,
  FaShare,
  FaInfo,
  FaCloudDownloadAlt,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import HasPermission from '~/components/HasPermission';

import {
  Container,
  Image,
  EditButton,
  DeleteButton,
  CardBody,
  ShareButton,
  SharedBy,
  ExportPDF,
} from './styles';

export default function CardBox({
  shareAction,
  exportPdfAction,
  editAction,
  deleteAction,
  sharedBy,
  imageCard,
  width,
  height,
  children,
}) {
  const { t } = useTranslation();

  return (
    <Container width={width} height={height}>
      <HasPermission>
        {shareAction && (
          <ShareButton
            data-tip={t('components.card-box.share')}
            onClick={shareAction}
          >
            <FaShare />
          </ShareButton>
        )}
      </HasPermission>
      <HasPermission>
        {editAction && (
          <EditButton
            data-tip={t('components.card-box.edit')}
            onClick={editAction}
          >
            <FaPen />
          </EditButton>
        )}
      </HasPermission>
      <HasPermission>
        {exportPdfAction && (
          <ExportPDF
            data-tip={t('components.card-box.export')}
            onClick={exportPdfAction}
          >
            <FaCloudDownloadAlt />
          </ExportPDF>
        )}
      </HasPermission>
      <HasPermission>
        {deleteAction && (
          <DeleteButton
            data-tip={t('components.card-box.delete')}
            onClick={deleteAction}
          >
            <FaTrash />
          </DeleteButton>
        )}
      </HasPermission>
      <HasPermission>
        {!!sharedBy && (
          <SharedBy
            data-tip={`${t('components.card-box.shared-by')} ${sharedBy}`}
          >
            <FaInfo />
          </SharedBy>
        )}
      </HasPermission>
      {imageCard && <Image src={imageCard} />}
      <CardBody>{children}</CardBody>
      <ReactTooltip />
    </Container>
  );
}

CardBox.defaultProps = {
  shareAction: null,
  exportPdfAction: null,
  editAction: null,
  deleteAction: null,
  sharedBy: '',
  imageCard: null,
  width: 250,
  height: null,
};

CardBox.propTypes = {
  shareAction: PropTypes.func,
  exportPdfAction: PropTypes.func,
  editAction: PropTypes.func,
  deleteAction: PropTypes.func,
  sharedBy: PropTypes.string,
  imageCard: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};
