import types from './types';

export function searchRequest() {
  return {
    type: types.SEARCH_REQUEST,
  };
}

export function searchRequestSuccess(results) {
  return {
    type: types.SEARCH_REQUEST_SUCCESS,
    payload: { results },
  };
}

export function searchSetFilter(filter) {
  return {
    type: types.SEARCH_SET_FILTER,
    payload: { filter },
  };
}
