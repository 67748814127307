/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  faqs: [],
};

export default function faq(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.FAQ_REQUEST_SUCCESS: {
        draft.faqs = action.payload.faqs;
        break;
      }
      default:
    }
  });
}
