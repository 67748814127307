/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  // Class
  classes: [],
  classe: null,
  classeEdit: null,
  classeModalCreate: false,
  // Question
  questions: [],
  questionForm: null,
  // Keyword
  keywords: [],
  keywordForm: null,
  // Files
  files: [],
  fileCreate: null,
  formFileEdit: null,
  // Mindmap
  mindmaps: [],
  mindmapForm: null,
  mindmapEditorForm: null,
};

export default function classes(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      // Class
      case types.CLASSES_REQUEST_SUCCESS: {
        draft.classes = action.payload.classes;
        break;
      }
      case types.CLASS_REQUEST_SUCCESS: {
        draft.classe = action.payload.classe;
        break;
      }
      case types.CLASS_SET_EDIT: {
        draft.classeEdit = action.payload.classeEdit;
        break;
      }
      case types.CLASS_SET_MODAL_CREATE: {
        draft.classeModalCreate = action.payload.classeModalCreate;
        break;
      }
      // Question
      case types.QUESTION_SET: {
        draft.questionForm = action.payload.questionForm;
        break;
      }
      case types.QUESTIONS_REQUEST_SUCCESS: {
        draft.questions = action.payload.questions;
        break;
      }
      // Keyword
      case types.KEYWORD_SET: {
        draft.keywordForm = action.payload.keywordForm;
        break;
      }
      case types.KEYWORDS_REQUEST_SUCCESS: {
        draft.keywords = action.payload.keywords;
        break;
      }
      // Files
      case types.FILES_REQUEST_SUCCESS: {
        draft.files = action.payload.files;
        break;
      }
      case types.FILES_SET_CREATE: {
        draft.fileCreate = action.payload.fileCreate;
        break;
      }
      case types.FILES_SET_UPDATE: {
        draft.formFileEdit = action.payload.formFileEdit;
        break;
      }
      // Mindmap
      case types.MINDMAP_SET: {
        draft.mindmapForm = action.payload.mindmapForm;
        break;
      }
      case types.MINDMAP_EDITOR_SET: {
        draft.mindmapEditorForm = action.payload.mindmapEditorForm;
        break;
      }
      case types.MINDMAP_REQUEST_SUCCESS: {
        draft.mindmaps = action.payload.mindmaps;
        break;
      }
      default:
    }
  });
}
