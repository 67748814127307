import types from './types';

export function signInRequest(email, password) {
  return {
    type: types.SIGN_IN_REQUEST,
    payload: { email, password },
  };
}

export function signInSuccess(token, user, refreshToken) {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: { token, user, refreshToken },
  };
}

export function signUpRequest(user) {
  return {
    type: types.SIGN_UP_REQUEST,
    payload: { user },
  };
}

export function signFailure() {
  return {
    type: types.SIGN_FAILURE,
  };
}

export function signOut() {
  return {
    type: types.SIGN_OUT,
  };
}

export function check() {
  return {
    type: types.CHECK,
  };
}

export function setSigned(signed) {
  return {
    type: types.SET_SIGNED,
    payload: { signed },
  };
}
