import React, { useRef } from 'react';
import * as Yup from 'yup';
import { FaClock, FaSave } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';
import { getValidationErrors } from '~/utils';
import hoursToPush from '~/utils/hoursToPush';
import {
  Unform,
  UnformSelect,
  UnformCheckboxGroup,
  ButtonSave,
} from './styles';

export default function ModalEdit({ onCLose, onSubmit, editItem }) {
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    periods: Yup.array().required(
      t('settings.modal-edit.field.periods.message.required')
    ),
    hour: Yup.string().required(
      t('settings.modal-edit.field.hour.message.required')
    ),
  });

  const weekNames = [
    { id: 0, value: 0, label: t('settings.setting-item.week-names.sunday') },
    { id: 1, value: 1, label: t('settings.setting-item.week-names.monday') },
    { id: 2, value: 2, label: t('settings.setting-item.week-names.tuesday') },
    { id: 3, value: 3, label: t('settings.setting-item.week-names.wednesday') },
    { id: 4, value: 4, label: t('settings.setting-item.week-names.thursday') },
    { id: 5, value: 5, label: t('settings.setting-item.week-names.friday') },
    { id: 6, value: 6, label: t('settings.setting-item.week-names.saturday') },
  ];

  return (
    <Modal
      visible
      width="450px"
      height="auto"
      effect="fadeInUp"
      title={t('settings.modal-edit.title')}
      onClose={onCLose}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            onSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={editItem}
        ref={formRef}
      >
        <UnformSelect
          icon={FaClock}
          label={t('settings.modal-edit.field.hour.label')}
          name="hour"
          options={hoursToPush}
        />
        <h2>{t('settings.modal-edit.field.periods.label')}</h2>
        <UnformCheckboxGroup name="periods" options={weekNames} />
        <ButtonSave
          type="submit"
          title={t('settings.modal-edit.save')}
          icon={FaSave}
          gradient
        />
      </Unform>
    </Modal>
  );
}

ModalEdit.propTypes = {
  onCLose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editItem: PropTypes.shape().isRequired,
};
