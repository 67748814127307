import styled from 'styled-components';
import AvatarComponent from '~/components/Avatar';
import ButtonComponent from '~/components/Button';

export const Image = styled(AvatarComponent).attrs({
  width: 40,
  height: 40,
})``;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
`;

export const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  max-height: 200px;
  overflow-y: auto;
`;

export const TableButton = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginLeft: 10,
  },
})``;
