import React from 'react';
import LoaderSpinner from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { useSelector } from 'react-redux';

import colors from '~/styles/colors';

import { Container } from './styles';

export default function Loader() {
  const loading = useSelector(state => state.loader.loading);
  return (
    loading && (
      <Container>
        <LoaderSpinner
          type="ThreeDots"
          color={colors.primary}
          height={100}
          width={100}
        />
      </Container>
    )
  );
}
