import styled, { css } from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.accent3};
  border: 1px solid ${colors.accent3};
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px;
  ${props =>
    props.expired &&
    css`
      opacity: 0.5;
    `}
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10px;
  overflow: hidden;
  > h1 {
    width: 100%;
    color: ${colors.secondary};
    font-size: 30px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > h2 {
    width: 100%;
    color: ${colors.white};
    font-size: 20px;
    font-weight: 300;
    margin-top: 3px;
  }
`;

export const PlanPrice = styled.div`
  width: 100px;
  height: 100px;
  border: 3px solid ${colors.secondary};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.white};

  span {
    width: 100%;
    text-align: left;
    padding: 0px 10px;
    font-size: 16px;
    font-weight: bold;
  }
  h2 {
    width: 100%;
    text-align: left;
    padding: 0px 10px;
    font-size: 25px;
    font-weight: 400;
  }
  small {
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
  }
`;
