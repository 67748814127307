/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  // Subject
  subjects: [],
  subjectModalCreate: false,
  subjectEdit: null,
  // Test
  tests: [],
  testForm: null,
  // Work
  works: [],
  workForm: null,
};

export default function subject(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      // Subject
      case types.SUBJECT_REQUEST_SUCCESS: {
        draft.subjects = action.payload.subjects;
        break;
      }
      case types.SUBJECT_SET_EDIT: {
        draft.subjectEdit = action.payload.subjectEdit;
        break;
      }
      case types.SUBJECT_SET_MODAL_CREATE: {
        draft.subjectModalCreate = action.payload.subjectModalCreate;
        break;
      }
      // Test
      case types.TEST_SET: {
        draft.testForm = action.payload.testForm;
        break;
      }
      case types.TESTS_REQUEST_SUCCESS: {
        draft.tests = action.payload.tests;
        break;
      }
      // Work
      case types.WORK_SET: {
        draft.workForm = action.payload.workForm;
        break;
      }
      case types.WORKS_REQUEST_SUCCESS: {
        draft.works = action.payload.works;
        break;
      }
      default:
    }
  });
}
