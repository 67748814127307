import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import {
  agendaRequestSuccess,
  agendaSetModalCreate,
  agendaRequest,
  agendaSetUpdate,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* requestEvent() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'events');
    // Stop loading
    yield put(stopLoader());

    yield put(agendaRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.agenda.request-event.error'));
  }
}

export function* createEvent({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { event } = payload;

    yield call(api.post, 'events', event);
    // Stop loading
    yield put(stopLoader());

    yield put(agendaSetModalCreate(false));
    yield put(agendaRequest());

    toast.success(i18n.t('store.agenda.create-event.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.agenda.create-event.error'));
  }
}

export function* updateEvent({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...event } = payload.event;

    yield call(api.put, `events/${id}`, event);
    // Stop loading
    yield put(stopLoader());

    yield put(agendaSetUpdate(null));
    yield put(agendaRequest());

    toast.success(i18n.t('store.agenda.update-event.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.agenda.update-event.error'));
  }
}

export function* deleteEvent({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { eventId } = payload;

    yield call(api.delete, `events/${eventId}`);
    // Stop loading
    yield put(stopLoader());
    yield put(agendaSetUpdate(null));
    yield put(agendaRequest());
    toast.success(i18n.t('store.agenda.delete-event.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.agenda.delete-event.error'));
  }
}

export default all([
  takeLatest(types.AGENDA_REQUEST, requestEvent),
  takeLatest(types.AGENDA_CREATE, createEvent),
  takeLatest(types.AGENDA_UPDATE, updateEvent),
  takeLatest(types.AGENDA_DELETE, deleteEvent),
]);
