/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  filter: '',
  results: [],
};

export default function search(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SEARCH_REQUEST_SUCCESS: {
        draft.results = action.payload.results;
        break;
      }
      case types.SEARCH_SET_FILTER: {
        draft.filter = action.payload.filter;
        break;
      }
      default:
    }
  });
}
