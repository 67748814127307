import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import iconSubjects from '~/assets/images/icons/subjects.svg';

import {
  Container,
  Image,
  CardBody,
  ClassTitle,
  ClassDate,
  ContainerHeader,
  ContainerContent,
  KeywordBox,
  QuestionBox,
  ConceptBox,
} from './styles';

export default function ClassItem({ classItem }) {
  const [concepts, setConcepts] = useState({
    success: [],
    warning: [],
    danger: [],
    fundamental: [],
  });

  const getConceptSuccess = () => {
    if (!classItem.content) return [];
    const mapSuccess = classItem.content.filter(
      i =>
        i.attributes &&
        i.attributes.conceptSuccess &&
        i.attributes.conceptSuccess === 'true'
    );
    return mapSuccess.map(i => i.insert);
  };

  const getConceptWarning = () => {
    if (!classItem.content) return [];
    const mapWarning = classItem.content.filter(
      i =>
        i.attributes &&
        i.attributes.conceptWarning &&
        i.attributes.conceptWarning === 'true'
    );
    return mapWarning.map(i => i.insert);
  };

  const getConceptDanger = () => {
    if (!classItem.content) return [];
    const mapDanger = classItem.content.filter(
      i =>
        i.attributes &&
        i.attributes.conceptDanger &&
        i.attributes.conceptDanger === 'true'
    );
    return mapDanger.map(i => i.insert);
  };

  const getConceptFundamental = () => {
    if (!classItem.content) return [];
    const mapDanger = classItem.content.filter(
      i =>
        i.attributes &&
        i.attributes.conceptFundamental &&
        i.attributes.conceptFundamental === 'true'
    );
    return mapDanger.map(i => i.insert);
  };

  useEffect(() => {
    setConcepts({
      success: getConceptSuccess(),
      warning: getConceptWarning(),
      danger: getConceptDanger(),
      fundamental: getConceptFundamental(),
    });
  }, []);

  return (
    <Container>
      <ContainerHeader>
        <Image src={iconSubjects} />
        <CardBody>
          <ClassTitle>{classItem.title}</ClassTitle>
          <ClassDate>
            <b>Criado em: </b>
            {moment(classItem.created_at).format('DD/MM/YYYY HH:mm')}
          </ClassDate>
          <ClassDate>
            <b>Última atualização: </b>
            {moment(classItem.updated_at).format('DD/MM/YYYY HH:mm')}
          </ClassDate>
        </CardBody>
      </ContainerHeader>
      <ContainerContent>
        {classItem.classKeywords.length > 0 && (
          <KeywordBox>
            <h2>Palavras-chave</h2>
            <p>
              {classItem.classKeywords.map(item => item.description).join(', ')}
            </p>
          </KeywordBox>
        )}
        {classItem.classQuestions.length > 0 && (
          <QuestionBox>
            <h2>Questões</h2>
            <ul>
              {classItem.classQuestions.map((item, index) => (
                <li>{`${index + 1} - ${item.description}`}</li>
              ))}
            </ul>
          </QuestionBox>
        )}
        {(concepts.success.length > 0 ||
          concepts.warning.length > 0 ||
          concepts.fundamental.length > 0 ||
          concepts.danger.length > 0) && (
          <ConceptBox>
            <h2>Conceitos</h2>
            {concepts.fundamental.length > 0 && (
              <>
                <h3>Fundamental</h3>
                <ul className="concept-fundamental">
                  {concepts.fundamental.map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </>
            )}
            {concepts.success.length > 0 && (
              <>
                <h3>Dominado</h3>
                <ul className="concept-success">
                  {concepts.success.map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </>
            )}
            {concepts.warning.length > 0 && (
              <>
                <h3>Dúvida</h3>
                <ul className="concept-warning">
                  {concepts.warning.map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </>
            )}
            {concepts.danger.length > 0 && (
              <>
                <h3>Sem conhecimento</h3>
                <ul className="concept-danger">
                  {concepts.danger.map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </>
            )}
          </ConceptBox>
        )}
        {/* classItem.classConcepts.length > 0 && (
          <ConceptBox>
            <h2>Conceitos</h2>
            {getConceptSuccess().length > 0 && (
              <>
                <h3>Conceitos dominado</h3>
                <ul className="concept-success">
                  {getConceptSuccess().map(item => (
                    <li>{item.description}</li>
                  ))}
                </ul>
              </>
            )}
            {getConceptWarning().length > 0 && (
              <>
                <h3>Conceitos em dúvida</h3>
                <ul className="concept-warning">
                  {getConceptWarning().map(item => (
                    <li>{item.description}</li>
                  ))}
                </ul>
              </>
            )}
            {getConceptDanger().length > 0 && (
              <>
                <h3>Conceitos sem conhecimento</h3>
                <ul className="concept-danger">
                  {getConceptDanger().map(item => (
                    <li>{item.description}</li>
                  ))}
                </ul>
              </>
            )}
          </ConceptBox>
        ) */}
      </ContainerContent>
    </Container>
  );
}

ClassItem.defaultProps = {
  // shareAction: null,
};

ClassItem.propTypes = {
  classItem: PropTypes.shape().isRequired,
};
