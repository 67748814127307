import types from './types';

export function startLoader() {
  return {
    type: types.START_LOADER,
  };
}

export function stopLoader() {
  return {
    type: types.STOP_LOADER,
  };
}
