import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { FaPlus } from 'react-icons/fa';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';

import SearchBox from '~/components/SearchBox';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import ModalEdit from './components/ModalEdit';
import ModalCreate from './components/ModalCreate';
import CertificateItem from './components/CertificateItem';
import iconCertificates from '~/assets/images/icons/licencing.svg';
import Tour from '~/components/Tour';
import TourButton from '~/components/TourButton';
import HasPermission from '~/components/HasPermission';

import {
  certificateRequest,
  certificateSetEdit,
  certificateSetModalCreate,
  deleteCertificateRequest,
} from '~/store/modules/certificate/actions';
import { tourSetState } from '~/store/modules/tour/actions';

import colors from '~/styles/colors';
import {
  Content,
  CertificateList,
  NewCertificate,
  HeaderButton,
} from './styles';

const Certificates = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { certificates, certificateModalCreate, certificateEdit } = useSelector(
    state => state.certificate
  );
  const { showedCertificates } = useSelector(state => state.tour);
  const [searchValue, setSearchValue] = useState('');
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    { selector: '#certificate-new', content: t('tour.certificates.new') },
    { selector: '#certificate-search', content: t('tour.certificates.search') },
  ];

  useEffect(() => {
    dispatch(certificateRequest());
    if (!showedCertificates) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const handleDeleteCertificate = certificateId => {
    confirmAlert({
      title: t('certificates.confirm-alert.delete-certificate.title'),
      message: t('certificates.confirm-alert.delete-certificate.message'),
      buttons: [
        {
          label: t(
            'certificates.confirm-alert.delete-certificate.buttons.cancel'
          ),
        },
        {
          label: t('certificates.confirm-alert.delete-certificate.buttons.yes'),
          onClick: () => {
            dispatch(deleteCertificateRequest(certificateId));
          },
        },
      ],
    });
  };

  const renderHeader = () => (
    <PageHeader
      breadcrumb={[t('certificates.breadcrumb.1')]}
      actionElements={() => (
        <>
          <TourButton onClick={() => setTurIsOpen(true)} />
          <HasPermission>
            <HeaderButton
              id="certificate-new"
              type="button"
              title={t('certificates.new-certificate')}
              icon={FaPlus}
              onClick={() => {
                dispatch(certificateSetModalCreate(true));
              }}
              color={colors.secondary}
            />
          </HasPermission>
        </>
      )}
    />
  );

  const renderSearchBox = () => (
    <SearchBox
      id="certificate-search"
      placeholder={t('certificates.search')}
      value={searchValue}
      onChange={val => setSearchValue(val)}
    />
  );

  const downloadFileFromServer = async certificate => {
    FileSaver.saveAs(
      certificate.file_url,
      certificate.file_url.split('/').pop()
    );
  };

  const renderCertificates = () => {
    const certificateList = certificates.filter(certificate => {
      const certificateDescription = certificate.title.toUpperCase();
      const certificateFilter = searchValue.toUpperCase();
      return certificateDescription.includes(certificateFilter);
    });
    return (
      <CertificateList>
        {certificates.length > 0 ? (
          certificateList.map(item => (
            <CertificateItem
              key={item.id}
              downloadAction={() => {
                downloadFileFromServer(item);
              }}
              editAction={() => {
                dispatch(certificateSetEdit(item));
              }}
              deleteAction={() => {
                handleDeleteCertificate(item.id);
              }}
              imageCard={iconCertificates}
              title={item.title}
              description={item.description}
              initialDate={item.initial_date}
              finalDate={item.final_date}
              workload={item.workload}
            />
          ))
        ) : (
          <NewCertificate
            onClick={() => {
              dispatch(certificateSetModalCreate(true));
            }}
          >
            <FaPlus />
            {t('certificates.new-certificate')}
          </NewCertificate>
        )}
      </CertificateList>
    );
  };

  const renderContent = () => (
    <Content>
      {renderSearchBox()}
      {renderCertificates()}
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {certificateEdit && <ModalEdit />}
        {certificateModalCreate && <ModalCreate />}
        <Tour
          steps={tourSteps}
          isOpen={turIsOpen}
          onRequestClose={() => setTurIsOpen(false)}
          closeWithMask={false}
          startAt={0}
          onClose={() => {
            dispatch(tourSetState({ showedCertificates: true }));
          }}
        />
      </PageContent>
    </>
  );
};

export default Certificates;
