import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  height: 55px;
  max-height: 55px;
  min-height: 55px;
  width: 100%;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex: 1;
  color: ${colors.white};
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  padding-left: 10px;
  span {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 300;
    svg {
      margin: 0 10px;
      min-height: 20px;
      min-width: 20px;
    }
  }
`;

export const HeaderAction = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

export const HeaderBack = styled.button`
  display: flex;
  width: 35px;
  height: 35px;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50%;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 20px;
  background-image: linear-gradient(
    90deg,
    ${colors.primary} 0%,
    ${colors.secondary} 100%
  );
  color: ${colors.white};
  margin-right: 10px;
  &:hover {
    background-image: linear-gradient(
      90deg,
      ${colors.secondary} 0%,
      ${colors.primary} 100%
    );
  }
`;
