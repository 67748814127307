import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import CheckboxGroupComponent from '~/components/Unform/CheckboxGroup';

import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const ClassList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  max-height: 200px;
  .keyword-item {
    display: flex;
    width: 100%;
    padding: 5px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.dark1};
    &:last-child {
      border: 0;
    }
    .keyword-description {
      display: flex;
      flex: 1;
    }
    .keyword-action {
      display: flex;
    }
  }
  > div {
    flex: none;
  }
`;

export const ClassExport = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    marginTop: 20,
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
`;

export const CheckboxGroup = styled(CheckboxGroupComponent)`
  margin-bottom: 10px;
`;
