/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { Container, Input, Label, PasswordEye, WrapIcon } from './styles';

const InputMaskComponent = ({
  label,
  name,
  type,
  icon: Icon,
  style,
  className,
  mask,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [inputType, setInputType] = useState(type);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  // new-password
  return (
    <Container
      style={style}
      className={className}
      isFocused={inputIsFocused || !!inputValue}
      hasIcon={!!Icon}
    >
      <Input
        ref={inputRef}
        type={inputType}
        mask={mask}
        {...rest}
        name={name}
        defaultValue={inputValue}
        onBlur={() => setInputIsFocused(false)}
        onFocus={() => setInputIsFocused(true)}
        onChange={e => setInputValue(e.target.value)}
        placeholder=" "
        autoComplete="new-password"
      />
      {!!label && <Label htmlFor={name}>{label}</Label>}
      {type === 'password' && (
        <PasswordEye
          type="button"
          onClick={() => {
            setInputType(inputType === 'password' ? 'text' : type);
          }}
        >
          {inputType === 'password' && <FaEye />}
          {inputType !== 'password' && <FaEyeSlash />}
        </PasswordEye>
      )}
      {Icon && (
        <WrapIcon>
          <Icon />
        </WrapIcon>
      )}
      {error && <span className="error">{error}</span>}
    </Container>
  );
};

InputMaskComponent.defaultProps = {
  label: '',
  name: '',
  mask: '',
  type: 'text',
  icon: null,
  style: null,
};

InputMaskComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.string,
  icon: PropTypes.func,
  style: PropTypes.shape(),
  className: PropTypes.string.isRequired,
};

export default InputMaskComponent;
