/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as Yup from 'yup';
import {
  FaRegEnvelope,
  FaSignature,
  FaIdCard,
  FaUniversity,
  FaToolbox,
  FaMobileAlt,
} from 'react-icons/fa';
import { AiFillLock } from 'react-icons/ai';
import { MdGTranslate, MdAccessTime, MdDateRange } from 'react-icons/md';
import config from '~/config';
import i18n from '~/i18n';

import { signUpRequest } from '~/store/modules/auth/actions';
import { getValidationErrors, languagesList } from '~/utils';

import {
  Container,
  RegisterBoxTitle,
  ButtonRegister,
  LoginBox,
  LinkLogin,
  FormScroll,
  FormAccept,
  // form register
  Unform,
  UnformInput,
  UnformSelect,
  UnformCheckbox,
} from './styles';

const initialData = {
  name: '',
  email: '',
  password: '',
  language: 'en',
  document: '',
  cell_phone: '',
  college: '',
  careers: '',
};

export default function SignUp() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    // UserData validation
    accept_terms: Yup.boolean()
      .required(t('auth.sign-up.field.accept_terms.message.required'))
      .oneOf([true], t('auth.sign-up.field.accept_terms.message.required')),
    name: Yup.string().required(t('auth.sign-up.field.name.message.required')),
    document: Yup.string().required(
      t('auth.sign-up.field.document.message.required')
    ),
    email: Yup.string()
      .email(t('auth.sign-up.field.email.message.email'))
      .required(t('auth.sign-up.field.email.message.required')),
    password: Yup.string()
      .min(6, t('auth.sign-up.field.password.message.min'))
      .required(t('auth.sign-up.field.password.message.required')),
    language: Yup.string().required(
      t('auth.sign-up.field.language.message.required')
    ),
    college: Yup.string().required(
      t('auth.sign-up.field.college.message.required')
    ),
    careers: Yup.string().required(
      t('auth.sign-up.field.careers.message.required')
    ),
    cell_phone: Yup.string().required(
      t('auth.sign-up.field.cell-phone.message.required')
    ),
    avatar: Yup.mixed(),
    locale_config: Yup.object({
      dateFormat: Yup.string().required(
        t('auth.sign-up.field.locale_config.dateFormat.message.required')
      ),
      tzCode: Yup.string().required(
        t('auth.sign-up.field.locale_config.tzCode.message.required')
      ),
    }),
  });

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current.setErrors({});
        await schema.validate(data, { abortEarly: false });
        dispatch(
          signUpRequest({
            ...data,
            role: 'student',
            active: true,
          })
        );
      } catch (err) {
        formRef.current.setErrors(getValidationErrors(err));
      }
    },
    [i18n.language]
  );

  return (
    <Container>
      <RegisterBoxTitle>{t('auth.sign-up.title')}</RegisterBoxTitle>
      <Unform onSubmit={handleSubmit} initialData={initialData} ref={formRef}>
        <FormScroll>
          <UnformInput
            icon={FaSignature}
            type="text"
            label={t('auth.sign-up.field.name.label')}
            name="name"
            style={{ marginBottom: 20 }}
          />
          <UnformInput
            icon={FaIdCard}
            type="text"
            label={t('auth.sign-up.field.document.label')}
            name="document"
            style={{ marginBottom: 20 }}
          />
          <UnformInput
            icon={FaRegEnvelope}
            type="text"
            label={t('auth.sign-up.field.email.label')}
            name="email"
            style={{ marginBottom: 20 }}
          />
          <UnformInput
            icon={AiFillLock}
            type="password"
            label={t('auth.sign-up.field.password.label')}
            name="password"
            style={{ marginBottom: 20 }}
          />
          <UnformSelect
            icon={MdGTranslate}
            label={t('auth.sign-up.field.language.label')}
            name="language"
            style={{ marginBottom: 20 }}
            options={languagesList.map(item => ({
              label: t(`language.${item}`),
              value: item,
            }))}
          />
          <UnformInput
            icon={FaUniversity}
            type="text"
            label={t('auth.sign-up.field.college.label')}
            name="college"
            style={{ marginBottom: 20 }}
          />
          <UnformInput
            icon={FaToolbox}
            type="text"
            label={t('auth.sign-up.field.careers.label')}
            name="careers"
            style={{ marginBottom: 20 }}
          />
          <UnformInput
            icon={FaMobileAlt}
            type="text"
            label={t('auth.sign-up.field.cell-phone.label')}
            name="cell_phone"
          />
          <UnformSelect
            icon={MdAccessTime}
            label={t('auth.sign-up.field.locale_config.tzCode.label')}
            name="locale_config.tzCode"
            style={{ marginBottom: 20 }}
            options={config.timezones}
            menuPlacement="top"
          />
          <UnformSelect
            icon={MdDateRange}
            label={t('auth.sign-up.field.locale_config.dateFormat.label')}
            name="locale_config.dateFormat"
            style={{ marginBottom: 20 }}
            options={config.formatDates.map(i => ({
              ...i,
              label: `${i.label} (${moment().format(i.value)})`,
            }))}
            menuPlacement="top"
          />
        </FormScroll>
        <FormAccept>
          <UnformCheckbox
            name="accept_terms"
            label={
              <span className="componenrt-accept">
                {t('auth.sign-up.field.accept_terms.label1')}
                <a
                  href={`${config.SITE_URL}/terms`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('auth.sign-up.field.accept_terms.label2')}
                </a>
              </span>
            }
          />
        </FormAccept>
        <ButtonRegister
          type="submit"
          title={t('auth.sign-up.register')}
          gradient
        />
        <LoginBox>
          {t('auth.sign-up.already-registered')}
          <LinkLogin to="/signIn">{t('auth.sign-up.sign-in')}</LinkLogin>
        </LoginBox>
      </Unform>
    </Container>
  );
}
