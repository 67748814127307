import React from 'react';
import {
  FaTrash,
  FaEye,
  FaPen,
  FaShare,
  FaInfo,
  FaCloudDownloadAlt,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import colors from '~/styles/colors';
import HasPermission from '~/components/HasPermission';
import {
  Container,
  Image,
  CardBody,
  CardActions,
  CardActionButton,
} from './styles';

export default function ClassItem({
  shareAction,
  exportPdfAction,
  editAction,
  viewAction,
  deleteAction,
  sharedBy,
  imageCard,
  title,
  date,
}) {
  const { t } = useTranslation();
  return (
    <Container>
      {imageCard && <Image src={imageCard} />}
      <CardBody onClick={() => viewAction()}>
        <h1>{title}</h1>
        <h2>{t('classes.class-item.created-on', { date })}</h2>
      </CardBody>
      <CardActions>
        {viewAction && (
          <CardActionButton
            data-tip={t('classes.class-item.view')}
            onClick={() => viewAction()}
          >
            <FaEye />
          </CardActionButton>
        )}
        <HasPermission>
          {shareAction && (
            <CardActionButton
              data-tip={t('classes.class-item.share')}
              onClick={() => shareAction()}
            >
              <FaShare />
            </CardActionButton>
          )}
        </HasPermission>
        <HasPermission>
          {!!exportPdfAction && (
            <CardActionButton
              data-tip={t('classes.class-item.export')}
              onClick={() => exportPdfAction()}
            >
              <FaCloudDownloadAlt />
            </CardActionButton>
          )}
        </HasPermission>
        <HasPermission>
          {!!sharedBy && (
            <CardActionButton
              data-tip={t('classes.class-item.shared-by', { name: sharedBy })}
            >
              <FaInfo />
            </CardActionButton>
          )}
        </HasPermission>
        <HasPermission>
          {editAction && (
            <CardActionButton
              data-tip={t('classes.class-item.edit')}
              onClick={() => editAction()}
            >
              <FaPen />
            </CardActionButton>
          )}
        </HasPermission>
        <HasPermission>
          {deleteAction && (
            <CardActionButton
              data-tip={t('classes.class-item.delete')}
              onClick={() => deleteAction()}
              color={colors.accent2}
            >
              <FaTrash />
            </CardActionButton>
          )}
        </HasPermission>
      </CardActions>
      <ReactTooltip />
    </Container>
  );
}

ClassItem.defaultProps = {
  shareAction: null,
  exportPdfAction: null,
  editAction: null,
  viewAction: null,
  deleteAction: null,
  imageCard: null,
  title: '',
  date: '',
  sharedBy: '',
};

ClassItem.propTypes = {
  shareAction: PropTypes.func,
  exportPdfAction: PropTypes.func,
  editAction: PropTypes.func,
  viewAction: PropTypes.func,
  deleteAction: PropTypes.func,
  imageCard: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  sharedBy: PropTypes.string,
};
