/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  shareds: [],
};

export default function shared(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SHARED_REQUEST_SUCCESS: {
        draft.shareds = action.payload.shareds;
        break;
      }
      default:
    }
  });
}
