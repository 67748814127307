/* eslint-disable no-param-reassign */
import produce from 'immer';
import { toast } from 'react-toastify';
import types from './types';

const INITIAL_STATE = {
  channels: [],
  messages: [],
  channelSelectedId: null,
  chatModalStatus: false,
};

export default function period(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      // Canais de amigos
      case types.CHAT_CHANNELS_REQUEST_SUCCESS: {
        draft.channels = action.payload.channels;
        break;
      }
      // Mensagens do canal
      case types.CHAT_MESSAGE_REQUEST_SUCCESS: {
        draft.messages = action.payload.messages;
        break;
      }
      // adicionando nova mensagem do websocket na lista de mensagens
      case types.CHAT_ADD_NEW_MESSAGE: {
        if (
          action.payload.message.user_channel_id === draft.channelSelectedId &&
          draft.chatModalStatus
        ) {
          draft.messages = [...draft.messages, action.payload.message];
        } else {
          toast.info(`
            ${action.payload.message.user.name} diz:
            ${action.payload.message.message}
          `);
        }
        break;
      }
      // setando canal selecionado
      case types.CHAT_SET_CHANNEL_SELECTED: {
        draft.channelSelectedId = action.payload.channelSelectedId;
        break;
      }
      // alterando status do modal
      case types.CHAT_SET_MODAL_STATUS: {
        draft.chatModalStatus = action.payload.chatModalStatus;
        break;
      }
      // alterando canal na lista
      case types.UPDATE_CHANNEL_IN_LIST: {
        draft.channels = draft.channels.map(item => {
          if (action.payload.channelToUpdate.id === item.id) {
            return action.payload.channelToUpdate;
          }
          return item;
        });
        break;
      }
      default:
    }
  });
}
