import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import SearchBox from '~/components/SearchBox';
import iconSubjects from '~/assets/images/icons/subjects.svg';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import Route from '~/services/history';
import Tour from '~/components/Tour';
import TourButton from '~/components/TourButton';
import HasPermission from '~/components/HasPermission';

import ClassItem from './components/ClassItem';
import ModalEdit from './components/ModalEdit';
import ModalCreate from './components/ModalCreate';
import ModalTests from './components/ModalTests';
import ModalWorks from './components/ModalWorks';
import ModalShare from './components/ModalShare';

import {
  classesRequest,
  deleteClassRequest,
  classSetEdit,
  classSetModalCreate,
} from '~/store/modules/class/actions';

import {
  subjectTestRequest,
  subjectWorkRequest,
  exportPdfClasses,
} from '~/store/modules/subject/actions';
import { tourSetState } from '~/store/modules/tour/actions';

import colors from '~/styles/colors';
import { NewClass, HeaderButton, Content, ClassList } from './styles';

const Classes = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes, classeEdit, classeModalCreate } = useSelector(
    state => state.classe
  );
  const { showedClasses } = useSelector(state => state.tour);
  const { tests, works } = useSelector(state => state.subject);
  const [searchValue, setSearchValue] = useState('');
  const [classToShare, setClassToShare] = useState('');
  const [modalTestVisible, setModalTestVisible] = useState(false);
  const [modalWorkVisible, setModalWorkVisible] = useState(false);
  const [modalShareOpened, setModalShareOpened] = useState(false);
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    { selector: '#class-new', content: t('tour.classes.new') },
    { selector: '#class-test', content: t('tour.classes.test') },
    { selector: '#class-work', content: t('tour.classes.work') },
    // { selector: '#class-search', content: t('tour.classes.search') },
  ];

  const { periodId, subjectId } = match.params;

  useEffect(() => {
    dispatch(classesRequest(subjectId));
    dispatch(subjectTestRequest(subjectId));
    dispatch(subjectWorkRequest(subjectId));
    if (!showedClasses) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const handleDeleteClass = classe => {
    confirmAlert({
      title: t('classes.confirm-alert.delete-class.title'),
      message: t('classes.confirm-alert.delete-class.message'),
      buttons: [
        {
          label: t('classes.confirm-alert.delete-class.buttons.cancel'),
        },
        {
          label: t('classes.confirm-alert.delete-class.buttons.yes'),
          onClick: () => {
            dispatch(deleteClassRequest({ ...classe, subject_id: subjectId }));
          },
        },
      ],
    });
  };

  const renderHeader = () => (
    <PageHeader
      onBack={() => {
        Route.replace(`/subjects/${periodId}`);
      }}
      breadcrumb={[
        t('classes.breadcrumb.1'),
        t('classes.breadcrumb.2'),
        t('classes.breadcrumb.3'),
      ]}
      actionElements={() => (
        <>
          <TourButton onClick={() => setTurIsOpen(true)} />
          <HeaderButton
            id="class-work"
            title={t('classes.works')}
            onClick={() => {
              setModalWorkVisible(true);
            }}
            badge={works.length}
            color={colors.primary}
          />
          <HeaderButton
            id="class-test"
            title={t('classes.tests')}
            onClick={() => {
              setModalTestVisible(true);
            }}
            badge={tests.length}
            color={colors.primary}
          />
          <HasPermission>
            <HeaderButton
              id="class-new"
              type="button"
              title={t('classes.new-class')}
              icon={FaPlus}
              onClick={() => {
                dispatch(classSetModalCreate(true));
              }}
              color={colors.secondary}
            />
          </HasPermission>
        </>
      )}
    />
  );

  const renderSearchBox = () => (
    <SearchBox
      id="class-search"
      placeholder={t('classes.search')}
      value={searchValue}
      onChange={val => setSearchValue(val)}
    />
  );

  const renderClasses = () => {
    const classeList = classes.filter(classe => {
      const classeDescription = classe.title.toUpperCase();
      const classeFilter = searchValue.toUpperCase();
      return classeDescription.includes(classeFilter);
    });
    return (
      <ClassList>
        {classes.length > 0 ? (
          classeList.map(item => (
            <ClassItem
              key={item.id}
              shareAction={() => {
                setClassToShare(item.id);
                setModalShareOpened(true);
              }}
              deleteAction={() => {
                handleDeleteClass(item);
              }}
              editAction={() => {
                dispatch(
                  classSetEdit({
                    ...item,
                    subject_tests_ids: item.subjectTests.map(test => test.id),
                  })
                );
              }}
              viewAction={() => {
                Route.replace(`/classes/${periodId}/${subjectId}/${item.id}`);
              }}
              sharedBy={item.userShared ? item.userShared.name : ''}
              exportPdfAction={() => {
                dispatch(exportPdfClasses([item.id]));
              }}
              imageCard={iconSubjects}
              title={item.title}
              date={item.created_at}
            />
          ))
        ) : (
          <NewClass
            onClick={() => {
              dispatch(classSetModalCreate(true));
            }}
          >
            <FaPlus />
            {t('classes.new-class')}
          </NewClass>
        )}
        {}
      </ClassList>
    );
  };

  const renderContent = () => (
    <Content>
      {renderSearchBox()}
      {renderClasses()}
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {classeEdit && <ModalEdit subjectId={subjectId} />}
        {classeModalCreate && <ModalCreate subjectId={subjectId} />}
        {modalTestVisible && (
          <ModalTests
            subjectId={subjectId}
            onClose={() => {
              setModalTestVisible(false);
            }}
          />
        )}
        {modalWorkVisible && (
          <ModalWorks
            subjectId={subjectId}
            onClose={() => {
              setModalWorkVisible(false);
            }}
          />
        )}
        {modalShareOpened && (
          <ModalShare
            classToShare={classToShare}
            onClose={() => {
              setClassToShare('');
              setModalShareOpened(false);
            }}
          />
        )}
        <Tour
          steps={tourSteps}
          isOpen={turIsOpen}
          onRequestClose={() => setTurIsOpen(false)}
          closeWithMask={false}
          startAt={0}
          onClose={() => {
            dispatch(tourSetState({ showedClasses: true }));
          }}
        />
      </PageContent>
    </>
  );
};

Classes.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      periodId: PropTypes.string,
      subjectId: PropTypes.string,
    }),
  }).isRequired,
};

export default Classes;
