import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { FaThumbsUp } from 'react-icons/fa';

import Modal from '~/components/Modal';
import Table from '~/components/Table';
import colors from '~/styles/colors';

import { friendInvitesRequestAccept } from '~/store/modules/friend/actions';

import { ModalBody, ListBox, Image, TableButton } from './styles';

export default function ModalInvites({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { friendsInvites } = useSelector(state => state.friend);

  const handleAcceptFriend = friendId => {
    confirmAlert({
      title: t('friends.modal-invites.confirm-alert.accept-friend.title'),
      message: t('friends.modal-invites.confirm-alert.accept-friend.message'),
      buttons: [
        {
          label: t(
            'friends.modal-invites.confirm-alert.accept-friend.buttons.cancel'
          ),
        },
        {
          label: t(
            'friends.modal-invites.confirm-alert.accept-friend.buttons.yes'
          ),
          onClick: () => {
            dispatch(friendInvitesRequestAccept(friendId));
          },
        },
      ],
    });
  };

  const renderTable = () => (
    <ModalBody>
      <ListBox>
        <Table>
          <tbody>
            {friendsInvites.length === 0 && (
              <tr>
                <td align="center">{t('friends.modal-invites.empty')}</td>
              </tr>
            )}
            {friendsInvites.map(item => (
              <tr key={item.id}>
                <td width="1%">
                  <Image src={item.user.avatar_url} />
                </td>
                <td width="50%">{item.user.name}</td>
                <td width="50%">{item.user.email}</td>
                <td width="1%">
                  <TableButton
                    type="button"
                    title={t('friends.modal-invites.accept-friend')}
                    icon={FaThumbsUp}
                    onClick={() => {
                      handleAcceptFriend(item.id);
                    }}
                    color={colors.secondary}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ListBox>
    </ModalBody>
  );

  return (
    <Modal
      visible
      width="600px"
      height="auto"
      effect="fadeInUp"
      title={t('friends.modal-invites.title')}
      onClose={() => {
        onClose();
      }}
    >
      {renderTable()}
    </Modal>
  );
}

ModalInvites.propTypes = {
  onClose: PropTypes.func.isRequired,
};
