import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import {
  FaTrash,
  FaPlus,
  FaPen,
  FaFilePdf,
  FaFileWord,
  FaFileAlt,
  FaFileCode,
  FaFileExcel,
  FaFileVideo,
  FaFileImage,
  FaFile,
  FaFolder,
  FaCloudDownloadAlt,
  FaFolderOpen,
  FaShare,
} from 'react-icons/fa';

import SearchBox from '~/components/SearchBox';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import HasPermission from '~/components/HasPermission';

import ModalCreateFolder from './components/ModalCreateFolder';
import ModalEditFolder from './components/ModalEditFolder';
import ModalUploadFile from './components/ModalUploadFile';
import ModalShare from './components/ModalShare';
import colors from '~/styles/colors';
import Tour from '~/components/Tour';
import TourButton from '~/components/TourButton';

import {
  driveRequest,
  driveSetModalFolderCreate,
  driveDeleteRequest,
  driveFolderSetEdit,
  driveSetModalFileCreate,
} from '~/store/modules/drive/actions';
import { tourSetState } from '~/store/modules/tour/actions';

import { Content, DriveList, HeaderButton, Table } from './styles';

const Drive = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    drive: dirDrive,
    driveModalFolderCreate,
    driveModalFileCreate,
    folderEdit,
  } = useSelector(state => state.drive);
  const profile = useSelector(state => state.user.profile);
  const { showedDrive } = useSelector(state => state.tour);
  const [searchValue, setSearchValue] = useState('');
  const [driveToShare, setDriveToShare] = useState('');
  const [modalShareOpened, setModalShareOpened] = useState(false);
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    { selector: '#drive-new-file', content: t('tour.drive.new-file') },
    { selector: '#drive-new-folder', content: t('tour.drive.new-folder') },
    { selector: '#drive-search', content: t('tour.drive.search') },
  ];

  const handleLoadDir = dirId => {
    if (dirId) {
      dispatch(driveRequest(dirId));
    }
  };

  useEffect(() => {
    handleLoadDir(profile.userDrive.id);
    if (!showedDrive) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const handleDeleteDrive = drive => {
    confirmAlert({
      title: t('drive.confirm-alert.delete-item.title'),
      message: t('drive.confirm-alert.delete-item.message', {
        fileType:
          drive.type === 'folder'
            ? t('drive.confirm-alert.delete-item.fileType.folder')
            : t('drive.confirm-alert.delete-item.fileType.file'),
      }),
      buttons: [
        {
          label: 'Cancelar',
        },
        {
          label: 'Sim',
          onClick: () => {
            dispatch(driveDeleteRequest(drive));
          },
        },
      ],
    });
  };

  const renderHeader = () => {
    const breadcrumb = [t('drive.breadcrumb.1')];
    if (dirDrive) {
      breadcrumb.push(dirDrive.title);
    }
    return (
      <PageHeader
        breadcrumb={breadcrumb}
        onBack={
          dirDrive && dirDrive.user_drive_id
            ? () => {
                handleLoadDir(dirDrive.user_drive_id);
              }
            : null
        }
        actionElements={() => (
          <>
            <TourButton onClick={() => setTurIsOpen(true)} />
            <HasPermission>
              <>
                {dirDrive && !dirDrive.user_drive_id && (
                  <HeaderButton
                    id="drive-new-folder"
                    title={t('drive.new-folder')}
                    icon={FaPlus}
                    onClick={() => {
                      dispatch(driveSetModalFolderCreate(true));
                    }}
                    color={colors.primary}
                  />
                )}
                <HeaderButton
                  id="drive-new-file"
                  title={t('drive.new-file')}
                  icon={FaPlus}
                  onClick={() => {
                    dispatch(driveSetModalFileCreate(true));
                  }}
                  color={colors.secondary}
                />
              </>
            </HasPermission>
          </>
        )}
      />
    );
  };

  const downloadFileFromServer = async file => {
    FileSaver.saveAs(file.file_url, file.file_url.split('/').pop());
  };

  const renderSearchBox = () =>
    dirDrive &&
    dirDrive.childrens.length > 0 && (
      <SearchBox
        id="drive-search"
        placeholder={t('drive.search')}
        value={searchValue}
        onChange={val => setSearchValue(val)}
      />
    );

  const getIconFromFileType = itemFile => {
    const iconSize = 20;
    if (itemFile.type === 'folder') {
      return <FaFolder size={iconSize} color={colors.primary} />;
    }

    const fileName = itemFile.file_url.split('/').pop();
    const extFile = fileName
      .split('.')
      .pop()
      .toUpperCase();
    switch (extFile) {
      case 'PDF':
        return <FaFilePdf size={iconSize} color={colors.danger} />;
      case 'DOCX':
        return <FaFileWord size={iconSize} color={colors.info} />;
      case 'TXT':
        return <FaFileAlt size={iconSize} color={colors.accent1} />;
      case 'DOC':
        return <FaFileWord size={iconSize} color={colors.info} />;
      case 'HTML':
        return <FaFileCode size={iconSize} color={colors.secondary} />;
      case 'XLSX':
        return <FaFileExcel size={iconSize} color={colors.success} />;
      case 'MP4':
        return <FaFileVideo size={iconSize} color={colors.dark3} />;
      case 'WEBM':
        return <FaFileVideo size={iconSize} color={colors.dark3} />;
      case 'JPG':
        return <FaFileImage size={iconSize} color={colors.third} />;
      case 'JPEG':
        return <FaFileImage size={iconSize} color={colors.third} />;
      case 'PNG':
        return <FaFileImage size={iconSize} color={colors.third} />;
      case 'BMP':
        return <FaFileImage size={iconSize} color={colors.third} />;
      case 'SVG':
        return <FaFileImage size={iconSize} color={colors.third} />;
      default:
        return <FaFile size={iconSize} color={colors.secondary} />;
    }
  };

  const renderFiles = () => {
    const driveList = dirDrive.childrens.filter(drive => {
      const driveDescription = drive.title.toUpperCase();
      const driveFilter = searchValue.toUpperCase();
      return driveDescription.includes(driveFilter);
    });
    return (
      <DriveList>
        <Table style={{ marginTop: dirDrive.childrens.length > 0 ? 0 : 10 }}>
          <tbody>
            {dirDrive.childrens.length > 0 ? (
              driveList.map(item => (
                <tr key={item.id}>
                  <td width="1%">{getIconFromFileType(item)}</td>
                  <td width="50%">{item.title}</td>
                  <td width="1%" className="table-nowrap">
                    <b>{t('drive.drive-item.created-in')} </b>
                    {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                  </td>
                  <td width="1%">
                    <div className="table-action">
                      {item.type === 'file' && (
                        <button
                          data-tip={t('drive.drive-item.download')}
                          type="button"
                          className="table-action-download"
                          onClick={() => {
                            downloadFileFromServer(item);
                          }}
                        >
                          <FaCloudDownloadAlt />
                        </button>
                      )}
                      {item.type === 'folder' && (
                        <button
                          data-tip={t('drive.drive-item.open')}
                          type="button"
                          className="table-action-view"
                          onClick={() => {
                            handleLoadDir(item.id);
                          }}
                        >
                          <FaFolderOpen />
                        </button>
                      )}
                      <HasPermission>
                        <button
                          data-tip={t('drive.drive-item.share')}
                          type="button"
                          className="table-action-share"
                          onClick={() => {
                            setDriveToShare(item.id);
                            setModalShareOpened(true);
                          }}
                        >
                          <FaShare />
                        </button>
                      </HasPermission>
                      <HasPermission>
                        {item.type === 'folder' && (
                          <button
                            data-tip={t('drive.drive-item.edit')}
                            type="button"
                            className="table-action-edit"
                            onClick={() => {
                              dispatch(driveFolderSetEdit(item));
                            }}
                          >
                            <FaPen />
                          </button>
                        )}
                      </HasPermission>
                      <HasPermission>
                        <button
                          data-tip={t('drive.drive-item.delete')}
                          type="button"
                          className="table-action-delete"
                          onClick={() => {
                            handleDeleteDrive(item);
                          }}
                        >
                          <FaTrash />
                        </button>
                      </HasPermission>
                      <ReactTooltip />
                    </div>
                  </td>
                </tr>
                /*
                  <DriveItem
                    key={item.id}
                    item={item}
                    downloadAction={() => {
                      downloadFileFromServer(item);
                    }}
                    viewAction={() => {
                      handleLoadDir(item.id);
                    }}
                    shareAction={() => {
                      setDriveToShare(item.id);
                      setModalShareOpened(true);
                    }}
                    editAction={() => {
                      dispatch(driveFolderSetEdit(item));
                    }}
                    deleteAction={() => {
                      handleDeleteDrive(item);
                    }}
                  />
                */
              ))
            ) : (
              <tr>
                <td align="center">{t('drive.no-files')}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </DriveList>
    );
  };

  const renderContent = () => (
    <Content>
      {renderSearchBox()}
      {dirDrive && renderFiles()}
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {driveModalFolderCreate && dirDrive && (
          <ModalCreateFolder driveId={dirDrive.id} />
        )}
        {folderEdit && <ModalEditFolder />}
        {driveModalFileCreate && dirDrive && (
          <ModalUploadFile driveId={dirDrive.id} />
        )}
        {modalShareOpened && (
          <ModalShare
            driveToShare={driveToShare}
            onClose={() => {
              setDriveToShare('');
              setModalShareOpened(false);
            }}
          />
        )}
        <Tour
          steps={tourSteps}
          isOpen={turIsOpen}
          onRequestClose={() => setTurIsOpen(false)}
          closeWithMask={false}
          startAt={0}
          onClose={() => {
            dispatch(tourSetState({ showedDrive: true }));
          }}
        />
      </PageContent>
    </>
  );
};

export default Drive;
