import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import AvatarComponent from '~/components/Avatar';
import colors from '~/styles/colors';

const disabledStyle = css`
  pointer-events: none;
  opacity: 0.2;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  ${props => props.disabled && disabledStyle}
`;

export const MenuItem = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${colors.white};
  font-size: 15px;
  font-weight: 400;
  height: 50px;
  min-height: 40px;
  position: relative;
  &.active {
    background-image: linear-gradient(
      90deg,
      ${colors.primary} 0%,
      ${colors.primary} 30%,
      ${colors.secondary} 90%
    );
    > svg.menu-info {
      color: ${colors.white};
    }
  }
  svg {
    color: ${colors.white};
    font-size: 18px;
    margin: 0 12px;
  }
  &:hover {
    text-decoration: none;
    color: ${colors.white};
  }
  > span.menu-badge {
    position: absolute;
    right: 5px;
    padding: 3px;
    text-align: center;
    min-width: 20px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    background-image: linear-gradient(
      90deg,
      ${colors.primary} 0%,
      ${colors.primary} 30%,
      ${colors.secondary} 90%
    );
    color: ${colors.white};
  }
  > svg.menu-info {
    position: absolute;
    right: 5px;
    padding: 3px;
    min-width: 20px;
    font-size: 28px;
    color: ${colors.secondary};
  }
`;

export const SyncButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.dark3};
  font-size: 12px;
  background-color: transparent;
  border: 0;
  padding: 5px 0;
  svg {
    color: ${colors.dark2};
    font-size: 12px;
    margin-right: 10px;
  }
`;

export const ProfileButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  position: relative;
  border: 0;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  width: 100%;
  white-space: nowrap;
  > * {
    z-index: 1;
  }
  &:before {
    z-index: 0;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: -30px;
    background-image: linear-gradient(
      90deg,
      ${colors.accent2} 0%,
      ${colors.accent1} 120%
    );
  }
  img {
    margin: 0 10px;
  }
  ${props => props.disabled && disabledStyle}
  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 186px;
    text-align: left;
  }
`;

export const ProfilePopupBox = styled.div`
  background-color: ${colors.white};
  border-radius: 4px;
  width: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > button {
    background-color: transparent;
    width: 100%;
    padding: 10px 20px;
    border: 0;
    border-bottom: 1px solid ${colors.primary};
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: ${colors.dark4};
    &:hover {
      background-color: ${colors.primary}20;
    }
    &:last-child {
      border-bottom: 0;
    }
    svg {
      margin-right: 5px;
    }
  }
`;

export const Avatar = styled(AvatarComponent).attrs({
  width: 30,
  height: 30,
})``;
