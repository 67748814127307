import React, { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FaRegEnvelope } from 'react-icons/fa';
import { AiFillLock } from 'react-icons/ai';

import { signInRequest } from '~/store/modules/auth/actions';
import { getValidationErrors } from '~/utils';

import {
  Container,
  LoginBoxTitle,
  ButtonLogin,
  LinkForgot,
  LinkRegister,
  RegisterBox,
  // form login
  Unform,
  UnformInput,
} from './styles';

const initialData = {
  email: '',
  password: '',
  // email: 'davilson.student@foxkey.com',
  // password: 'secret',
};

export default function SignIn() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t('auth.sign-in.field.email.message.email'))
      .required(t('auth.sign-in.field.email.message.required')),
    password: Yup.string().required(
      t('auth.sign-in.field.password.message.required')
    ),
  });

  const handleSubmit = useCallback(async data => {
    try {
      formRef.current.setErrors({});
      await schema.validate(data, { abortEarly: false });
      dispatch(signInRequest(data.email, data.password));
    } catch (err) {
      formRef.current.setErrors(getValidationErrors(err));
    }
  }, []);

  return (
    <Container>
      <LoginBoxTitle>{t('auth.sign-in.title')}</LoginBoxTitle>
      <Unform onSubmit={handleSubmit} initialData={initialData} ref={formRef}>
        <UnformInput
          icon={FaRegEnvelope}
          type="text"
          label={t('auth.sign-in.field.email.label')}
          name="email"
          style={{ marginBottom: 20 }}
        />
        <UnformInput
          icon={AiFillLock}
          type="password"
          label={t('auth.sign-in.field.password.label')}
          name="password"
          style={{ marginBottom: 10 }}
        />
        <LinkForgot to="/forgot">
          {t('auth.sign-in.forgot-password')}
        </LinkForgot>
        <ButtonLogin type="submit" title={t('auth.sign-in.login')} gradient />
        <RegisterBox>
          {t('auth.sign-in.not-registered-yet')}
          <LinkRegister to="/signUp">{t('auth.sign-in.register')}</LinkRegister>
        </RegisterBox>
      </Unform>
    </Container>
  );
}
