import styled from 'styled-components';
import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const NewSubject = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${colors.primary};
  border-radius: 10px;
  padding: 0;
  margin-left: 5px;
  width: 250px;
  height: 250px;
  font-size: 25px;
  background-color: ${colors.white};
  color: ${colors.primary};
`;

export const HeaderNewSubject = styled(ButtonComponent).attrs({
  color: colors.secondary,
  style: {
    height: 35,
    fontSize: 14,
    marginLeft: 10,
  },
})``;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const SubjectList = styled.div`
  display: flex;
  flex-flow: wrap;
  padding: 0 5px;
  justify-content: ${props => props.justifyContent};
`;

export const SubjectBoxButton = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  gradient: true,
  type: 'button',
  style: {
    marginTop: 10,
    height: 40,
    width: '100%',
  },
})``;
