import React from 'react';
import Tour from 'reactour';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import colors from '~/styles/colors';

function TourComponent({ onClose, ...rest }) {
  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);
  return (
    <Tour
      {...rest}
      className="react-tour"
      rounded={5}
      accentColor={colors.primary}
      onAfterOpen={() => {
        disableBody();
      }}
      onBeforeClose={() => {
        onClose();
        enableBody();
      }}
      maskClassName="react-tour-mask"
    />
  );
}

TourComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TourComponent;
