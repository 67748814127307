import styled, { css } from 'styled-components';
import { shade } from 'polished';
import colors from '~/styles/colors';

const getBackground = props =>
  props.gradient && !props.bordered
    ? css`
        background-image: linear-gradient(
          90deg,
          ${props.color2} 0%,
          ${props.color} 100%
        );
        &:hover {
          background-image: linear-gradient(
            90deg,
            ${shade(0.2, props.color2)} 0%,
            ${shade(0.2, props.color)} 100%
          );
        }
      `
    : css`
        background: ${props.bordered ? colors.white : props.color};
        &:hover {
          background: ${props.bordered
            ? colors.white
            : shade(0.2, props.color)};
        }
      `;

export const Container = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  padding: 0 10px;
  border-radius: 10px;
  ${props => getBackground(props)};
  border: ${props => (props.bordered ? 2 : 0)}px solid ${props => props.color};
  color: ${props => (props.bordered ? props.color : colors.white)};
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  .icon {
    margin-right: 5px;
  }
  span {
    white-space: nowrap;
  }
  .badge {
    color: ${colors.secondary};
    background-color: ${colors.accent1};
    height: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    padding: 0 6px;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
    border-radius: 5px;
    margin-left: 5px;
  }
`;
