import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaFilePdf } from 'react-icons/fa';
import PropTypes from 'prop-types';
import BlankContent from '~/components/BlankContent';

import colors from '~/styles/colors';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import ClassItem from './components/ClassItem';
import ModalExport from './components/ModalExport';

import { studyContentRequest } from '~/store/modules/studyContent/actions';

import { Content, HeaderButton, ClassList } from './styles';

const StudyContent = ({ match }) => {
  const dispatch = useDispatch();
  const { testContent } = useSelector(state => state.studyContent);
  const { testId } = match.params;
  const [modalExport, setModalExport] = useState(false);

  useEffect(() => {
    dispatch(studyContentRequest(testId));
  }, []);

  const renderHeader = () => {
    const breadcrumb = ['Conteúdo para a prova'];
    return (
      <PageHeader
        breadcrumb={breadcrumb}
        actionElements={() =>
          !!testContent && testContent.classes.length > 0 ? (
            <HeaderButton
              type="button"
              title="Gerar PDF"
              icon={FaFilePdf}
              onClick={() => {
                setModalExport(true);
              }}
              color={colors.secondary}
            />
          ) : null
        }
      />
    );
  };

  const renderClasses = () => (
    <ClassList>
      {testContent.classes.map(item => (
        <ClassItem key={item.id} classItem={item} />
      ))}
    </ClassList>
  );

  const renderContent = () => (
    <Content>
      {!!testContent && renderClasses()}
      {!!testContent && testContent.classes.length === 0 && (
        <BlankContent>Nenhum conteúdo vinculado para esta prova.</BlankContent>
      )}
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {modalExport && (
          <ModalExport
            onClose={() => {
              setModalExport(false);
            }}
            testToExport={testId}
          />
        )}
      </PageContent>
    </>
  );
};

StudyContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      testId: PropTypes.string,
    }),
  }).isRequired,
};

export default StudyContent;
