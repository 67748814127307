export default {
  FRIEND_REQUEST: '@friend/FRIEND_REQUEST',
  FRIEND_REQUEST_SUCCESS: '@friend/FRIEND_REQUEST_SUCCESS',
  DELETE_FRIEND_REQUEST: '@friend/DELETE_FRIEND_REQUEST',
  FRIEND_SEARCH_REQUEST: '@friend/FRIEND_SEARCH_REQUEST',
  FRIEND_SEARCH_REQUEST_SUCCESS: '@friend/FRIEND_SEARCH_REQUEST_SUCCESS',
  FRIEND_INVITE_FRIEND_REQUEST: '@friend/FRIEND_INVITE_FRIEND_REQUEST',
  FRIEND_INVITES_REQUEST: '@friend/FRIEND_INVITES_REQUEST',
  FRIEND_INVITES_REQUEST_SUCCESS: '@friend/FRIEND_INVITES_REQUEST_SUCCESS',
  FRIEND_INVITES_REQUEST_ACCEPT: '@friend/FRIEND_INVITES_REQUEST_ACCEPT',
  FRIEND_SET_SEARCH: '@friend/FRIEND_SET_SEARCH',
};
