/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  periods: [],
  periodModalCreate: false,
  periodEdit: null,
};

export default function period(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.PERIOD_REQUEST_SUCCESS: {
        draft.periods = action.payload.periods;
        break;
      }
      case types.PERIOD_SET_EDIT: {
        draft.periodEdit = action.payload.periodEdit;
        break;
      }
      case types.PERIOD_SET_MODAL_CREATE: {
        draft.periodModalCreate = action.payload.periodModalCreate;
        break;
      }
      default:
    }
  });
}
