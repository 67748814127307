// import React from 'react';
import Proptypes from 'prop-types';
import { useSelector } from 'react-redux';

function HasPermission({ children }) {
  const { expiredSubscription } = useSelector(state => state.myPlan);
  if (expiredSubscription) return null;
  return children;
}

HasPermission.propTypes = {
  children: Proptypes.element.isRequired,
};

export default HasPermission;
