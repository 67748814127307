/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  dashboard: {
    sharedWithMe: 0,
    invites: 0,
  },
};

export default function dashboard(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.DASHBOARD_REQUEST_SUCCESS: {
        draft.dashboard = {
          ...draft.dashboard,
          ...action.payload.dashboard
        };
        break;
      }
      default:
    }
  });
}
