import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import SearchBox from '~/components/SearchBox';
import logoH from '~/assets/images/logo-h.svg';
import { check } from '~/store/modules/auth/actions';
import { searchSetFilter, searchRequest } from '~/store/modules/search/actions';

import MenuLeft from '~/components/MenuLeft';
import ChatBottom from '~/components/ChatBottom';
import { Wrapper, LeftPanel, ContentPanel, BarLogo } from './styles';

export default function DefaultLayout({ children }) {
  const dispatch = useDispatch();
  const { filter } = useSelector(state => state.search);
  const [refTimeToSearch, setRefTimeToSearch] = useState(null);

  useEffect(() => {
    dispatch(check());
  }, []);

  const searchFilter = () => {
    dispatch(searchRequest());
  };

  return (
    <Wrapper>
      <LeftPanel>
        <BarLogo>
          <img src={logoH} alt="Hippocampus" />
        </BarLogo>
        <SearchBox
          className="left-menu-indexed-search"
          placeholder="Busca indexada..."
          value={filter}
          onChange={val => {
            dispatch(searchSetFilter(val));
            if (refTimeToSearch) {
              clearTimeout(refTimeToSearch);
              setRefTimeToSearch(null);
            }
            const timeOut = setTimeout(searchFilter, 1000);
            setRefTimeToSearch(timeOut);
          }}
        />
        <MenuLeft disabled={!!filter} />
      </LeftPanel>
      <ContentPanel>
        {children}
        <ChatBottom />
      </ContentPanel>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: Proptypes.element.isRequired,
};
