import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';
import i18n from '~/i18n';

import types from './types';

import api from '~/services/api';
import {
  certificateRequestSuccess,
  certificateSetEdit,
  certificateRequest,
  certificateSetModalCreate,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* request() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'certificates');
    const certificates = response.data.map(item => ({
      ...item,
      initial_date: moment(item.initial_date).format('DD/MM/YYYY'),
      final_date: moment(item.final_date).format('DD/MM/YYYY'),
    }));
    // Stop loading
    yield put(stopLoader());

    yield put(certificateRequestSuccess(certificates));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.certificate.request.error'));
  }
}

export function* update({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...certificate } = payload.certificate;

    yield call(api.put, `certificates/${id}`, {
      ...certificate,
      initial_date: moment(certificate.initial_date, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      final_date: moment(certificate.final_date, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
    });
    // Stop loading
    yield put(stopLoader());

    yield put(certificateSetEdit(null));
    yield put(certificateRequest());

    toast.success(i18n.t('store.certificate.update.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.certificate.update.error'));
  }
}

export function* create({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { certificate } = payload;

    yield call(api.post, 'certificates', {
      ...certificate,
      initial_date: moment(certificate.initial_date, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      final_date: moment(certificate.final_date, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
    });
    // Stop loading
    yield put(stopLoader());

    yield put(certificateSetModalCreate(false));
    yield put(certificateRequest());

    toast.success(i18n.t('store.certificate.create.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.certificate.create.error'));
  }
}

export function* remove({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { certificateId } = payload;

    yield call(api.delete, `certificates/${certificateId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(certificateRequest());
    toast.success(i18n.t('store.certificate.remove.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.certificate.remove.error'));
  }
}

export default all([
  takeLatest(types.CERTIFICATE_REQUEST, request),
  takeLatest(types.UPDATE_CERTIFICATE_REQUEST, update),
  takeLatest(types.CREATE_CERTIFICATE_REQUEST, create),
  takeLatest(types.DELETE_CERTIFICATE_REQUEST, remove),
]);
