import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import {
  FaFilePdf,
  FaFileWord,
  FaFileAlt,
  FaFileCode,
  FaFileExcel,
  FaFileVideo,
  FaFileImage,
  FaFile,
  FaCloudDownloadAlt,
  FaTrash,
  FaPen
} from 'react-icons/fa';

import { Container } from './styles';

function FileItem({ itemFile, onDownload, onDelete, onEdit }) {
  const { t } = useTranslation();

  const getIconByFileType = () => {
    const fileName = itemFile.file_url.split('/').pop();
    const extFile = fileName
      .split('.')
      .pop()
      .toUpperCase();
    switch (extFile) {
      case 'PDF':
        return <FaFilePdf />;
      case 'DOCX':
        return <FaFileWord />;
      case 'TXT':
        return <FaFileAlt />;
      case 'DOC':
        return <FaFileWord />;
      case 'HTML':
        return <FaFileCode />;
      case 'XLSX':
        return <FaFileExcel />;
      case 'MP4':
        return <FaFileVideo />;
      case 'WEBM':
        return <FaFileVideo />;
      case 'JPG':
        return <FaFileImage />;
      case 'JPEG':
        return <FaFileImage />;
      case 'PNG':
        return <FaFileImage />;
      case 'BMP':
        return <FaFileImage />;
      case 'SVG':
        return <FaFileImage />;
      default:
        return <FaFile />;
    }
  };

  return (
    <Container>
      <button
        type="button"
        className="file-box-download"
        onClick={() => {
          onDownload();
        }}
        data-tip={t('components.card-box.download')}
      >
        <FaCloudDownloadAlt />
      </button>
      <button
        type="button"
        className="file-box-edit"
        onClick={() => {
          onEdit();
        }}
        data-tip={t('components.card-box.edit')}
      >
        <FaPen />
      </button>
      <button
        type="button"
        className="file-box-delete"
        onClick={() => {
          onDelete();
        }}
        data-tip={t('components.card-box.delete')}
      >
        <FaTrash />
      </button>
      <div className="file-box-content">{getIconByFileType()}</div>
      <div className="file-box-footer">{itemFile.title}</div>
      <ReactTooltip />
    </Container>
  );
}

FileItem.propTypes = {
  itemFile: PropTypes.shape().isRequired,
  onDownload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default FileItem;
