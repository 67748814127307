import types from './types';

export function sharedRequest() {
  return {
    type: types.SHARED_REQUEST,
  };
}

export function sharedRequestSuccess(shareds) {
  return {
    type: types.SHARED_REQUEST_SUCCESS,
    payload: { shareds },
  };
}

export function sharedCreate(shared) {
  return {
    type: types.SHARED_CREATE,
    payload: { shared },
  };
}

export function sharedUpdate(shared) {
  return {
    type: types.SHARED_UPDATE,
    payload: { shared },
  };
}

export function sharedDelete(shared) {
  return {
    type: types.SHARED_DELETE,
    payload: { shared },
  };
}
