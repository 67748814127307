import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import {
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
  AiOutlineShopping,
  AiOutlineCreditCard,
} from 'react-icons/ai';
import { FaSignature } from 'react-icons/fa';
import { BiAt } from 'react-icons/bi';
import { CgPin } from 'react-icons/cg';
import { BsCreditCard, BsFillLockFill, BsCalendar } from 'react-icons/bs';
import * as Yup from 'yup';

import brandConfig from './brandsConfig';
import {
  myPlanSetState,
  checkout as planCheckout,
} from '~/store/modules/myPlan/actions';
import Modal from '~/components/Modal';
import Table from '~/components/Table';
import { numberToCurrency, getValidationErrors } from '~/utils';
import countries from '~/utils/countries';

import {
  Header,
  HeaderStep,
  Step1,
  Step2,
  Step3,
  Step4,
  PlanPriceBox,
  PlanPrice,
  Wrap,
  FooterButton,
  BrandList,
  BrandItem,
  // form
  Unform,
  UnformInput,
  UnformInputMask,
  UnformSelect,
} from './styles';

export default function ModalCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { plans, myPlans } = useSelector(state => state.myPlan);
  const { profile } = useSelector(state => state.user);
  const [stepNumber, setStepNumber] = useState(1);
  const [cardMask, setCardMask] = useState(brandConfig[0].mask);
  const formCreditCardRef = useRef(null);
  const formUserRef = useRef(null);
  const numberPattern = /\D/g;

  const [checkout, setCheckout] = useState({
    PlanId: plans[0].id,
    Customer: {
      Name: profile.name,
      Email: profile.email,
      ...(profile.language === 'pt'
        ? {
            Identity: '',
            IdentityType: 'CPF',
          }
        : {}),
      Birthdate: '',
      Address: {
        Street: '',
        Number: '',
        Complement: '',
        ZipCode: '',
        City: '',
        State: '',
        Country: 'BRA',
      },
    },
    Payment: {
      Type: 'CreditCard',
      Amount: plans[0].price,
      Installments: 1,
      CreditCard: {
        CardNumber: '',
        Holder: '',
        ExpirationDate: '',
        SecurityCode: '',
        Brand: brandConfig[0].brand,
      },
    },
  });

  useEffect(() => {}, []);

  const schemaCreditCard = Yup.object().shape({
    CardNumber: Yup.string().required(
      t('my-plan.modal-renew.step-2.field.cardNumber.required')
    ),
    Holder: Yup.string().required(
      t('my-plan.modal-renew.step-2.field.holder.required')
    ),
    ExpirationDate: Yup.string().required(
      t('my-plan.modal-renew.step-2.field.expirationDate.required')
    ),
    SecurityCode: Yup.string().required(
      t('my-plan.modal-renew.step-2.field.securityCode.required')
    ),
  });

  const schemaUser = Yup.object().shape({
    Name: Yup.string().required(
      t('my-plan.modal-renew.step-3.field.name.required')
    ),
    ...(profile.language === 'pt'
      ? {
          Identity: Yup.string().required(
            t('my-plan.modal-renew.step-3.field.identity.required')
          ),
        }
      : {}),
    Email: Yup.string().required(
      t('my-plan.modal-renew.step-3.field.email.required')
    ),
    Birthdate: Yup.string().required(
      t('my-plan.modal-renew.step-3.field.birthdate.required')
    ),
    Address: Yup.object({
      Street: Yup.string().required(
        t('my-plan.modal-renew.step-3.field.address.street.required')
      ),
      Number: Yup.string().required(
        t('my-plan.modal-renew.step-3.field.address.number.required')
      ),
      Complement: Yup.string(),
      ZipCode: Yup.string().required(
        t('my-plan.modal-renew.step-3.field.address.zipCode.required')
      ),
      City: Yup.string().required(
        t('my-plan.modal-renew.step-3.field.address.city.required')
      ),
      State: Yup.string(),
      Country: Yup.string().required(
        t('my-plan.modal-renew.step-3.field.address.country.required')
      ),
    }),
  });

  const renderHeaderSteps = () => {
    const stepList = [
      { label: t('my-plan.modal-renew.step-1.title'), step: 1 },
      { label: t('my-plan.modal-renew.step-2.title'), step: 2 },
      { label: t('my-plan.modal-renew.step-3.title'), step: 3 },
      { label: t('my-plan.modal-renew.step-4.title'), step: 4 },
    ];
    return (
      <Header>
        <div className="header-divider" />
        {stepList.map(i => (
          <HeaderStep key={i.step} active={stepNumber === i.step}>
            <span>{i.step}</span>
            <small>{i.label}</small>
          </HeaderStep>
        ))}
      </Header>
    );
  };

  const renderStep1 = () => (
    <Step1>
      <div className="step1-content">
        {plans.map(i => (
          <PlanPriceBox key={i.id} active={checkout.PlanId === i.id}>
            <h2>{t(`my-plan.plan-name.${i.language_key}`)}</h2>
            <PlanPrice
              active={checkout.PlanId === i.id}
              onClick={() => {
                const newCheckout = { ...checkout };
                newCheckout.PlanId = i.id;
                newCheckout.Payment.Amount = i.price;
                setCheckout(newCheckout);
              }}
            >
              <span>{t(`my-plan.item.currency.${i.currency}`)}</span>
              <h2>{numberToCurrency(i.label_price / 100)}</h2>
              <small>{t('my-plan.item.payment')}</small>
            </PlanPrice>
          </PlanPriceBox>
        ))}
      </div>
      <div className="step1-footer">
        <FooterButton
          id="certificate-new"
          type="button"
          title={t('my-plan.modal-renew.step-1.footer.next')}
          icon={AiOutlineArrowRight}
          onClick={() => setStepNumber(2)}
        />
      </div>
    </Step1>
  );

  const renderStep2 = () => (
    <Step2>
      <div className="step2-content">
        <BrandList>
          {brandConfig.map(i => (
            <BrandItem
              image={i.image}
              active={checkout.Payment.CreditCard.Brand === i.brand}
              onClick={() => {
                const newCheckout = { ...checkout };
                checkout.Payment.CreditCard.Brand = i.brand;
                setCheckout(newCheckout);
                setCardMask(i.mask);
              }}
            />
          ))}
        </BrandList>
        <Unform
          onSubmit={async data => {
            try {
              formCreditCardRef.current.setErrors({});
              await schemaCreditCard.validate(data, { abortEarly: false });
              const newCheckout = { ...checkout };
              newCheckout.Payment.CreditCard = {
                ...checkout.Payment.CreditCard,
                CardNumber: data.CardNumber.replace(numberPattern, ''),
                Holder: data.Holder,
                ExpirationDate: data.ExpirationDate,
                SecurityCode: data.SecurityCode.replace(numberPattern, ''),
              };
              setCheckout(newCheckout);
              setStepNumber(3);
            } catch (err) {
              formCreditCardRef.current.setErrors(getValidationErrors(err));
            }
          }}
          initialData={checkout.Payment.CreditCard}
          ref={formCreditCardRef}
        >
          <div className="form-row" style={{ marginBottom: 15 }}>
            <UnformInputMask
              icon={BsCreditCard}
              type="text"
              label={t('my-plan.modal-renew.step-2.field.cardNumber.label')}
              name="CardNumber"
              mask={cardMask}
              style={{
                marginRight: 20,
              }}
            />
            <UnformInputMask
              icon={BsFillLockFill}
              type="text"
              label={t('my-plan.modal-renew.step-2.field.securityCode.label')}
              name="SecurityCode"
              mask="999"
              style={{
                marginRight: 20,
                maxWidth: 150,
              }}
            />
            <UnformInputMask
              icon={BsCalendar}
              type="text"
              label={t('my-plan.modal-renew.step-2.field.expirationDate.label')}
              name="ExpirationDate"
              mask="99/9999"
              style={{
                maxWidth: 150,
              }}
            />
          </div>
          <div className="form-row">
            <UnformInput
              icon={FaSignature}
              type="text"
              label={t('my-plan.modal-renew.step-2.field.holder.label')}
              name="Holder"
              style={{ marginBottom: 20 }}
              maxLength="25"
            />
          </div>
        </Unform>
      </div>
      <div className="step2-footer">
        <FooterButton
          id="certificate-new"
          type="button"
          title={t('my-plan.modal-renew.step-2.footer.previous')}
          icon={AiOutlineArrowLeft}
          onClick={() => setStepNumber(1)}
        />
        <FooterButton
          id="certificate-new"
          type="button"
          title={t('my-plan.modal-renew.step-2.footer.next')}
          icon={AiOutlineArrowRight}
          onClick={() => formCreditCardRef.current.submitForm()}
        />
      </div>
    </Step2>
  );

  const renderStep3 = () => (
    <Step3>
      <div className="step3-content">
        <Unform
          onSubmit={async data => {
            try {
              formUserRef.current.setErrors({});
              await schemaUser.validate(data, { abortEarly: false });
              const newCheckout = { ...checkout };
              newCheckout.Customer = {
                Name: data.Name,
                ...(profile.language === 'pt'
                  ? {
                      Identity: data.Identity.replace(numberPattern, ''),
                      IdentityType: checkout.Customer.IdentityType,
                    }
                  : {}),
                Email: data.Email,
                Birthdate: data.Birthdate,
                Address: {
                  Street: data.Address.Street,
                  Number: data.Address.Number.replace(numberPattern, ''),
                  Complement: data.Address.Complement,
                  ZipCode: data.Address.ZipCode.replace(numberPattern, ''),
                  City: data.Address.City,
                  State: data.Address.State || '',
                  Country: data.Address.Country,
                },
              };
              setCheckout(newCheckout);
              setStepNumber(4);
            } catch (err) {
              formUserRef.current.setErrors(getValidationErrors(err));
            }
          }}
          initialData={checkout.Customer}
          ref={formUserRef}
        >
          <div className="form-row" style={{ marginBottom: 15 }}>
            <UnformInput
              icon={FaSignature}
              type="text"
              label={t('my-plan.modal-renew.step-3.field.name.label')}
              name="Name"
              style={{ marginRight: 20 }}
              maxLength="255"
            />
            <UnformInputMask
              icon={BsCalendar}
              type="text"
              label={t('my-plan.modal-renew.step-3.field.birthdate.label')}
              name="Birthdate"
              mask="99/99/9999"
              style={{
                maxWidth: 200,
              }}
            />
          </div>
          <div className="form-row" style={{ marginBottom: 15 }}>
            <UnformInput
              icon={BiAt}
              type="text"
              label={t('my-plan.modal-renew.step-3.field.email.label')}
              name="Email"
              style={{}}
              maxLength="255"
            />
            {!!checkout.Customer.IdentityType && (
              <UnformInputMask
                icon={AiOutlineCreditCard}
                type="text"
                label={t('my-plan.modal-renew.step-3.field.identity.label')}
                name="Identity"
                mask="999.999.999-99"
                style={{
                  marginLeft: 20,
                  maxWidth: 200,
                }}
              />
            )}
          </div>
          <div className="form-row" style={{ marginBottom: 15 }}>
            <UnformInput
              icon={CgPin}
              type="text"
              label={t('my-plan.modal-renew.step-3.field.address.street.label')}
              name="Address.Street"
              style={{ marginRight: 20 }}
              maxLength="255"
            />
            <UnformInput
              icon={CgPin}
              type="text"
              label={t('my-plan.modal-renew.step-3.field.address.number.label')}
              name="Address.Number"
              style={{ marginRight: 20, maxWidth: 150 }}
              maxLength="15"
            />
            <UnformInput
              icon={CgPin}
              type="text"
              label={t(
                'my-plan.modal-renew.step-3.field.address.complement.label'
              )}
              name="Address.Complement"
              style={{ maxWidth: 200 }}
              maxLength="50"
            />
          </div>
          <div className="form-row">
            <UnformInput
              icon={CgPin}
              type="text"
              label={t(
                'my-plan.modal-renew.step-3.field.address.zipCode.label'
              )}
              name="Address.ZipCode"
              style={{ marginRight: 20, maxWidth: 120 }}
              maxLength="9"
            />
            <UnformInput
              icon={CgPin}
              type="text"
              label={t('my-plan.modal-renew.step-3.field.address.city.label')}
              name="Address.City"
              style={{ marginRight: 20 }}
              maxLength="50"
            />
            {!!checkout.Customer.IdentityType && (
              <UnformInput
                icon={CgPin}
                type="text"
                label={t(
                  'my-plan.modal-renew.step-3.field.address.state.label'
                )}
                name="Address.State"
                style={{ marginRight: 20, maxWidth: 150 }}
                maxLength="2"
              />
            )}
            <UnformSelect
              icon={CgPin}
              label={t(
                'my-plan.modal-renew.step-3.field.address.country.label'
              )}
              name="Address.Country"
              style={{ maxWidth: 200 }}
              options={countries.map(i => ({
                label: i.label,
                value: i.alpha3,
              }))}
              menuPlacement="top"
            />
          </div>
        </Unform>
      </div>
      <div className="step3-footer">
        <FooterButton
          id="certificate-new"
          type="button"
          title={t('my-plan.modal-renew.step-3.footer.previous')}
          icon={AiOutlineArrowLeft}
          onClick={() => setStepNumber(2)}
        />
        <FooterButton
          id="certificate-new"
          type="button"
          title={t('my-plan.modal-renew.step-3.footer.next')}
          icon={AiOutlineArrowRight}
          onClick={() => formUserRef.current.submitForm()}
        />
      </div>
    </Step3>
  );

  const renderStep4 = () => {
    const findPlan = plans.find(i => i.id === checkout.PlanId);
    let initialDate = moment();
    const dueLastPlan = moment(myPlans.length ? myPlans[0].final_date : null);
    if (dueLastPlan.isAfter(initialDate)) initialDate = dueLastPlan;
    const newDueDate = initialDate
      .add(findPlan.days, 'days')
      .format('DD/MM/YYYY');
    const handleSubmitCheckout = () => {
      const newCheckout = { ...checkout };
      newCheckout.Customer.Birthdate = moment(
        newCheckout.Customer.Birthdate,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
      confirmAlert({
        title: t('my-plan.modal-renew.step-4.confirm.title'),
        message: t('my-plan.modal-renew.step-4.confirm.message', {
          plan: t(`my-plan.plan-name.${findPlan.language_key}`),
        }),
        buttons: [
          {
            label: t('my-plan.modal-renew.step-4.confirm.cancel'),
          },
          {
            label: t('my-plan.modal-renew.step-4.confirm.confirm'),
            onClick: () => {
              dispatch(planCheckout(newCheckout));
            },
          },
        ],
      });
    };
    return (
      <Step4>
        <div className="step4-content">
          <Table>
            <tbody>
              <tr>
                <td
                  width="1%"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                >
                  {t('my-plan.modal-renew.step-4.plan')}
                </td>
                <td style={{ cursor: 'pointer' }}>
                  {t(`my-plan.plan-name.${findPlan.language_key}`)}
                </td>
              </tr>
              <tr>
                <td
                  width="1%"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                >
                  {t('my-plan.modal-renew.step-4.validUntil')}
                </td>
                <td style={{ cursor: 'pointer' }}>{newDueDate}</td>
              </tr>
              <tr>
                <td
                  width="1%"
                  style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
                >
                  {t('my-plan.modal-renew.step-4.totalPayable')}
                </td>
                <td style={{ cursor: 'pointer' }}>
                  {`R$ ${numberToCurrency(checkout.Payment.Amount / 100)}`}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="step4-footer">
          <FooterButton
            id="certificate-new"
            type="button"
            title={t('my-plan.modal-renew.step-4.footer.previous')}
            icon={AiOutlineArrowLeft}
            onClick={() => setStepNumber(3)}
          />
          <FooterButton
            id="certificate-new"
            type="button"
            title={t('my-plan.modal-renew.step-4.footer.confirm')}
            icon={AiOutlineShopping}
            onClick={() => handleSubmitCheckout()}
          />
        </div>
      </Step4>
    );
  };

  return (
    <Modal
      visible
      width="800px"
      height="auto"
      effect="fadeInUp"
      title={t('my-plan.modal-renew.title')}
      onClose={() => {
        dispatch(
          myPlanSetState({
            plans: [],
          })
        );
      }}
    >
      <Wrap>
        {renderHeaderSteps()}
        {stepNumber === 1 && renderStep1()}
        {stepNumber === 2 && renderStep2()}
        {stepNumber === 3 && renderStep3()}
        {stepNumber === 4 && renderStep4()}
      </Wrap>
    </Modal>
  );
}
