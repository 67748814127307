/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

export const INITIAL_STATE = {
  showedPeriods: false,
  showedSubjects: false,
  showedClasses: false,
  showedClassDetail: false,
  showedStudyForTest: false,
  showedFriends: false,
  showedAgenda: false,
  showedCertificates: false,
  showedDrive: false,
  showedSharedWithMe: false,
  showedSettings: false,
  showedHelp: false,
};

export default function reducer(baseState = INITIAL_STATE, action) {
  return produce(baseState, draftState => {
    switch (action.type) {
      case types.TOUR_SET_STATE: {
        Object.keys(action.payload.tourState).forEach(objectKey => {
          draftState[objectKey] = action.payload.tourState[objectKey];
        });
        break;
      }
      default:
    }
  });
}
