import styled from 'styled-components';
import { shade } from 'polished';
import AvatarComponent from '~/components/Avatar';
import colors from '~/styles/colors';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.width}px;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  background-color: ${colors.accent3};
  border: 1px solid ${colors.accent3};
  border-radius: 10px;
  margin: 5px;
  padding: 15px;
  overflow: hidden;
  .card-box-title {
    max-width: ${props => props.width - 30}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.white};
  }
  .card-box-subtitle {
    max-width: ${props => props.width - 30}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    color: ${colors.white};
    margin-top: 5px;
    b {
      font-weight: bold;
    }
  }
`;

export const Image = styled(AvatarComponent)`
  width: 100px;
  height: 100px;
  border-radius: 10px;
`;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding-top: 10px;
`;

const CardBoxButton = styled.button.attrs({
  type: 'button',
})`
  color: ${colors.white};
  font-size: 12px;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 6px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditButton = styled(CardBoxButton)`
  background-color: ${colors.primary};
  top: 15px;
  left: 15px;
  &:hover {
    background-color: ${shade(0.2, colors.primary)};
  }
`;

export const ShareButton = styled(CardBoxButton)`
  background-color: ${colors.secondary};
  top: 50px;
  left: 15px;
  &:hover {
    background-color: ${shade(0.2, colors.secondary)};
  }
`;

export const ExportPDF = styled(CardBoxButton)`
  background-color: ${colors.info};
  top: 85px;
  left: 15px;
  &:hover {
    background-color: ${shade(0.2, colors.info)};
  }
`;

export const DeleteButton = styled(CardBoxButton)`
  background-color: ${colors.danger};
  top: 15px;
  right: 15px;
  &:hover {
    background-color: ${shade(0.2, colors.danger)};
  }
`;

export const SharedBy = styled(CardBoxButton)`
  background-color: ${colors.success};
  top: 50px;
  right: 15px;
  &:hover {
    background-color: ${shade(0.2, colors.success)};
  }
`;
