import styled from 'styled-components';
import colors from '~/styles/colors';
import bgAuth from '~/assets/layout/bg-auth.jpg';
import logoAuth from '~/assets/layout/logo-auth.svg';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
  background: ${colors.primary};
  background-image: url(${bgAuth});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ScreenBoxLogo = styled.div`
  display: flex;
  background-image: url(${logoAuth});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 200px;
  height: 260px;
  margin-right: 100px;
`;

export const ScreenBoxPage = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
  max-height: 80%;
  background-color: transparent;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid ${colors.secondary};
  background: ${colors.primary}30;
`;

export const TranslateBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  > button {
    border: 0;
    background-color: transparent;
    border-radius: 5px;
    overflow: hidden;
    width: 45px;
    height: 30px;
    margin-left: 10px;
    > img {
      width: 45px;
      height: 30px;
      object-fit: cover;
      object-position: center;
    }
  }
`;
