import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import TextareaComponent from './components/Textarea';

import ModalComponent from '~/components/Modal';
import ButtonComponent from '~/components/Button';
import SearchBoxComponent from '~/components/SearchBox';
import colors from '~/styles/colors';

export const Modal = styled(ModalComponent)``;

export const SearchBox = styled(SearchBoxComponent)`
  min-height: 45px;
  padding-top: 0;
  .field-search {
    background-color: ${colors.primary};
    color: ${colors.white};
    &::placeholder {
      color: ${colors.white};
    }
  }
  .icon-search {
    color: ${colors.white};
  }
  .button-clear {
    border: 1px solid ${colors.white};
    color: ${colors.white};
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
  height: calc(100% - 40px);
  border-right: 2px solid ${colors.secondary};
  margin: 20px 0;
`;

export const ChatMessageBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0 10px;
  overflow-y: auto;
`;

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

export const MessagesChatBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 20px 20px 0 20px;
  overflow-y: scroll;
`;

export const SendMessage = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    marginTop: 10,
    minHeight: 32,
    maxHeight: 32,
    width: 100,
    alignSelf: 'flex-end',
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${colors.light1}90;
  margin: 0 25px 25px 25px;
  border-radius: 10px;
  border: 0;
`;

export const Textarea = styled(TextareaComponent)``;
