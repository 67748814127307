import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';

import ButtonComponent from '~/components/Button';
import colors from '~/styles/colors';

export const ButtonSave = styled(ButtonComponent).attrs({
  color: colors.secondary,
  style: {
    height: 35,
    fontSize: 14,
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
`;

export const UnformInput = styled(UnformInputComponent)``;
