import styled from 'styled-components';
import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.white};
  min-width: 800px;
`;

export const HeaderButton = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginLeft: 10,
  },
})``;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const CertificateList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

export const NewCertificate = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${colors.primary};
  border-radius: 10px;
  padding: 0;
  margin-left: 5px;
  width: 250px;
  height: 250px;
  font-size: 25px;
  background-color: ${colors.white};
  color: ${colors.primary};
`;
