import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import { startLoader, stopLoader } from '~/store/modules/loader/actions';
import types from './types';

import api from '~/services/api';
import history from '~/services/history';

import { signInSuccess, signFailure, setSigned } from './actions';
import { tourSetState } from '../tour/actions';

import { INITIAL_STATE as INITIAL_STATE_TOUR } from '../tour/reducer';

export function* signIn({ payload }) {
  try {
    // Start loading
    yield put(startLoader());
    const { email, password } = payload;
    const response = yield call(
      api.post,
      'sessions',
      { email, password },
      {
        headers: {
          is_mobile: false,
        },
      }
    );
    const { token, user, refreshToken } = response.data;
    // Stop loading
    yield put(stopLoader());

    yield put(signInSuccess(token, user, refreshToken));
    yield put(tourSetState(INITIAL_STATE_TOUR));
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api.defaults.headers.is_mobile = false;
    api.defaults.headers.refresh_token = refreshToken;
    toast.success(i18n.t('store.auth.sign-in.success', { name: user.name }));
    history.push('/');
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.auth.sign-in.error'));
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    // Start loading
    yield put(startLoader());
    const { user } = payload;

    yield call(api.post, 'users', user);

    // Stop loading
    yield put(stopLoader());

    toast.success(i18n.t('store.auth.sign-up.success'));
    history.push('/signIn');
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.auth.sign-up.error'));
    yield put(signFailure());
  }
}

export function* check() {
  const { auth } = yield select();
  try {
    const { data } = yield call(api.get, 'sessions');
    i18n.changeLanguage(data.language || 'en');
    yield put(signInSuccess(auth.token, data, auth.refreshToken));
  } catch (error) {
    yield put(setSigned(false));
    history.push('/signIn');
    // yield put(signOut());
  }
}

export function* setToken(/* { payload } */) {
  const { auth } = yield select();
  if (auth.token) {
    api.defaults.headers.Authorization = `Bearer ${auth.token}`;
    api.defaults.headers.is_mobile = false;
    api.defaults.headers.refresh_token = auth.refreshToken;
  }
  // if (!payload) return;

  // const { token, refreshToken } = auth.auth;

  // if (token) {
  //   api.defaults.headers.Authorization = `Bearer ${token}`;
  //   api.defaults.headers.is_mobile = false;
  //   api.defaults.headers.refresh_token = refreshToken;
  // }
}

export function signOutSession() {
  history.push('/signIn');
  // toast.success(i18n.t('store.auth.sign-out-session.success'));
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(types.SIGN_IN_REQUEST, signIn),
  takeLatest(types.SIGN_UP_REQUEST, signUp),
  takeLatest(types.SIGN_OUT, signOutSession),
  takeLatest(types.CHECK, check),
]);
