import styled from 'styled-components';
import { shade, lighten } from 'polished';

import colors from '~/styles/colors';

export default styled.table`
  table-layout: auto;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 3px;
  font-size: 14px;
  thead {
    tr {
      th {
        text-align: left;
        color: ${colors.white};
        font-size: 14px;
        font-weight: 400;
        padding: 4px 10px;
      }
      th[align='center'] {
        text-align: center;
      }
      th[align='left'] {
        text-align: left;
      }
      th[align='right'] {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      background-color: ${colors.accent3};
      transition: 0.3s;
      &:hover {
        &:not(.table-no-holver) {
          background-color: ${lighten(0.05, colors.accent3)};
        }
      }
      td {
        text-align: left;
        padding: 10px 10px;
        border-top: 1px solid ${colors.accent3};
        border-bottom: 1px solid ${colors.accent3};
        font-size: 14px;
        font-weight: 400;
        color: ${colors.white};
        &:last-child {
          border-right: 1px solid ${colors.accent3};
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &:first-child {
          border-left: 1px solid ${colors.accent3};
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        b {
          font-weight: 700;
        }
        div.table-action {
          display: flex;
          justify-content: flex-end;
          > button {
            color: ${colors.white};
            font-size: 12px;
            width: 25px;
            height: 25px;
            border-radius: 6px;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
            &:last-child {
              margin-right: 0px;
            }
          }
          > button.table-action-edit {
            background-color: ${colors.primary};
            &:hover {
              background-color: ${shade(0.2, colors.primary)};
            }
          }
          > button.table-action-delete {
            background-color: ${colors.danger};
            &:hover {
              background-color: ${shade(0.2, colors.danger)};
            }
          }
        }
      }
      td[align='center'] {
        text-align: center;
      }
      td[align='left'] {
        text-align: left;
      }
      td[align='right'] {
        text-align: right;
      }
      td.table-nowrap {
        white-space: nowrap;
      }
    }
  }
`;
