import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FaSignature, FaCalendar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import {
  periodSetEdit,
  updatePeriodRequest,
} from '~/store/modules/period/actions';
import { getValidationErrors } from '~/utils';

import Modal from '~/components/Modal';
import { Unform, UnformInput, UnformInputMask, PeriodSave } from './styles';

export default function ModalEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const { periodEdit } = useSelector(state => state.period);

  const schema = Yup.object().shape({
    description: Yup.string().required(
      t('period.modal-edit.field.description.message.required')
    ),
    initial_date: Yup.string().required(
      t('period.modal-edit.field.initial.message.required')
    ),
    final_date: Yup.string().required(
      t('period.modal-edit.field.final.message.required')
    ),
  });

  const handleSubmit = period => {
    dispatch(
      updatePeriodRequest({
        id: periodEdit.id,
        ...period,
      })
    );
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('period.modal-edit.title')}
      onClose={() => {
        dispatch(periodSetEdit(null));
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={periodEdit}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('period.modal-new.field.description.label')}
          name="description"
          autoFocus
          style={{ marginBottom: 20 }}
        />
        <UnformInputMask
          icon={FaCalendar}
          type="text"
          label={t('period.modal-new.field.initial.label')}
          name="initial_date"
          mask="99/99/9999"
          style={{ marginBottom: 20 }}
        />
        <UnformInputMask
          icon={FaCalendar}
          type="text"
          label={t('period.modal-new.field.final.label')}
          name="final_date"
          mask="99/99/9999"
        />
        <PeriodSave
          type="submit"
          title={t('period.modal-edit.save')}
          gradient
        />
      </Unform>
    </Modal>
  );
}
