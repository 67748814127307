export default {
  DRIVE_REQUEST: '@drive/DRIVE_REQUEST',
  DRIVE_REQUEST_SUCCESS: '@drive/DRIVE_REQUEST_SUCCESS',
  DRIVE_SET_MODAL_FOLDER_CREATE: '@drive/DRIVE_SET_MODAL_FOLDER_CREATE',
  DRIVE_CREATE_FOLDER_REQUEST: '@drive/DRIVE_CREATE_FOLDER_REQUEST',
  DRIVE_SET_MODAL_FILE_CREATE: '@drive/DRIVE_SET_MODAL_FILE_CREATE',
  DRIVE_CREATE_FILE_REQUEST: '@drive/DRIVE_CREATE_FILE_REQUEST',
  DRIVE_UPDATE_FOLDER_REQUEST: '@drive/DRIVE_UPDATE_FOLDER_REQUEST',
  DRIVE_FOLDER_SET_EDIT: '@drive/DRIVE_FOLDER_SET_EDIT',
  DRIVE_DELETE_REQUEST: '@drive/DRIVE_DELETE_REQUEST',
};
