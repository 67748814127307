import styled from 'styled-components';
import {
  // Accordion,
  // AccordionItem,
  // AccordionItemHeading as AccordionItemHeadingComponent,
  AccordionItemButton as AccordionItemButtonComponent,
  AccordionItemPanel as AccordionItemPanelComponent,
} from 'react-accessible-accordion';
import colors from '~/styles/colors';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const AccordionItemButton = styled(AccordionItemButtonComponent)`
  background-color: ${colors.accent3};
  color: ${colors.white};
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  font-size: 16px;
  font-weight: 300;
  &:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid ${colors.secondary};
    border-right: 2px solid ${colors.secondary};
    transform: rotate(-45deg);
  }
  &[aria-expanded='true']::before,
  &[aria-selected='true']::before {
    transform: rotate(45deg);
  }
`;

export const AccordionItemPanel = styled(AccordionItemPanelComponent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px;
  background-color: ${colors.white};
  &[hidden] {
    display: none;
  }
  > div.content-text {
    width: 100%;
    font-size: 15px;
    color: ${colors.primary};
    line-height: 20px;
    text-align: justify;
    font-weight: 400;
    a {
      color: ${colors.secondary};
    }
  }
`;
