import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';
import UnformSelectComponent from '~/components/Unform/Select';
import UnformCheckboxComponent from '~/components/Unform/Checkbox';

import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  max-height: 100%;
`;

export const RegisterBoxTitle = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  color: ${colors.white};
  letter-spacing: 1px;
  margin: 20px 0;
`;

export const ButtonRegister = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
})`
  margin: 15px 20px 30px 20px;
`;

export const LoginBox = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: ${colors.white};
  margin-bottom: 20px;
`;

export const LinkLogin = styled(RouterLink)`
  text-decoration: none;
  color: ${colors.secondary};
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  &:hover {
    text-decoration: none;
    color: ${colors.secondary};
  }
`;

export const FormScroll = styled.div`
  overflow-y: auto;
  height: 290px;
  padding: 0 15px;
`;

export const FormAccept = styled.div`
  padding: 0 15px;
  margin-top: 15px;
`;

// form register
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
`;

export const UnformInput = styled(UnformInputComponent)``;

export const UnformSelect = styled(UnformSelectComponent)``;

export const UnformCheckbox = styled(UnformCheckboxComponent)`
  .componenrt-accept a {
    text-decoration: none;
    color: ${colors.secondary};
    margin-left: 5px;
    font-size: 14px;
    font-weight: 300;
  }
`;
