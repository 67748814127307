import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import image from '~/assets/images/icons/licencing.svg';

import {
  Container,
  Image,
  CardBody,
  CertificateTitle,
  CertificateType,
} from './styles';

export default function SearchItem({ title, type, onClick }) {
  const getTypeLabel = () => {
    switch (type) {
      case 'periods':
        return 'Período';
      case 'subjects':
        return 'Matéria';
      case 'classes':
        return 'Aula';
      default:
        return '';
    }
  };

  return (
    <Container onClick={onClick}>
      <Image src={image} />
      <CardBody>
        <CertificateTitle>{title}</CertificateTitle>
        {!!type && (
          <CertificateType>
            <b>Tipo:</b> {getTypeLabel()}
          </CertificateType>
        )}
      </CardBody>
      <ReactTooltip />
    </Container>
  );
}

SearchItem.defaultProps = {
  title: '',
  type: '',
  onClick: () => {},
};

SearchItem.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};
