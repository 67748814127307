import styled from 'styled-components';
import ModalComponent from '~/components/Modal';
import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Modal = styled(ModalComponent)``;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: ${colors.white};
  overflow: hidden;
  .mindmap {
    width: 100%;
    height: calc(100% - 40px);
    max-height: calc(100% - 40px);
    .icon.iconfont.bm-show-menu {
      color: ${colors.primary};
    }
  }
`;

export const HeaderAction = styled(ButtonComponent).attrs({
  color: colors.secondary,
  style: {
    height: 30,
    fontSize: 14,
  },
})``;
