import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { FaCheckDouble, FaTrash } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import ModalAcceptSubject from './components/ModalAcceptSubject';
import ModalAcceptClass from './components/ModalAcceptClass';
import HasPermission from '~/components/HasPermission';

import {
  sharedRequest,
  sharedDelete,
  sharedUpdate,
} from '~/store/modules/shared/actions';

import { Content, ShareList, Table, Image } from './styles';

const SharedWithMe = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { shareds } = useSelector(state => state.shared);
  const [openModalAcceptSubject, setOpenModalAcceptSubject] = useState(false);
  const [subjectSharedItem, setSubjectSharedItem] = useState(null);
  const [openModalAcceptClass, setOpenModalAcceptClass] = useState(false);
  const [classSharedItem, setClassSharedItem] = useState(null);

  useEffect(() => {
    dispatch(sharedRequest());
  }, []);

  const handleDelete = shared => {
    const getMessage = () => {
      switch (shared.type) {
        case 'class':
          return t('shared-with-me.confirm-alert.delete.message.class');
        case 'subject':
          return t('shared-with-me.confirm-alert.delete.message.subject');
        case 'period':
          return t('shared-with-me.confirm-alert.delete.message.period');
        case 'drive':
          return t('shared-with-me.confirm-alert.delete.message.drive');
        default:
          return '';
      }
    };
    confirmAlert({
      title: t('shared-with-me.confirm-alert.delete.title'),
      message: getMessage(),
      buttons: [
        {
          label: t('shared-with-me.confirm-alert.delete.buttons.cancel'),
        },
        {
          label: t('shared-with-me.confirm-alert.delete.buttons.yes'),
          onClick: () => {
            dispatch(
              sharedDelete({
                id: shared.id,
                accepted: false,
              })
            );
          },
        },
      ],
    });
  };

  const handleAcceptPeriod = shared => {
    confirmAlert({
      title: t('shared-with-me.confirm-alert.accept-period.title'),
      message: t('shared-with-me.confirm-alert.accept-period.message'),
      buttons: [
        {
          label: t('shared-with-me.confirm-alert.accept-period.buttons.cancel'),
        },
        {
          label: t('shared-with-me.confirm-alert.accept-period.buttons.yes'),
          onClick: () => {
            dispatch(
              sharedUpdate({
                id: shared.id,
                accepted: true,
              })
            );
          },
        },
      ],
    });
  };

  const handleAcceptDrive = shared => {
    confirmAlert({
      title: t('shared-with-me.confirm-alert.accept-drive.title'),
      message: t('shared-with-me.confirm-alert.accept-drive.message'),
      buttons: [
        {
          label: t('shared-with-me.confirm-alert.accept-drive.buttons.cancel'),
        },
        {
          label: t('shared-with-me.confirm-alert.accept-drive.buttons.yes'),
          onClick: () => {
            dispatch(
              sharedUpdate({
                id: shared.id,
                accepted: true,
              })
            );
          },
        },
      ],
    });
  };

  const handleAcceptSubject = shared => {
    confirmAlert({
      title: t('shared-with-me.confirm-alert.accept-subject.title'),
      message: t('shared-with-me.confirm-alert.accept-subject.message'),
      buttons: [
        {
          label: t(
            'shared-with-me.confirm-alert.accept-subject.buttons.cancel'
          ),
        },
        {
          label: t('shared-with-me.confirm-alert.accept-subject.buttons.yes'),
          onClick: () => {
            dispatch(sharedUpdate(shared));
            setOpenModalAcceptSubject(false);
            setSubjectSharedItem(null);
          },
        },
      ],
    });
  };

  const handleAcceptClass = shared => {
    confirmAlert({
      title: t('shared-with-me.confirm-alert.accept-class.title'),
      message: t('shared-with-me.confirm-alert.accept-class.message'),
      buttons: [
        {
          label: t('shared-with-me.confirm-alert.accept-class.buttons.cancel'),
        },
        {
          label: t('shared-with-me.confirm-alert.accept-class.buttons.yes'),
          onClick: () => {
            dispatch(sharedUpdate(shared));
            setOpenModalAcceptClass(false);
            setClassSharedItem(null);
          },
        },
      ],
    });
  };

  const handleAccept = shared => {
    switch (shared.type) {
      case 'class':
        setClassSharedItem(shared);
        setOpenModalAcceptClass(true);
        break;
      case 'subject':
        setSubjectSharedItem(shared);
        setOpenModalAcceptSubject(true);
        break;
      case 'period':
        handleAcceptPeriod(shared);
        break;
      case 'drive':
        handleAcceptDrive(shared);
        break;
      default:
        break;
    }
  };

  const renderHeader = () => (
    <PageHeader breadcrumb={[t('shared-with-me.breadcrumb.1')]} />
  );

  const renderTableRow = item => {
    const getContent = () => {
      const getType = () => {
        switch (item.type) {
          case 'class':
            return t('shared-with-me.shared-item.type.class');
          case 'subject':
            return t('shared-with-me.shared-item.type.subject');
          case 'period':
            return t('shared-with-me.shared-item.type.period');
          case 'drive': {
            if (item.drive.type === 'file') {
              return t('shared-with-me.shared-item.type.drive.file');
            }
            return t('shared-with-me.shared-item.type.drive.folder');
          }
          default:
            return '';
        }
      };

      const getTitle = () => {
        switch (item.type) {
          case 'class':
            return item.classe.title;
          case 'subject':
            return item.subject.description;
          case 'period':
            return item.period.description;
          case 'drive':
            return item.drive.title;
          default:
            return '';
        }
      };

      return {
        title: getTitle(),
        user: item.userFrom.name,
        type: getType(),
        date: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
      };
    };
    return (
      <tr key={item.id}>
        <td width="1%">
          <Image src={item.userFrom.avatar_url} />
        </td>
        <td>{getContent().title}</td>
        <td width="1%" className="table-nowrap">
          <b>{t('shared-with-me.shared-item.label-type')} </b>
          {getContent().type}
        </td>
        <td width="1%" className="table-nowrap">
          <b>{t('shared-with-me.shared-item.label-shared-by')} </b>
          {getContent().user}
        </td>
        <td width="1%" className="table-nowrap">
          <b>{t('shared-with-me.shared-item.label-shared-on')} </b>
          {getContent().date}
        </td>
        <HasPermission>
          <td width="1%">
            <div className="table-action">
              <button
                data-tip={t('shared-with-me.shared-item.accept')}
                type="button"
                className="table-action-accept"
                onClick={() => {
                  handleAccept(item);
                }}
              >
                <FaCheckDouble />
              </button>
              <button
                data-tip={t('shared-with-me.shared-item.delete')}
                type="button"
                className="table-action-delete"
                onClick={() => {
                  handleDelete(item);
                }}
              >
                <FaTrash />
              </button>
              <ReactTooltip />
            </div>
          </td>
        </HasPermission>
      </tr>
    );
  };

  const renderShareList = () => (
    <ShareList>
      <Table>
        <tbody>
          {shareds.length > 0 > 0 ? (
            shareds.map(renderTableRow)
          ) : (
            <tr>
              <td align="center">{t('shared-with-me.no-shared-items')}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </ShareList>
  );

  const renderContent = () => <Content>{renderShareList()}</Content>;

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {openModalAcceptSubject && (
          <ModalAcceptSubject
            onClose={() => {
              setOpenModalAcceptSubject(false);
              setSubjectSharedItem(null);
            }}
            onAccept={dataPost => {
              handleAcceptSubject(dataPost);
            }}
            sharedItem={subjectSharedItem}
          />
        )}
        {openModalAcceptClass && (
          <ModalAcceptClass
            onClose={() => {
              setOpenModalAcceptClass(false);
              setClassSharedItem(null);
            }}
            onAccept={dataPost => {
              handleAcceptClass(dataPost);
            }}
            sharedItem={classSharedItem}
          />
        )}
      </PageContent>
    </>
  );
};

export default SharedWithMe;
