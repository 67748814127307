import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus, FaUsers } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import SearchBox from '~/components/SearchBox';

import colors from '~/styles/colors';
import CardBox from '~/components/CardBox';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import ModalSearch from './components/ModalSearch';
import ModalInvites from './components/ModalInvites';
import Tour from '~/components/Tour';
import TourButton from '~/components/TourButton';
import HasPermission from '~/components/HasPermission';

import {
  friendRequest,
  deleteFriendRequest,
  friendInvitesRequest,
} from '~/store/modules/friend/actions';
import { tourSetState } from '~/store/modules/tour/actions';

import { Content, FriendList, NewFriend, HeaderButton } from './styles';

const Friends = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { friends, friendsInvites } = useSelector(state => state.friend);
  const { showedFriends } = useSelector(state => state.tour);
  const [modalSearchOpen, setModalSearchOpen] = useState(false);
  const [modalInvitesOpen, setModalInvitesOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    { selector: '#friend-add', content: t('tour.friends.add') },
    { selector: '#friend-invite', content: t('tour.friends.invite') },
    { selector: '#friend-search', content: t('tour.friends.search') },
  ];

  useEffect(() => {
    dispatch(friendRequest());
    dispatch(friendInvitesRequest());
    if (!showedFriends) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const handleDeleteFriend = friendId => {
    confirmAlert({
      title: t('friends.confirm-alert.delete-friend.title'),
      message: t('friends.confirm-alert.delete-friend.message'),
      buttons: [
        {
          label: t('friends.confirm-alert.delete-friend.buttons.cancel'),
        },
        {
          label: t('friends.confirm-alert.delete-friend.buttons.yes'),
          onClick: () => {
            dispatch(deleteFriendRequest(friendId));
          },
        },
      ],
    });
  };

  const renderHeader = () => (
    <PageHeader
      breadcrumb={[t('friends.breadcrumb.1')]}
      actionElements={() => (
        <>
          <TourButton onClick={() => setTurIsOpen(true)} />
          <HasPermission>
            <>
              <HeaderButton
                id="friend-invite"
                onClick={() => {
                  setModalInvitesOpen(true);
                }}
                badge={friendsInvites.length}
                color={colors.primary}
                title={t('friends.invitations')}
                icon={FaUsers}
              />
              <HeaderButton
                id="friend-add"
                type="button"
                title={t('friends.add-friend')}
                icon={FaPlus}
                onClick={() => {
                  setModalSearchOpen(true);
                }}
                color={colors.secondary}
              />
            </>
          </HasPermission>
        </>
      )}
    />
  );

  const renderSearchBox = () => (
    <SearchBox
      id="friend-search"
      placeholder={t('friends.search')}
      value={searchValue}
      onChange={val => setSearchValue(val)}
    />
  );

  const renderFriends = () => {
    const friendList = friends.filter(friend => {
      const friendDescription = friend.name.toUpperCase();
      const friendFilter = searchValue.toUpperCase();
      return friendDescription.includes(friendFilter);
    });
    return (
      <FriendList
        justifyContent={friends.length > 3 ? 'space-between' : 'unset'}
      >
        {friends.length > 0 ? (
          friendList.map(item => (
            <CardBox
              key={item.id}
              deleteAction={() => {
                handleDeleteFriend(item.id);
              }}
              imageCard={item.avatar_url}
              width={250}
            >
              <div className="card-box-title">{item.name}</div>
              <div className="card-box-subtitle">{item.email}</div>
              <div className="card-box-subtitle">
                <b>{t('friends.student')}</b>
              </div>
            </CardBox>
          ))
        ) : (
          <NewFriend
            onClick={() => {
              setModalSearchOpen(true);
            }}
          >
            <FaPlus />
            {t('friends.add-friend')}
          </NewFriend>
        )}
      </FriendList>
    );
  };

  const renderContent = () => (
    <Content>
      {renderSearchBox()}
      {renderFriends()}
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {modalSearchOpen && (
          <ModalSearch
            onClose={() => {
              setModalSearchOpen(false);
            }}
          />
        )}
        {modalInvitesOpen && (
          <ModalInvites
            onClose={() => {
              setModalInvitesOpen(false);
            }}
          />
        )}
        <Tour
          steps={tourSteps}
          isOpen={turIsOpen}
          onRequestClose={() => setTurIsOpen(false)}
          closeWithMask={false}
          startAt={0}
          onClose={() => {
            dispatch(tourSetState({ showedFriends: true }));
          }}
        />
      </PageContent>
    </>
  );
};

export default Friends;
