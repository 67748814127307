import React, { useRef } from 'react';
import * as Yup from 'yup';
import { FaSignature } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';

import {
  classFileUpdate,
} from '~/store/modules/class/actions';
import { getValidationErrors } from '~/utils';

import { Unform, UnformInput, ButtonSave } from './styles';

export default function ModalEditFile({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { formFileEdit } = useSelector(state => state.classe);

  const schema = Yup.object().shape({
    title: Yup.string().required(
      t('class-view.modal-edit-file.field.title.message.required')
    ),
  });

  const handleUpdate = async ({ title }) => {
    dispatch(
      classFileUpdate({
        ...formFileEdit,
        title,
      })
    );
    onClose();
  };

  return (
    <Modal
      visible
      width="650px"
      height="auto"
      effect="fadeInUp"
      title={t('class-view.modal-edit-file.title')}
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleUpdate(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={formFileEdit}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('class-view.modal-edit-file.field.title.label')}
          name="title"
          autoFocus
          style={{ marginRight: 20 }}
        />
        <ButtonSave type="submit" title={t('class-view.modal-edit-file.save')} />
      </Unform>
    </Modal>
  );
}

ModalEditFile.propTypes = {
  onClose: PropTypes.func.isRequired,
};
