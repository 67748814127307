import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import { sharedRequestSuccess, sharedRequest } from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* request() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'sharecontents');
    // Stop loading
    yield put(stopLoader());

    yield put(sharedRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.shared.request.error'));
  }
}

export function* createShared({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { shared } = payload;
    yield call(api.post, 'sharecontents', shared);
    // Stop loading
    yield put(stopLoader());
    if (shared.type === 'period') {
      toast.success(i18n.t('store.shared.create-shared.success.period'));
    }
    if (shared.type === 'subject') {
      toast.success(i18n.t('store.shared.create-shared.success.subject'));
    }
    if (shared.type === 'class') {
      toast.success(i18n.t('store.shared.create-shared.success.class'));
    }
    if (shared.type === 'drive') {
      toast.success(i18n.t('store.shared.create-shared.success.drive'));
    }
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.shared.create-shared.error'));
  }
}

export function* updateShared({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...shared } = payload.shared;
    yield call(api.put, `sharecontents/${id}`, shared);
    // Stop loading
    yield put(stopLoader());
    yield put(sharedRequest());
    toast.success(i18n.t('store.shared.update-shared.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.shared.update-shared.error'));
  }
}

export function* deleteShared({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...shared } = payload.shared;
    yield call(api.put, `sharecontents/${id}`, shared);
    // Stop loading
    yield put(stopLoader());
    yield put(sharedRequest());
    toast.success(i18n.t('store.shared.delete-shared.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.shared.delete-shared.error'));
  }
}

export default all([
  takeLatest(types.SHARED_REQUEST, request),
  takeLatest(types.SHARED_CREATE, createShared),
  takeLatest(types.SHARED_UPDATE, updateShared),
  takeLatest(types.SHARED_DELETE, deleteShared),
]);
