/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { fileToBase64 } from '~/utils';
import colors from '~/styles/colors';
import { Container, Button, File } from './styles';

const InputFile = ({
  icon: Icon,
  name,
  onStartProgress,
  accept,
  title,
  color,
  className,
  style,
  maxSize,
  changeName,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [file64, setFile64] = useState('');
  const [internalError, setInternalError] = useState('');
  const [fileName, setFileName] = useState('');

  const { fieldName, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return file64;
      },
      clearValue: ref => {
        ref.value = '';
        setFile64('');
      },
    });
  }, [fieldName, registerField, file64]);

  return (
    <Container className={className} style={style}>
      <Button htmlFor={name} color={color}>
        {Icon && <Icon className="icon" />}
        {!!changeName && !!fileName ? fileName : title}
      </Button>
      <File
        name={name}
        id={name}
        accept={accept}
        ref={inputRef}
        {...rest}
        onChange={async e => {
          setFile64('');
          if (e.target.files.length > 0) {
            const itemFile = e.target.files[0];
            if (maxSize && itemFile.size > maxSize) {
              setInternalError(`Tamanho máximo do arquivo deve ser de ${maxSize/1000000}MB`);
              setFile64('');
              onStartProgress('');
              setFileName('');
            } else {
              const base64 = await fileToBase64(itemFile);
              setFile64(base64);
              onStartProgress(base64);
              setInternalError('');
              setFileName(itemFile.name);
            }
          }
        }}
      />
      {error && !internalError && <span className="error">{error}</span>}
      {!!internalError && <span className="error">{internalError}</span>}
    </Container>
  );
};

InputFile.defaultProps = {
  color: colors.primary,
  icon: null,
  name: 'file',
  onStartProgress: () => {},
  accept: 'image/*',
  title: 'Upload',
  style: null,
  maxSize: null,
  changeName: false,
};

InputFile.propTypes = {
  maxSize: PropTypes.number,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
  icon: PropTypes.func,
  name: PropTypes.string,
  onStartProgress: PropTypes.func,
  accept: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.shape(),
  changeName: PropTypes.bool,
};

export default InputFile;
