import types from './types';

export function dashboardRequest() {
  return {
    type: types.DASHBOARD_REQUEST,
  };
}

export function dashboardRequestSuccess(dashboard) {
  return {
    type: types.DASHBOARD_REQUEST_SUCCESS,
    payload: { dashboard },
  };
}
