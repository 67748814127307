import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import {
  Content,
  PeriodList,
  PeriodBoxButton,
  NewPeriod,
  HeaderNewPeriod,
} from './styles';
import SearchBox from '~/components/SearchBox';
import iconPeriods from '~/assets/images/icons/periods.svg';
import Route from '~/services/history';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import Tour from '~/components/Tour';
import TourButton from '~/components/TourButton';
import HasPermission from '~/components/HasPermission';

import CardBox from '~/components/CardBox';
import ModalEdit from './components/ModalEdit';
import ModalCreate from './components/ModalCreate';
import ModalShare from './components/ModalShare';

import {
  periodRequest,
  periodSetEdit,
  periodSetModalCreate,
  deletePeriodRequest,
} from '~/store/modules/period/actions';

import { tourSetState } from '~/store/modules/tour/actions';

const Periods = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { periods, periodEdit, periodModalCreate } = useSelector(
    state => state.period
  );
  const { showedPeriods } = useSelector(state => state.tour);
  const [searchValue, setSearchValue] = useState('');
  const [periodToShare, setPeriodToShare] = useState('');
  const [modalShareOpened, setModalShareOpened] = useState(false);
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    {
      selector: '.left-menu-indexed-search',
      content: t('tour.indexed-search'),
    },
    {
      selector: '.left-menu-my-notebook',
      content: t('tour.menu-left.my-notebook'),
    },
    {
      selector: '.left-menu-study-for-test',
      content: t('tour.menu-left.study-for-test'),
    },
    { selector: '.left-menu-friends', content: t('tour.menu-left.friends') },
    { selector: '.left-menu-agenda', content: t('tour.menu-left.agenda') },
    {
      selector: '.left-menu-my-certificates',
      content: t('tour.menu-left.my-certificates'),
    },
    { selector: '.left-menu-my-files', content: t('tour.menu-left.my-files') },
    {
      selector: '.left-menu-shared-with-me',
      content: t('tour.menu-left.shared-with-me'),
    },
    {
      selector: '.left-menu-configure-notification',
      content: t('tour.menu-left.configure-notification'),
    },
    { selector: '.left-menu-help', content: t('tour.menu-left.help') },
    { selector: '.left-menu-profile', content: t('tour.menu-left.profile') },
    { selector: '#period-new', content: t('tour.periods.new') },
    // { selector: '#period-search', content: t('tour.periods.search') },
  ];

  useEffect(() => {
    dispatch(periodRequest());
    if (!showedPeriods) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const handleDeletePeriod = periodId => {
    confirmAlert({
      title: t('period.confirm-alert.delete-period.title'),
      message: t('period.confirm-alert.delete-period.message'),
      buttons: [
        {
          label: t('period.confirm-alert.delete-period.buttons.cancel'),
        },
        {
          label: t('period.confirm-alert.delete-period.buttons.yes'),
          onClick: () => {
            dispatch(deletePeriodRequest(periodId));
          },
        },
      ],
    });
  };

  const renderHeader = () => (
    <PageHeader
      breadcrumb={[t('period.breadcrumb.1')]}
      actionElements={() => (
        <>
          <TourButton onClick={() => setTurIsOpen(true)} />
          <HasPermission>
            <HeaderNewPeriod
              id="period-new"
              title={t('period.new-period')}
              icon={FaPlus}
              onClick={() => {
                dispatch(periodSetModalCreate(true));
              }}
            />
          </HasPermission>
        </>
      )}
    />
  );

  const renderSearchBox = () => (
    <SearchBox
      id="period-search"
      placeholder={t('period.search')}
      value={searchValue}
      onChange={val => setSearchValue(val)}
    />
  );

  const renderPeriods = () => {
    const periodList = periods.filter(period => {
      const periodDescription = period.description.toUpperCase();
      const periodFilter = searchValue.toUpperCase();
      return periodDescription.includes(periodFilter);
    });
    return (
      <PeriodList
        justifyContent={periods.length > 3 ? 'space-between' : 'unset'}
      >
        {periods.length > 0 ? (
          periodList.map(item => (
            <CardBox
              key={item.id}
              shareAction={() => {
                setPeriodToShare(item.id);
                setModalShareOpened(true);
              }}
              deleteAction={() => {
                handleDeletePeriod(item.id);
              }}
              editAction={() => {
                dispatch(periodSetEdit(item));
              }}
              sharedBy={item.userShared ? item.userShared.name : ''}
              imageCard={iconPeriods}
              width={250}
            >
              <div className="card-box-title">{item.description}</div>
              <div className="card-box-subtitle">{`${item.initial_date} - ${item.final_date}`}</div>
              <div className="card-box-subtitle">
                <b>
                  {item.is_current
                    ? t('period.card-item.status.open')
                    : t('period.card-item.status.closed')}
                </b>
              </div>
              <PeriodBoxButton
                title={t('period.card-item.matters')}
                onClick={() => Route.replace(`/subjects/${item.id}`)}
              />
            </CardBox>
          ))
        ) : (
          <NewPeriod
            onClick={() => {
              dispatch(periodSetModalCreate(true));
            }}
          >
            <FaPlus />
            {t('period.new-period')}
          </NewPeriod>
        )}
      </PeriodList>
    );
  };

  const renderContent = () => (
    <Content>
      {renderSearchBox()}
      {renderPeriods()}
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {periodEdit && <ModalEdit />}
        {periodModalCreate && <ModalCreate />}
        {modalShareOpened && (
          <ModalShare
            periodToShare={periodToShare}
            onClose={() => {
              setPeriodToShare('');
              setModalShareOpened(false);
            }}
          />
        )}
        <Tour
          steps={tourSteps}
          isOpen={turIsOpen}
          onRequestClose={() => setTurIsOpen(false)}
          closeWithMask={false}
          startAt={0}
          onClose={() => {
            dispatch(tourSetState({ showedPeriods: true }));
          }}
        />
      </PageContent>
    </>
  );
};

export default Periods;
