/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import HasPermission from '~/components/HasPermission';
import {
  agendaRequest,
  agendaSetUpdate,
  agendaSetModalCreate,
} from '~/store/modules/agenda/actions';

import colors from '~/styles/colors';
import Route from '~/services/history';
import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import ModalEdit from './components/ModalEdit';
import ModalCreate from './components/ModalCreate';
import { Content, CalendarToolbar } from './styles';

const localizer = momentLocalizer(moment);

const Agenda = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const calendarRef = useRef();
  const { events, eventEdit, eventModalCreate } = useSelector(
    state => state.agenda
  );

  const agendaMessages = {
    next: t('agenda.next'),
    previous: t('agenda.previous'),
    today: t('agenda.today'),
    month: t('agenda.month'),
    week: t('agenda.week'),
    work_week: t('agenda.work-week'),
    day: t('agenda.day'),
    agenda: t('agenda.agenda'),
    event: t('agenda.event'),
    time: t('agenda.time'),
    date: t('agenda.date'),
    showMore: number => t('agenda.show-more', { number }),
    noEventsInRange: t('agenda.no-events-in-range'),
    allDay: t('agenda.all-day'),
    yesterday: t('agenda.yesterday'),
    tomorrow: t('agenda.tomorrow'),
  };

  const agendaFormats = {
    agendaDateFormat: `dddd, DD [${t('agenda.tomorrow')}] MMMM`,
    agendaHeaderFormat: ({ start }) => {
      return moment.utc(start).format('MMMM YYYY');
    },
  };

  const defaultDate = moment(moment().format('YYYY-MM-01')).toDate();
  const defaultView = 'month';
  const [eventList, setEventList] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [calendarActualDate, setCalendarActualDate] = useState(
    moment().format('YYYY-MM-01')
  );
  const renderHeader = () => (
    <PageHeader breadcrumb={[t('agenda.breadcrumb.1')]} />
  );

  useEffect(() => {
    dispatch(agendaRequest());
  }, []);

  useEffect(() => {
    setEventList(
      events.map(item => ({
        ...item,
        initial_date: moment(item.initial_date).toDate(),
        final_date: moment(item.final_date).toDate(),
      }))
    );
  }, [events]);

  const handleRedirectTestToStudyContent = event => {
    confirmAlert({
      title: t('agenda.confirm-alert.redirect-test-to-study-content.title'),
      message: t(
        'agenda.confirm-alert.redirect-test-to-study-content.message',
        { event: event.title }
      ),
      buttons: [
        {
          label: t(
            'agenda.confirm-alert.redirect-test-to-study-content.buttons.cancel'
          ),
        },
        {
          label: t(
            'agenda.confirm-alert.redirect-test-to-study-content.buttons.yes'
          ),
          onClick: () => {
            Route.replace(`/study-content/${event.id}`);
          },
        },
      ],
    });
  };

  const renderContent = () => (
    <Content>
      <Calendar
        ref={calendarRef}
        className="agenda-box"
        popup
        selectable
        defaultDate={defaultDate}
        defaultView={defaultView}
        events={eventList}
        localizer={localizer}
        messages={agendaMessages}
        views={['month', 'agenda']}
        formats={agendaFormats}
        startAccessor="initial_date"
        endAccessor="final_date"
        onNavigate={date => {
          setCalendarActualDate(moment(date).format('YYYY/MM/01'));
        }}
        onSelectEvent={event => {
          if (event.type === 'event') {
            dispatch(agendaSetUpdate(event));
          }
          if (event.type === 'test') {
            handleRedirectTestToStudyContent(event);
          }
        }}
        onSelectSlot={({ start }) => {
          setInitialDate(moment(start).format('DD/MM/YYYY 00:00'));
          dispatch(agendaSetModalCreate(true));
        }}
        components={{
          // eslint-disable-next-line react/prop-types
          toolbar: ({ onNavigate }) => (
            <CalendarToolbar>
              <div className="calendar-toolbar-action">
                <button
                  className="calendar-prev-button"
                  type="button"
                  onClick={() => onNavigate('PREV')}
                >
                  {t('agenda.previous')}
                </button>
                <button
                  className="calendar-next-button"
                  type="button"
                  onClick={() => onNavigate('NEXT')}
                >
                  {t('agenda.next')}
                </button>
              </div>
              <h2>{moment(calendarActualDate).format('MMMM YYYY')}</h2>
              <div className="calendar-toolbar-action">
                <button
                  className="calendar-prev-button"
                  type="button"
                  onClick={() => calendarRef.current.handleViewChange('month')}
                >
                  {t('agenda.month')}
                </button>
                <button
                  className="calendar-next-button"
                  type="button"
                  onClick={() => calendarRef.current.handleViewChange('agenda')}
                >
                  {t('agenda.agenda')}
                </button>
              </div>
            </CalendarToolbar>
          ),
        }}
      />
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent style={{ backgroundColor: colors.white }}>
        {renderContent()}
        <HasPermission>{eventEdit && <ModalEdit />}</HasPermission>
        <HasPermission>
          {eventModalCreate && initialDate && (
            <ModalCreate initialDate={initialDate} />
          )}
        </HasPermission>
      </PageContent>
    </>
  );
};

export default Agenda;
