import React from 'react';
import Proptypes from 'prop-types';
import { FaFileDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { downloadFile } from '../../Utils';

const ToolbarItemExport = ({ diagram }) => {
  const { t } = useTranslation();
  const onClickExportJson = () => {
    const diagramProps = diagram.getDiagramProps();
    const { controller, model } = diagramProps;

    const json = controller.run('serializeModel', diagramProps);
    const jsonStr = JSON.stringify(json);
    const url = `data:text/plain,${encodeURIComponent(jsonStr)}`;
    const title = controller.run('getTopicTitle', {
      ...diagramProps,
      topicKey: model.rootTopicKey,
    });
    downloadFile(url, `${title}.blinkmind`);
  };

  return (
    <button
      type="button"
      className="toolbar-menu-item"
      onClick={onClickExportJson}
      data-tip={t('class-view.modal-mindmaps.toolbar.buttonExport')}
    >
      <FaFileDownload />
    </button>
  );
};

ToolbarItemExport.propTypes = {
  diagram: Proptypes.shape().isRequired,
};

export default ToolbarItemExport;
