import styled from 'styled-components';
import ButtonComponent from '~/components/Button';
import colors from '~/styles/colors';

export const Content = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  padding: 20px;
  height: 100%;
  .content-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    max-height: 100%;
    padding: 0 5px;
    &:last-child {
      padding-right: 0;
    }
    &:first-child {
      padding-left: 0;
    }
    .column-item {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: ${colors.accent3};
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      min-height: calc(50% - 5px);
      &:last-child {
        margin-bottom: 0;
      }
      .column-item-header {
        display: flex;
        border-bottom: 2px solid ${colors.secondary};
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;
        > h2 {
          color: ${colors.secondary};
          font-weight: 400;
          font-size: 20px;
          line-height: 1;
          display: flex;
          align-items: center;
          > svg {
            font-size: 15px;
            margin-right: 5px;
          }
        }
      }
      .column-item-content {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
      .column-item-player {
        .component-player {
          background-color: ${colors.primary};
          border-radius: 10px;
          margin-bottom: 10px;
          .rhap_main-controls-button,
          .rhap_repeat-button,
          .rhap_volume-button,
          .rhap_time {
            color: ${colors.accent3};
          }
          .rhap_progress-indicator,
          .rhap_volume-indicator,
          .rhap_progress-filled {
            background-color: ${colors.accent3};
          }
          .rhap_download-progress,
          .rhap_volume-bar {
            background-color: ${colors.secondary};
          }
        }
      }
    }
  }
`;

export const HeaderQuestions = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginRight: 10,
  },
})``;

export const HeaderFiles = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginRight: 10,
  },
})``;

export const HeaderKeywords = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginRight: 10,
  },
})``;

export const HeaderButton = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginLeft: 10,
  },
})``;

export const ButtonActionColumnHeader = styled(ButtonComponent).attrs({
  color: colors.secondary,
  style: {
    height: 30,
    fontSize: 12,
  },
})``;

export const ButtonInfo = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${colors.secondary};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    color: ${colors.white};
    font-size: 12px;
  }
`;
