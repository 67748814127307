/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import { components } from 'react-select';
import { IoIosArrowDown } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import colors from '~/styles/colors';
import { Select, Container, Label, WrapIcon } from './styles';

const SelectComponent = ({
  label,
  name,
  style,
  className,
  icon: Icon,
  options,
  ...rest
}) => {
  const selectRef = useRef(null);
  const { t } = useTranslation();
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [selectValue, setSelectValue] = useState(
    defaultValue ? options.find(opt => opt.value === defaultValue) : null
  );

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <IoIosArrowDown color={colors.secondary} size={15} />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: ref => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map(option => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);
  return (
    <Container
      style={style}
      className={className}
      isFocused={inputIsFocused || !!selectValue}
      hasIcon={!!Icon}
    >
      <Select
        ref={selectRef}
        defaultValue={selectValue}
        noOptionsMessage={() =>
          t('components.unform.select.no-options-message')
        }
        {...rest}
        components={{ DropdownIndicator }}
        options={options}
        placeholder=""
        onMenuOpen={() => setInputIsFocused(true)}
        onMenuClose={() => setInputIsFocused(false)}
        onChange={setSelectValue}
      />
      {!!label && <Label>{label}</Label>}
      {Icon && (
        <WrapIcon>
          <Icon />
        </WrapIcon>
      )}
      {error && <span className="error">{error}</span>}
    </Container>
  );
};

SelectComponent.defaultProps = {
  label: '',
  name: '',
  icon: null,
  style: null,
  options: [],
};

SelectComponent.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.func,
  style: PropTypes.shape(),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  className: PropTypes.string.isRequired,
};

export default SelectComponent;
