import types from './types';

export function updateProfileRequest(user) {
  return {
    type: types.UPDATE_PROFILE_REQUEST,
    payload: { user },
  };
}
export function updateProfileSuccess(profile) {
  return {
    type: types.UPDATE_PROFILE_SUCCESS,
    payload: { profile },
  };
}
export function notificationConfigRequest() {
  return {
    type: types.NOTIFICATION_CONFIG_REQUEST,
  };
}
export function notificationConfigRequestSuccess(notificationConfig) {
  return {
    type: types.NOTIFICATION_CONFIG_REQUEST_SUCCESS,
    payload: { notificationConfig },
  };
}
export function notificationConfigSave(notificationConfig) {
  return {
    type: types.NOTIFICATION_CONFIG_SAVE,
    payload: { notificationConfig },
  };
}
