import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AudioPlayer from 'react-h5-audio-player';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import ReactTooltip from 'react-tooltip';
import FileSaver from 'file-saver';
import 'react-h5-audio-player/lib/styles.css';
import {
  FaQuestionCircle,
  FaKey,
  FaMusic,
  FaFile,
  FaPen,
  FaTrash,
  FaPlay,
  FaPlus,
  FaFileImport,
  FaCloudDownloadAlt,
  FaInfo,
} from 'react-icons/fa';
import { CgListTree } from 'react-icons/cg';

import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import Table from '~/components/Table';
import Route from '~/services/history';
import HasPermission from '~/components/HasPermission';
import Config from '~/config';

import {
  classRequest,
  classRequestSuccess,
  classQuestionRequest,
  classKeywordRequest,
  classFileRequest,
  classFileCreate,
  classKeywordSet,
  deleteKeywordRequest,
  classQuestionSet,
  deleteQuestionRequest,
  classFileDelete,
  classSetFileUpdate,
  classMindmapSet,
  deleteMindmapRequest,
  classMindmapEditorSet,
  classMindmapRequest,
} from '~/store/modules/class/actions';

import { exportPdfClasses } from '~/store/modules/subject/actions';
import { tourSetState } from '~/store/modules/tour/actions';

import ModalQuestion from './components/ModalQuestions';
import ModalMindmap from './components/ModalMindmap';
import ModalMindmapEditor from './components/ModalMindmapEditor';
import ModalKeywords from './components/ModalKeywords';
import ModalEditFile from './components/ModalEditFile';
import ButtonRecorder from './components/ButtonRecorder';
import DropZoneFiles from './components/DropZoneFiles';
import FileItem from './components/FileItem';
import Tour from '~/components/Tour';
import TourButton from '~/components/TourButton';

import colors from '~/styles/colors';
import {
  HeaderButton,
  Content,
  ButtonActionColumnHeader,
  ButtonInfo,
} from './styles';

const Editor = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    classe,
    questions,
    keywords,
    files,
    mindmaps,
    keywordForm,
    questionForm,
    mindmapForm,
    mindmapEditorForm,
    formFileEdit,
  } = useSelector(state => state.classe);

  const { token, refreshToken } = useSelector(state => state.auth);
  const { showedClassDetail } = useSelector(state => state.tour);

  const { periodId, subjectId, classId } = match.params;
  const [audioUrlFile, setAudioUrlFile] = useState('');
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    {
      selector: '#class-detail-open-editor',
      content: t('tour.class-detail.open-editor'),
    },
    {
      selector: '#class-detail-export-pdf',
      content: t('tour.class-detail.export-pdf'),
    },
    { selector: '#class-detail-files', content: t('tour.class-detail.files') },
    {
      selector: '#class-detail-audios',
      content: t('tour.class-detail.audios'),
    },
    {
      selector: '#class-detail-mindmap',
      content: t('tour.class-detail.mindmap'),
    },
    {
      selector: '#class-detail-keywords',
      content: t('tour.class-detail.keywords'),
    },
    {
      selector: '#class-detail-questions',
      content: t('tour.class-detail.questions'),
    },
  ];

  useEffect(() => {
    dispatch(classRequestSuccess(null));
    dispatch(classRequest(classId));
    dispatch(classQuestionRequest(classId));
    dispatch(classKeywordRequest(classId));
    dispatch(classFileRequest(classId));
    dispatch(classMindmapRequest(classId));
    if (!showedClassDetail) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const handleUploadAudio = blob => {
    const formData = new FormData();
    formData.append('file_name', blob, 'audio.wav');
    formData.append('class_id', classId);
    formData.append(
      'title',
      t('class-view.upload-audio-title', {
        date: moment().format('DD/MM/YYYY [às] HH:mm'),
      })
    );
    formData.append('type', 'audio');
    dispatch(classFileCreate(classId, formData));
  };

  const handleUploadFile = blob => {
    const formData = new FormData();
    formData.append('file_name', blob, `file.${blob.name.split('.').pop()}`);
    formData.append('class_id', classId);
    formData.append('title', blob.name);
    formData.append('type', 'file');
    dispatch(classFileCreate(classId, formData));
  };

  const renderHeader = () => (
    <PageHeader
      onBack={() => {
        Route.replace(`/classes/${periodId}/${subjectId}`);
      }}
      breadcrumb={[t('class-view.breadcrumb.1'), classe.title]}
      actionElements={() => (
        <>
          <TourButton onClick={() => setTurIsOpen(true)} />
          <HeaderButton
            id="class-detail-export-pdf"
            type="button"
            title={t('classes.class-item.export')}
            icon={FaCloudDownloadAlt}
            color={colors.primary}
            onClick={() => {
              dispatch(exportPdfClasses([classe.id]));
            }}
          />
          <HasPermission>
            <HeaderButton
              id="class-detail-open-editor"
              type="button"
              title={t('class-view.open-editor')}
              icon={FaFileImport}
              color={colors.secondary}
              onClick={() => {
                window.open(
                  `${Config.EDITOR_URI}?c=${classe.id}&t=${token}&r=${refreshToken}`,
                  '_blank'
                );
              }}
            />
          </HasPermission>
        </>
      )}
    />
  );

  const renderTableQuestion = () => (
    <div
      className="column-item"
      id="class-detail-questions"
      style={{ maxHeight: 'calc(50% - 5px)' }}
    >
      <div className="column-item-header">
        <h2>
          <FaQuestionCircle />
          {t('class-view.modal-questions.title')}
        </h2>
        <HasPermission>
          <ButtonActionColumnHeader
            title={t('class-view.modal-questions.new')}
            icon={FaPlus}
            onClick={() => {
              dispatch(classQuestionSet({}));
            }}
          />
        </HasPermission>
      </div>
      <div className="column-item-content">
        <Table>
          <tbody>
            {questions.length === 0 && (
              <tr>
                <td align="center">
                  {t('class-view.modal-questions.no-question-listed')}
                </td>
              </tr>
            )}
            {questions.length >= 0 &&
              questions.map((item, index) => (
                <tr key={item.id}>
                  <td>{`${index + 1} - ${item.description}`}</td>
                  <HasPermission>
                    <td width="1%">
                      <div className="table-action">
                        <button
                          type="button"
                          className="table-action-edit"
                          onClick={() => {
                            dispatch(classQuestionSet(item));
                          }}
                        >
                          <FaPen />
                        </button>
                        <button
                          type="button"
                          className="table-action-delete"
                          onClick={() => {
                            confirmAlert({
                              title: t(
                                'class-view.modal-questions.confirm-alert.delete-question.title'
                              ),
                              message: t(
                                'class-view.modal-questions.confirm-alert.delete-question.message'
                              ),
                              buttons: [
                                {
                                  label: t(
                                    'class-view.modal-questions.confirm-alert.delete-question.buttons.cancel'
                                  ),
                                },
                                {
                                  label: t(
                                    'class-view.modal-questions.confirm-alert.delete-question.buttons.yes'
                                  ),
                                  onClick: () => {
                                    dispatch(deleteQuestionRequest(item));
                                  },
                                },
                              ],
                            });
                          }}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </HasPermission>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );

  const renderTableKeyword = () => (
    <div
      className="column-item"
      id="class-detail-keywords"
      style={{ maxHeight: 'calc(50% - 5px)' }}
    >
      <div className="column-item-header">
        <h2>
          <FaKey />
          {t('class-view.modal-keywords.title')}
        </h2>
        <HasPermission>
          <ButtonActionColumnHeader
            title={t('class-view.modal-keywords.new')}
            icon={FaPlus}
            onClick={() => {
              dispatch(classKeywordSet({}));
            }}
          />
        </HasPermission>
      </div>
      <div className="column-item-content">
        <Table>
          <tbody>
            {keywords.length === 0 && (
              <tr>
                <td align="center">
                  {t('class-view.modal-keywords.no-keyword-listed')}
                </td>
              </tr>
            )}
            {keywords.length >= 0 &&
              keywords.map((item, index) => (
                <tr key={item.id}>
                  <td>{`${index + 1} - ${item.description}`}</td>
                  <HasPermission>
                    <td width="1%">
                      <div className="table-action">
                        <button
                          type="button"
                          className="table-action-edit"
                          onClick={() => {
                            dispatch(classKeywordSet(item));
                          }}
                        >
                          <FaPen />
                        </button>
                        <button
                          type="button"
                          className="table-action-delete"
                          onClick={() => {
                            confirmAlert({
                              title: t(
                                'class-view.modal-keywords.confirm-alert.delete-keyword.title'
                              ),
                              message: t(
                                'class-view.modal-keywords.confirm-alert.delete-keyword.message'
                              ),
                              buttons: [
                                {
                                  label: t(
                                    'class-view.modal-keywords.confirm-alert.delete-keyword.buttons.cancel'
                                  ),
                                },
                                {
                                  label: t(
                                    'class-view.modal-keywords.confirm-alert.delete-keyword.buttons.yes'
                                  ),
                                  onClick: () => {
                                    dispatch(deleteKeywordRequest(item));
                                  },
                                },
                              ],
                            });
                          }}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </HasPermission>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );

  const renderTableAudios = () => {
    const filterListAudio = files.filter(i => i.type === 'audio');
    return (
      <div className="column-item" id="class-detail-audios">
        <div className="column-item-header">
          <h2>
            <FaMusic />
            {t('class-view.modal-audio-list.title')}
          </h2>
          <HasPermission>
            <ButtonRecorder
              onData={blob => {
                handleUploadAudio(blob);
              }}
            />
          </HasPermission>
        </div>
        {!!audioUrlFile && (
          <div className="column-item-player">
            <AudioPlayer
              src={audioUrlFile}
              autoPlay
              autoPlayAfterSrcChange
              showJumpControls={false}
              className="component-player"
              onPlayError={() => {
                toast.error(t('class-view.modal-audio-list.error-play-audio'));
              }}
            />
          </div>
        )}
        <div className="column-item-content">
          <Table>
            <tbody>
              {filterListAudio.length === 0 && (
                <tr>
                  <td align="center">
                    {t('class-view.modal-audio-list.no-audio-listed')}
                  </td>
                </tr>
              )}
              {filterListAudio.length >= 0 &&
                filterListAudio.map((item, index) => (
                  <tr key={item.id}>
                    <td>{`${index + 1} - ${item.title}`}</td>
                    <td width="1%">
                      <div className="table-action">
                        <button
                          type="button"
                          className="table-action-edit"
                          onClick={() => {
                            setAudioUrlFile(item.file_url);
                          }}
                        >
                          <FaPlay />
                        </button>
                        <HasPermission>
                          <>
                            <button
                              type="button"
                              className="table-action-edit"
                              onClick={() => {
                                dispatch(classSetFileUpdate(item));
                              }}
                            >
                              <FaPen />
                            </button>
                            <button
                              type="button"
                              className="table-action-delete"
                              onClick={() => {
                                confirmAlert({
                                  title: t(
                                    'class-view.modal-audio-list.confirm-alert.delete-audio.title'
                                  ),
                                  message: t(
                                    'class-view.modal-audio-list.confirm-alert.delete-audio.message'
                                  ),
                                  buttons: [
                                    {
                                      label: t(
                                        'class-view.modal-audio-list.confirm-alert.delete-audio.buttons.cancel'
                                      ),
                                    },
                                    {
                                      label: t(
                                        'class-view.modal-audio-list.confirm-alert.delete-audio.buttons.yes'
                                      ),
                                      onClick: () => {
                                        dispatch(classFileDelete(item));
                                      },
                                    },
                                  ],
                                });
                              }}
                            >
                              <FaTrash />
                            </button>
                          </>
                        </HasPermission>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  const renderTableMindmaps = () => (
    <div
      className="column-item"
      id="class-detail-mindmap"
      style={{ maxHeight: 'calc(50% - 5px)' }}
    >
      <div className="column-item-header">
        <h2>
          <CgListTree />
          {t('class-view.modal-mindmaps.title')}
        </h2>
        <HasPermission>
          <ButtonActionColumnHeader
            title="Novo"
            icon={FaPlus}
            onClick={() => {
              dispatch(classMindmapSet({}));
            }}
          />
        </HasPermission>
      </div>
      <div className="column-item-content">
        <Table>
          <tbody>
            {mindmaps.length === 0 && (
              <tr>
                <td align="center">
                  {t('class-view.modal-mindmaps.no-mindmap-listed')}
                </td>
              </tr>
            )}
            {mindmaps.length >= 0 &&
              mindmaps.map((item, index) => (
                <tr key={item.id}>
                  <td>{`${index + 1} - ${item.title}`}</td>
                  <td width="1%">
                    <div className="table-action">
                      <button
                        type="button"
                        className="table-action-edit"
                        onClick={() => {
                          // dispatch(classMindmapSet(item));
                          dispatch(classMindmapEditorSet(item));
                        }}
                      >
                        <FaPen />
                      </button>
                      <button
                        type="button"
                        className="table-action-delete"
                        onClick={() => {
                          confirmAlert({
                            title: t(
                              'class-view.modal-mindmaps.confirm-alert.delete-mindmap.title'
                            ),
                            message: t(
                              'class-view.modal-mindmaps.confirm-alert.delete-mindmap.message'
                            ),
                            buttons: [
                              {
                                label: t(
                                  'class-view.modal-mindmaps.confirm-alert.delete-mindmap.buttons.cancel'
                                ),
                              },
                              {
                                label: t(
                                  'class-view.modal-mindmaps.confirm-alert.delete-mindmap.buttons.yes'
                                ),
                                onClick: () => {
                                  dispatch(deleteMindmapRequest(item));
                                },
                              },
                            ],
                          });
                        }}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );

  const renderTableFile = () => {
    const filterListFile = files.filter(i => i.type === 'file');
    return (
      <div className="column-item" id="class-detail-files">
        <div className="column-item-header">
          <h2>
            <FaFile />
            {t('class-view.modal-file-list.title')}
          </h2>
          <ButtonInfo
            data-tip={t('class-view.modal-file-list.message-max-size')}
          >
            <FaInfo />
          </ButtonInfo>
        </div>
        <div className="column-item-upload">
          <HasPermission>
            <DropZoneFiles onDropOk={handleUploadFile} maxSize={2000000} />
          </HasPermission>
        </div>
        <div
          className="column-item-content"
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          {filterListFile.length === 0 && (
            <Table>
              <tbody>
                <tr>
                  <td align="center">
                    {t('class-view.modal-file-list.no-file-listed')}
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
          {filterListFile.map(item => (
            <FileItem
              itemFile={item}
              onDownload={() => {
                FileSaver.saveAs(item.file_url, item.file_url.split('/').pop());
              }}
              onEdit={() => {
                dispatch(classSetFileUpdate(item));
              }}
              onDelete={() => {
                confirmAlert({
                  title: t(
                    'class-view.modal-file-list.confirm-alert.delete-audio.title'
                  ),
                  message: t(
                    'class-view.modal-file-list.confirm-alert.delete-audio.message'
                  ),
                  buttons: [
                    {
                      label: t(
                        'class-view.modal-file-list.confirm-alert.delete-audio.buttons.cancel'
                      ),
                    },
                    {
                      label: t(
                        'class-view.modal-file-list.confirm-alert.delete-audio.buttons.yes'
                      ),
                      onClick: () => {
                        dispatch(classFileDelete(item));
                      },
                    },
                  ],
                });
              }}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderContent = () => (
    <Content>
      <div className="content-column" style={{ minWidth: 320, maxWidth: 320 }}>
        {renderTableFile()}
      </div>
      <div className="content-column">
        {renderTableAudios()}
        {renderTableMindmaps()}
      </div>
      <div className="content-column">
        {renderTableKeyword()}
        {renderTableQuestion()}
      </div>
    </Content>
  );

  return (
    classe && (
      <>
        {renderHeader()}
        <PageContent>
          {renderContent()}
          {questionForm && (
            <ModalQuestion
              onClose={() => {
                dispatch(classQuestionSet(null));
              }}
            />
          )}
          {mindmapForm && (
            <ModalMindmap
              onClose={() => {
                dispatch(classMindmapSet(null));
              }}
            />
          )}
          {mindmapEditorForm && (
            <ModalMindmapEditor
              onClose={() => {
                dispatch(classMindmapEditorSet(null));
              }}
            />
          )}
          {keywordForm && (
            <ModalKeywords
              onClose={() => {
                dispatch(classKeywordSet(null));
              }}
            />
          )}
          {formFileEdit && (
            <ModalEditFile
              onClose={() => {
                dispatch(classSetFileUpdate(null));
              }}
            />
          )}
          <ReactTooltip />
          <Tour
            steps={tourSteps}
            isOpen={turIsOpen}
            onRequestClose={() => setTurIsOpen(false)}
            closeWithMask={false}
            startAt={0}
            onClose={() => {
              dispatch(tourSetState({ showedClassDetail: true }));
            }}
          />
        </PageContent>
      </>
    )
  );
};

Editor.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      periodId: PropTypes.string,
      subjectId: PropTypes.string,
      classId: PropTypes.string,
    }),
  }).isRequired,
};

export default Editor;
