import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import {
  friendRequestSuccess,
  friendRequest,
  friendSearchRequestSuccess,
  friendInvitesRequestSuccess,
  friendInvitesRequest,
  friendSearchRequest,
  friendSetSearch,
} from './actions';

import { chatChannelsRequest } from '~/store/modules/chat/actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* request() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'friends');
    // Stop loading
    yield put(stopLoader());

    yield put(friendRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.friend.request.error'));
  }
}

export function* remove({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { friendId } = payload;

    yield call(api.delete, `friends/${friendId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(friendRequest());

    toast.success(i18n.t('store.friend.remove.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.friend.remove.error'));
  }
}

export function* requestSearch() {
  const { friend } = yield select();
  try {
    const search = friend.searchFriend;
    const response = yield call(api.get, `search/friends/?search=${search}`);
    yield put(friendSearchRequestSuccess(response.data));
  } catch (error) {
    toast.error(i18n.t('store.friend.request-search.error'));
  }
}

export function* friendInviteFriend({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { friendId } = payload;
    yield call(api.post, 'invites', {
      friend_id: friendId,
    });
    // Stop loading
    yield put(stopLoader());
    yield put(friendSearchRequest());
    yield put(friendSetSearch(''));

    toast.success(i18n.t('store.friend.friend-invite-friend.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.friend.friend-invite-friend.error'));
  }
}

export function* friendRequestInvites() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'invites');
    // Stop loading
    yield put(stopLoader());

    yield put(friendInvitesRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.friend.friend-request-invites.error'));
  }
}

export function* friendInviteAccept({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { friendId } = payload;
    yield call(api.put, `invites/${friendId}`, {
      accepted: 'true',
    });
    // Stop loading
    yield put(stopLoader());

    yield put(friendRequest());
    yield put(friendInvitesRequest());
    yield put(chatChannelsRequest());
    toast.success(i18n.t('store.friend.friend-invite-accept.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.friend.friend-invite-accept.error'));
  }
}

export default all([
  takeLatest(types.FRIEND_REQUEST, request),
  takeLatest(types.DELETE_FRIEND_REQUEST, remove),
  takeLatest(types.FRIEND_SEARCH_REQUEST, requestSearch),
  takeLatest(types.FRIEND_INVITE_FRIEND_REQUEST, friendInviteFriend),
  takeLatest(types.FRIEND_INVITES_REQUEST, friendRequestInvites),
  takeLatest(types.FRIEND_INVITES_REQUEST_ACCEPT, friendInviteAccept),
]);
