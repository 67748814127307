import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 55px;
  max-height: 55px;
  position: relative;
  padding: 10px;
  .icon-search {
    position: absolute;
    left: 18px;
    color: ${colors.dark4};
    font-size: 15px;
  }
`;

export const Search = styled.input`
  display: flex;
  align-items: center;
  flex: 1;
  border: 0;
  border-radius: 10px;
  text-indent: 30px;
  font-size: 15px;
  font-weight: 400;
  color: ${colors.dark4};
  background-color: ${colors.white};
  height: 35px;
  ::placeholder {
    color: ${colors.dark4};
  }
`;

export const Clear = styled.button`
  position: absolute;
  right: 18px;
  background-color: transparent;
  border: 1px solid ${colors.dark4};
  color: ${colors.dark4};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
