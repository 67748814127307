import React, { useEffect } from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import YouTube from 'react-youtube';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
} from 'react-accessible-accordion';
import { useTranslation } from 'react-i18next';

import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';

import { faqRequest } from '~/store/modules/faq/actions';

import { Content, AccordionItemButton, AccordionItemPanel } from './styles';

const Help = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { faqs } = useSelector(state => state.faq);

  useEffect(() => {
    dispatch(faqRequest());
  }, []);

  const renderHeader = () => {
    return <PageHeader breadcrumb={[t('help.breadcrumb.1')]} />;
  };

  const renderItemText = item => (
    <div
      className="content-text"
      dangerouslySetInnerHTML={{ __html: item.content }}
    />
  );

  const renderItemVideo = item => (
    <YouTube
      videoId={item.content}
      opts={{
        // width: '100%',
        height: '450px',
        playerVars: {
          autoplay: 0,
        },
      }}
      onReady={() => {}}
    />
  );

  const renderItemByType = item => {
    switch (item.type) {
      case 'text':
        return renderItemText(item);
      case 'video':
        return renderItemVideo(item);
      default:
        return '';
    }
  };

  const renderContent = () => (
    <Content>
      <Accordion>
        {faqs.map(item => (
          <AccordionItem key={item.id}>
            <AccordionItemHeading>
              <AccordionItemButton>{item.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>{renderItemByType(item)}</AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>{renderContent()}</PageContent>
    </>
  );
};

export default Help;
