/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { Container, Label, Checkbox } from './styles';

const CheckboxGroup = ({ name, options, className, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = [], error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: refs => {
        return refs.filter(ref => ref.checked).map(ref => ref.value);
      },
      clearValue: refs => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  const renderCheckboxItem = (option, index) => {
    const findItem = defaultValue.filter(dv => dv === option.value);
    return (
      <Checkbox
        {...rest}
        defaultChecked={findItem.length > 0}
        ref={ref => {
          inputRefs.current[index] = ref;
        }}
        value={option.value}
        type="checkbox"
        id={option.id}
      />
    );
  };

  return (
    <Container>
      {options.map((option, index) => (
        <Label htmlFor={option.id} key={option.id} className={className}>
          {renderCheckboxItem(option, index)}
          <h3>{option.label}</h3>
          <span className="checkmark" />
        </Label>
      ))}
      {error && <span className="error">{error}</span>}
    </Container>
  );
};

CheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
};

export default CheckboxGroup;
