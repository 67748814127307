import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import {
  updateProfileSuccess,
  notificationConfigRequestSuccess,
  notificationConfigRequest,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* updateProfile({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...user } = payload.user;

    const response = yield call(api.put, `users/${id}`, user);
    // Stop loading
    yield put(stopLoader());

    yield put(updateProfileSuccess(response.data));
    i18n.changeLanguage(response.data.language || 'en');

    toast.success(i18n.t('store.user.update-profile.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());

    toast.error(i18n.t('store.user.update-profile.error'));
  }
}

export function* notificationRequest() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'users/notification/config');
    // Stop loading
    yield put(stopLoader());

    yield put(notificationConfigRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());

    toast.error(i18n.t('store.user.notification-request.error'));
  }
}

export function* notificationSave({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { notificationConfig } = payload;

    yield call(api.post, 'users/notification/config', {
      notification_config: notificationConfig,
    });
    // Stop loading
    yield put(stopLoader());

    yield put(notificationConfigRequest());
    toast.success(i18n.t('store.user.notification-save.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());

    toast.error(i18n.t('store.user.notification-save.error'));
  }
}

export default all([
  takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(types.NOTIFICATION_CONFIG_REQUEST, notificationRequest),
  takeLatest(types.NOTIFICATION_CONFIG_SAVE, notificationSave),
]);
