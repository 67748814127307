import React from 'react';
import { FaTrash, FaCloudDownloadAlt, FaPen } from 'react-icons/fa';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import colors from '~/styles/colors';
import HasPermission from '~/components/HasPermission';

import {
  Container,
  Image,
  CardBody,
  CardActions,
  CardActionButton,
} from './styles';

export default function CertificateItem({
  editAction,
  downloadAction,
  deleteAction,
  imageCard,
  title,
  initialDate,
  finalDate,
  description,
  workload,
}) {
  const { t } = useTranslation();
  return (
    <Container>
      {imageCard && <Image src={imageCard} />}
      <CardBody>
        <h1>{title}</h1>
        {!!description && (
          <h2>
            <b>{t('certificates.certificate-item.description')}</b>
            {` ${description}`}
          </h2>
        )}
        {!!workload && (
          <h2>
            <b>{t('certificates.certificate-item.workload')}</b>
            {t('certificates.certificate-item.hours', { hours: workload })}
          </h2>
        )}
        {!!initialDate && (
          <h2>
            <b>{t('certificates.certificate-item.started-in')}</b>
            {` ${initialDate}`}
          </h2>
        )}
        {!!finalDate && (
          <h2>
            <b>{t('certificates.certificate-item.finished-in')}</b>
            {` ${finalDate}`}
          </h2>
        )}
      </CardBody>
      <CardActions>
        {downloadAction && (
          <CardActionButton
            data-tip={t('certificates.certificate-item.download')}
            onClick={() => downloadAction()}
          >
            <FaCloudDownloadAlt />
          </CardActionButton>
        )}
        <HasPermission>
          {editAction && (
            <CardActionButton
              data-tip={t('certificates.certificate-item.edit')}
              onClick={() => editAction()}
            >
              <FaPen />
            </CardActionButton>
          )}
        </HasPermission>
        <HasPermission>
          {deleteAction && (
            <CardActionButton
              data-tip={t('certificates.certificate-item.delete')}
              onClick={() => deleteAction()}
              color={colors.accent2}
            >
              <FaTrash />
            </CardActionButton>
          )}
        </HasPermission>
      </CardActions>
      <ReactTooltip />
    </Container>
  );
}

CertificateItem.defaultProps = {
  editAction: null,
  downloadAction: null,
  deleteAction: null,
  imageCard: null,
  title: '',
  initialDate: '',
  finalDate: '',
  description: '',
  workload: '',
};

CertificateItem.propTypes = {
  editAction: PropTypes.func,
  downloadAction: PropTypes.func,
  deleteAction: PropTypes.func,
  imageCard: PropTypes.string,
  title: PropTypes.string,
  initialDate: PropTypes.string,
  finalDate: PropTypes.string,
  description: PropTypes.string,
  workload: PropTypes.string,
};
