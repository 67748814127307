/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  testContent: null,
  allTests: [],
};

export default function period(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.STUDY_CONTENT_REQUEST_SUCCESS: {
        draft.testContent = action.payload.testContent;
        break;
      }
      case types.STUDY_ALL_TESTS_REQUEST_SUCCESS: {
        draft.allTests = action.payload.allTests;
        break;
      }
      default:
    }
  });
}
