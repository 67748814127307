import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import { searchRequestSuccess } from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* request() {
  const { search } = yield select();
  // Start loading
  yield put(startLoader());
  try {
    const { data } = yield call(
      api.get,
      `search/general?search=${search.filter}`
    );
    yield put(searchRequestSuccess(data));
    // Stop loading
    yield put(stopLoader());
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.search.request.error'));
  }
}

export default all([takeLatest(types.SEARCH_REQUEST, request)]);
