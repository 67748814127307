import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';

import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoginBoxTitle = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  color: ${colors.white};
  letter-spacing: 1px;
  margin: 80px 0 50px 0;
`;

export const ButtonLogin = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    marginTop: 30,
    marginBottom: 30,
  },
})``;

export const LinkForgot = styled(RouterLink)`
  text-decoration: none;
  color: ${colors.white};
  text-align: right;
  font-size: 14px;
  font-weight: 300;
  &:hover {
    text-decoration: none;
    color: ${colors.white};
  }
`;

export const RegisterBox = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  color: ${colors.white};
`;

export const LinkRegister = styled(RouterLink)`
  text-decoration: none;
  color: ${colors.secondary};
  margin-left: 5px;
  font-size: 14px;
  font-weight: 300;
  &:hover {
    text-decoration: none;
    color: ${colors.secondary};
  }
`;

// form login
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
`;

export const UnformInput = styled(UnformInputComponent)``;
