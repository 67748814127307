/* eslint-disable no-async-promise-executor */
import React, { useEffect } from 'react';
import { MdMessage } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Ws from '@adonisjs/websocket-client';

import ModalChat from './components/ModalChat';

import {
  chatChannelsRequest,
  chatChannelsRequestSuccess,
  chatMessageRequestSuccess,
  chatSetChannelSelected,
  chatAddNewMessage,
  chatSetModalStatus,
  updateChannelInList,
} from '~/store/modules/chat/actions';
import config from '~/config';

import { Container } from './styles';

const ws = Ws(config.API_WS);
ws.connect();
const interactionWS = {};

export default function ChatBottom() {
  const dispatch = useDispatch();
  const { channels, chatModalStatus } = useSelector(state => state.chat);
  const { profile } = useSelector(state => state.user);

  const connectOnChannels = async () => {
    channels.forEach(async item => {
      if (!interactionWS[item.id]) {
        interactionWS[item.id] = await ws.subscribe(`friendChat:${item.id}`);
        interactionWS[item.id].on('new:message', message => {
          dispatch(chatAddNewMessage(message));
        });
        interactionWS[item.id].on('update:channel', async message => {
          dispatch(updateChannelInList(message));
        });
      }
    });
  };

  const getNonReadMessages = () => {
    let totalMessages = 0;
    channels.forEach(async item => {
      if (profile.id === item.user1_id) {
        totalMessages += item.unread_user1;
      }
      if (profile.id === item.user2_id) {
        totalMessages += item.unread_user2;
      }
    });
    return totalMessages;
  };

  useEffect(() => {
    dispatch(chatChannelsRequestSuccess([]));
    dispatch(chatMessageRequestSuccess([]));
    dispatch(chatSetChannelSelected(null));
    dispatch(chatChannelsRequest());
    return async () => {
      Object.keys(interactionWS).forEach(async item => {
        if (interactionWS[item]) {
          await ws.removeSubscription(interactionWS[item]);
          await interactionWS[item].close();
          delete interactionWS[item];
        }
      });
    };
  }, []);

  useEffect(() => {
    if (channels.length > 0) {
      connectOnChannels();
    }
  }, [channels]);

  const renderButton = () => {
    const totalMessages = getNonReadMessages();
    return (
      <Container
        onClick={() => {
          dispatch(chatSetModalStatus(true));
        }}
      >
        <MdMessage />
        {totalMessages > 0 && <span className="badge">{totalMessages}</span>}
      </Container>
    );
  };

  const renderModal = () => (
    <ModalChat
      onClose={() => {
        dispatch(chatSetModalStatus(false));
      }}
    />
  );

  return (
    <>
      {renderButton()}
      {chatModalStatus && renderModal()}
    </>
  );
}
