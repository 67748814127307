/* eslint-disable no-console */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import {
  chatChannelsRequestSuccess,
  chatMessageRequestSuccess,
  chatSetChannelSelected,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* requestChannels() {
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'userchannels');
    // Stop loading
    yield put(stopLoader());

    yield put(chatChannelsRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.chat.request-channels.error'));
  }
}

export function* sendMessage({ payload }) {
  try {
    const { messageToSend } = payload;
    yield call(api.post, 'userchannels', messageToSend);
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.chat.send-message.error'));
  }
}

export function* requestMessages({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { channelId } = payload;

    const response = yield call(api.get, `userchannels/${channelId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(chatMessageRequestSuccess(response.data));
    yield put(chatSetChannelSelected(channelId));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.chat.request-messages.error'));
  }
}

export function* setRead({ payload }) {
  try {
    const { userChannelId } = payload;
    yield call(api.post, 'userchannels/set-read', { userChannelId });
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
  }
}

export default all([
  takeLatest(types.CHAT_CHANNELS_REQUEST, requestChannels),
  takeLatest(types.CHAT_SEND_MESSAGE, sendMessage),
  takeLatest(types.CHAT_MESSAGE_REQUEST, requestMessages),
  takeLatest(types.CHAT_CHANNELS_SET_READ_REQUEST, setRead),
]);
