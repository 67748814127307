import React, { useRef } from 'react';
import * as Yup from 'yup';
import { FaSignature } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';

import {
  createMindmapRequest,
  updateMindmapRequest,
} from '~/store/modules/class/actions';
import { getValidationErrors } from '~/utils';

import { Unform, UnformInput, ButtonSave } from './styles';

export default function ModalMindmaps({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { classe, mindmapForm } = useSelector(state => state.classe);

  const schema = Yup.object().shape({
    title: Yup.string().required(
      t('class-view.modal-mindmaps.field.title.message.required')
    ),
  });

  const handleCreate = async ({ title }) => {
    dispatch(
      createMindmapRequest({
        class_id: classe.id,
        title,
        content: null,
      })
    );
    // formRef.current.reset();
    // onClose();
  };

  const handleUpdate = async ({ title }) => {
    dispatch(
      updateMindmapRequest({
        class_id: classe.id,
        title,
        mindmap_id: mindmapForm.id,
        content: mindmapForm.content,
      })
    );
    // onClose();
  };

  return (
    <Modal
      visible
      width="650px"
      height="auto"
      effect="fadeInUp"
      title={t('class-view.modal-mindmaps.title')}
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            if (mindmapForm.id) {
              handleUpdate(data);
            } else {
              handleCreate(data);
            }
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={mindmapForm}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('class-view.modal-mindmaps.field.title.label')}
          name="title"
          autoFocus
          style={{ marginRight: 20 }}
        />
        <ButtonSave type="submit" title={t('class-view.modal-mindmaps.save')} />
      </Unform>
    </Modal>
  );
}

ModalMindmaps.propTypes = {
  onClose: PropTypes.func.isRequired,
};
