import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  max-height: 40px;

  .container-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    height: 40px;
    max-height: 40px;
    padding: 5px;
    .toolbar-menu-item {
      width: 30px;
      height: 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${colors.primary};
      border: 1px solid ${colors.primary};
      border-radius: 10px;
      margin-right: 5px;
      svg {
        color: ${colors.white};
        font-size: 15px;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .container-actions {
    display: flex;
    flex-direction: row;
    height: 40px;
    max-height: 40px;
    align-items: center;
    padding: 0 5px;
  }

  .bm-toolbar-item {
    position: relative;
    padding: 6px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 24px;
    display: inline-block;
  }

  .bm-toolbar-item-disabled {
    color: grey;
  }
  .bm-toolbar-item-disabled:hover {
    color: grey !important;
  }

  .bm-toolbar-item:hover {
    color: ${colors.primary};
  }

  .bm-theme-item {
    cursor: pointer;
    padding: 10px;
  }

  .bm-toolbar .iconfont {
    font-size: 28px !important;
  }

  .bm-theme-img {
    width: 500px;
    height: 125px;
    border: #333333 1px solid;
  }

  .bm-theme-img:hover {
    border: rgb(44, 184, 146) 2px solid;
  }

  .bm-toolbar-popover-target {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
  }
`;
