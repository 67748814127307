/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react';
import * as Yup from 'yup';
import {
  FaSignature,
  FaCalendar,
  FaTimes,
  FaPen,
  FaTrash,
} from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';
import Table from '~/components/Table';
import Route from '~/services/history';
import HasPermission from '~/components/HasPermission';

import {
  ModalBody,
  ListBox,
  ClearButton,
  Unform,
  UnformInput,
  UnformInputMask,
  ButtonSave,
} from './styles';

import {
  createTestRequest,
  updateTestRequest,
  deleteTestRequest,
  subjectTestSet,
} from '~/store/modules/subject/actions';

import { getValidationErrors } from '~/utils';

export default function ModalTests({ onClose, subjectId }) {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { tests, testForm } = useSelector(state => state.subject);

  const schema = Yup.object().shape({
    description: Yup.string().required(
      t('classes.modal-tests.field.description.message.required')
    ),
    date: Yup.string().required(
      t('classes.modal-tests.field.date.message.required')
    ),
  });

  const handleCreate = async ({ description, date }) => {
    dispatch(
      createTestRequest({
        subject_id: subjectId,
        description,
        date: moment(date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:00'),
      })
    );
    formRef.current.reset();
  };

  const handleUpdate = async ({ description, date }) => {
    dispatch(
      updateTestRequest({
        subject_id: subjectId,
        description,
        date: moment(date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:00'),
        test_id: testForm.id,
      })
    );
  };

  const handleDelete = test => {
    confirmAlert({
      title: t('classes.modal-tests.confirm-alert.delete-test.title'),
      message: t('classes.modal-tests.confirm-alert.delete-test.message'),
      buttons: [
        {
          label: t(
            'classes.modal-tests.confirm-alert.delete-test.buttons.cancel'
          ),
        },
        {
          label: t('classes.modal-tests.confirm-alert.delete-test.buttons.yes'),
          onClick: () => {
            dispatch(deleteTestRequest(test));
          },
        },
      ],
    });
  };

  const renderForm = () => (
    <>
      {!testForm && (
        <Unform
          onSubmit={async data => {
            try {
              formRef.current.setErrors({});
              await schema.validate(data, { abortEarly: false });
              handleCreate(data);
            } catch (err) {
              formRef.current.setErrors(getValidationErrors(err));
            }
          }}
          initialData={{}}
          ref={formRef}
        >
          <UnformInput
            icon={FaSignature}
            type="text"
            label={t('classes.modal-tests.field.description.label')}
            name="description"
            autoFocus
            style={{ marginRight: 20 }}
          />
          <UnformInputMask
            icon={FaCalendar}
            type="text"
            label={t('classes.modal-tests.field.date.label')}
            name="date"
            mask="99/99/9999 99:99"
            style={{ marginRight: 20 }}
          />
          <ButtonSave type="submit" title={t('classes.modal-tests.add')} />
        </Unform>
      )}
      {!!testForm && (
        <Unform
          onSubmit={async data => {
            try {
              formRef.current.setErrors({});
              await schema.validate(data, { abortEarly: false });
              handleUpdate(data);
            } catch (err) {
              formRef.current.setErrors(getValidationErrors(err));
            }
          }}
          initialData={testForm}
          ref={formRef}
        >
          <UnformInput
            icon={FaSignature}
            type="text"
            label={t('classes.modal-tests.field.description.label')}
            name="description"
            autoFocus
            style={{ marginRight: 20 }}
          />
          <UnformInputMask
            icon={FaCalendar}
            type="text"
            label={t('classes.modal-tests.field.date.label')}
            name="date"
            mask="99/99/9999 99:99"
            style={{ marginRight: 20 }}
          />
          <ClearButton
            onClick={() => {
              dispatch(subjectTestSet(null));
            }}
          >
            <FaTimes />
          </ClearButton>
          <ButtonSave type="submit" title={t('classes.modal-tests.save')} />
        </Unform>
      )}
    </>
  );

  const renderBody = () => (
    <ModalBody>
      <ListBox>
        <Table>
          <tbody>
            {tests.length === 0 && (
              <tr>
                <td align="center">{t('classes.modal-tests.empty')}</td>
              </tr>
            )}
            {tests.length >= 0 &&
              tests.map(item => (
                <tr key={item.id}>
                  <td
                    width="50%"
                    style={{ cursor: 'pointer' }}
                    onClick={() => Route.replace(`/study-content/${item.id}`)}
                  >
                    {item.description}
                  </td>
                  <td
                    width="50%"
                    style={{ cursor: 'pointer' }}
                    onClick={() => Route.replace(`/study-content/${item.id}`)}
                  >
                    {item.date}
                  </td>
                  <HasPermission>
                    <td width="1%">
                      <div className="table-action">
                        <button
                          type="button"
                          className="table-action-edit"
                          onClick={() => {
                            dispatch(subjectTestSet(item));
                          }}
                        >
                          <FaPen />
                        </button>
                        <button
                          type="button"
                          className="table-action-delete"
                          onClick={() => {
                            handleDelete(item);
                          }}
                        >
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </HasPermission>
                </tr>
              ))}
          </tbody>
        </Table>
      </ListBox>
    </ModalBody>
  );

  return (
    <Modal
      visible
      width="650px"
      height="auto"
      effect="fadeInUp"
      title={t('classes.modal-tests.title')}
      onClose={() => {
        onClose();
      }}
    >
      <>
        <HasPermission>{renderForm()}</HasPermission>
        {renderBody()}
      </>
    </Modal>
  );
}

ModalTests.propTypes = {
  onClose: PropTypes.func.isRequired,
  subjectId: PropTypes.number.isRequired,
};
