/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Proptypes from 'prop-types';
import { browserOpenFile } from '@blink-mind/renderer-react';
import { FaRegFolderOpen } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ToolbarItemOpen = ({ diagram }) => {
  const { t } = useTranslation();
  const onClickOpenFile = () => {
    const diagramProps = diagram.getDiagramProps();
    const { controller } = diagramProps;
    browserOpenFile('.json,.blinkmind,.bm').then(txt => {
      const obj = JSON.parse(txt);
      const model = controller.run('deserializeModel', { controller, obj });
      diagram.openNewModel(model);
    });
  };
  return (
    <button
      type="button"
      className="toolbar-menu-item"
      onClick={onClickOpenFile}
      data-tip={t('class-view.modal-mindmaps.toolbar.buttonOpen')}
    >
      <FaRegFolderOpen />
    </button>
  );
};

ToolbarItemOpen.propTypes = {
  diagram: Proptypes.shape().isRequired,
};

export default ToolbarItemOpen;
