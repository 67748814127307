import types from './types';

export function chatChannelsRequest() {
  return {
    type: types.CHAT_CHANNELS_REQUEST,
  };
}

export function chatChannelsRequestSuccess(channels) {
  return {
    type: types.CHAT_CHANNELS_REQUEST_SUCCESS,
    payload: { channels },
  };
}

export function chatSendMessage(messageToSend) {
  return {
    type: types.CHAT_SEND_MESSAGE,
    payload: { messageToSend },
  };
}

export function chatMessageRequest(channelId) {
  return {
    type: types.CHAT_MESSAGE_REQUEST,
    payload: { channelId },
  };
}

export function chatAddNewMessage(message) {
  return {
    type: types.CHAT_ADD_NEW_MESSAGE,
    payload: { message },
  };
}

export function chatMessageRequestSuccess(messages) {
  return {
    type: types.CHAT_MESSAGE_REQUEST_SUCCESS,
    payload: { messages },
  };
}

export function chatSetChannelSelected(channelSelectedId) {
  return {
    type: types.CHAT_SET_CHANNEL_SELECTED,
    payload: { channelSelectedId },
  };
}

export function chatSetModalStatus(chatModalStatus) {
  return {
    type: types.CHAT_SET_MODAL_STATUS,
    payload: { chatModalStatus },
  };
}

export function updateChannelInList(channelToUpdate) {
  return {
    type: types.UPDATE_CHANNEL_IN_LIST,
    payload: { channelToUpdate },
  };
}

export function chatChannelsSetReadRequest(userChannelId) {
  return {
    type: types.CHAT_CHANNELS_SET_READ_REQUEST,
    payload: { userChannelId },
  };
}
