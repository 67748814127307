import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';

import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const ButtonSave = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    marginTop: 30,
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
`;

export const UnformInput = styled(UnformInputComponent)``;
