import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import { FaSignature, FaCalendar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import {
  agendaSetModalCreate,
  createAgendaRequest,
} from '~/store/modules/agenda/actions';
import { getValidationErrors } from '~/utils';

import Modal from '~/components/Modal';
import {
  SaveButton,
  Unform,
  UnformInput,
  UnformInputMask,
  UnformTextarea,
} from './styles';

export default function ModalCreate({ initialDate }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    title: Yup.string().required(
      t('agenda.modal-create.field.title.message.required')
    ),
    description: Yup.string().required(
      t('agenda.modal-create.field.description.message.required')
    ),
    initial_date: Yup.string().required(
      t('agenda.modal-create.field.initial-date.message.required')
    ),
  });

  const handleSubmit = event => {
    dispatch(
      createAgendaRequest({
        ...event,
        initial_date: moment(event.initial_date, 'DD/MM/YYYY HH:mm').format(
          'YYYY-MM-DD HH:mm:00Z'
        ),
      })
    );
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('agenda.modal-create.title')}
      onClose={() => {
        dispatch(agendaSetModalCreate(false));
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{
          title: '',
          description: '',
          initial_date: initialDate,
        }}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('agenda.modal-create.field.title.label')}
          name="title"
          autoFocus
          style={{ marginBottom: 20 }}
        />
        <UnformInputMask
          icon={FaCalendar}
          type="text"
          label={t('agenda.modal-create.field.initial-date.label')}
          name="initial_date"
          mask="99/99/9999 99:99"
          style={{ marginBottom: 20 }}
        />
        <UnformTextarea
          icon={FaSignature}
          type="text"
          label={t('agenda.modal-create.field.description.label')}
          name="description"
          style={{ marginBottom: 20 }}
        />
        <SaveButton
          type="submit"
          title={t('agenda.modal-create.save')}
          gradient
        />
      </Unform>
    </Modal>
  );
}

ModalCreate.defaultProps = {};

ModalCreate.propTypes = {
  initialDate: PropTypes.string.isRequired,
};
