export default {
  // Class
  CLASSES_REQUEST: '@class/CLASSES_REQUEST',
  CLASSES_REQUEST_SUCCESS: '@class/CLASSES_REQUEST_SUCCESS',
  DELETE_CLASS_REQUEST: '@class/DELETE_CLASS_REQUEST',
  CLASS_REQUEST: '@class/CLASS_REQUEST',
  CLASS_REQUEST_SUCCESS: '@class/CLASS_REQUEST_SUCCESS',
  UPDATE_CLASS_REQUEST: '@class/UPDATE_CLASS_REQUEST',
  CLASS_SET_EDIT: '@class/CLASS_SET_EDIT',
  CLASS_SET_MODAL_CREATE: '@class/CLASS_SET_MODAL_CREATE',
  CREATE_CLASS_REQUEST: '@class/CREATE_CLASS_REQUEST',
  // Question
  QUESTION_SET: '@class/QUESTION_SET',
  QUESTIONS_REQUEST: '@class/QUESTIONS_REQUEST',
  QUESTIONS_REQUEST_SUCCESS: '@class/QUESTIONS_REQUEST_SUCCESS',
  CREATE_QUESTION_REQUEST: '@class/CREATE_QUESTION_REQUEST',
  UPDATE_QUESTION_REQUEST: '@class/UPDATE_QUESTION_REQUEST',
  DELETE_QUESTION_REQUEST: '@class/DELETE_QUESTION_REQUEST',
  // Keyword
  KEYWORD_SET: '@class/KEYWORD_SET',
  KEYWORDS_REQUEST: '@class/KEYWORDS_REQUEST',
  KEYWORDS_REQUEST_SUCCESS: '@class/KEYWORDS_REQUEST_SUCCESS',
  CREATE_KEYWORD_REQUEST: '@class/CREATE_KEYWORD_REQUEST',
  UPDATE_KEYWORD_REQUEST: '@class/UPDATE_KEYWORD_REQUEST',
  DELETE_KEYWORD_REQUEST: '@class/DELETE_KEYWORD_REQUEST',
  // Files
  FILES_REQUEST: '@class/FILES_REQUEST',
  FILES_REQUEST_SUCCESS: '@class/FILES_REQUEST_SUCCESS',
  FILES_SET_CREATE: '@class/FILES_SET_CREATE',
  FILES_CREATE: '@class/FILES_CREATE',
  FILES_UPDATE: '@class/FILES_UPDATE',
  FILES_SET_UPDATE: '@class/FILES_SET_UPDATE',
  FILES_DELETE: '@class/FILES_DELETE',
  // Mindmap
  MINDMAP_SET: '@class/MINDMAP_SET',
  MINDMAP_EDITOR_SET: '@class/MINDMAP_EDITOR_SET',
  MINDMAP_REQUEST: '@class/MINDMAP_REQUEST',
  MINDMAP_REQUEST_SUCCESS: '@class/MINDMAP_REQUEST_SUCCESS',
  CREATE_MINDMAP_REQUEST: '@class/CREATE_MINDMAP_REQUEST',
  UPDATE_MINDMAP_REQUEST: '@class/UPDATE_MINDMAP_REQUEST',
  DELETE_MINDMAP_REQUEST: '@class/DELETE_MINDMAP_REQUEST',
};
