import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FaSignature, FaCalendar, FaFile, FaClock } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import {
  certificateSetModalCreate,
  createCertificateRequest,
} from '~/store/modules/certificate/actions';
import { getValidationErrors } from '~/utils';

import Modal from '~/components/Modal';
import {
  Row,
  SaveButton,
  Unform,
  UnformInput,
  UnformInputMask,
  UnformInputFile,
  UnformTextarea,
} from './styles';

export default function ModalCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    file_name: Yup.string().required(
      t('certificates.modal-new.field.file-name.message.required')
    ),
    title: Yup.string().required(
      t('certificates.modal-new.field.title.message.required')
    ),
    description: Yup.string(),
    workload: Yup.string(),
    initial_date: Yup.string().required(
      t('certificates.modal-new.field.initial-date.message.required')
    ),
    final_date: Yup.string().required(
      t('certificates.modal-new.field.final-date.message.required')
    ),
  });

  const handleSubmit = certificate => {
    dispatch(createCertificateRequest(certificate));
  };

  return (
    <Modal
      visible
      width="550px"
      height="auto"
      effect="fadeInUp"
      title={t('certificates.modal-new.title')}
      onClose={() => {
        dispatch(certificateSetModalCreate(false));
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formRef}
      >
        <UnformInputFile
          icon={FaFile}
          name="file_name"
          accept="application/pdf,image/jpeg,image/png"
          title={t('certificates.modal-new.field.file-name.label')}
          style={{ marginBottom: 20 }}
          maxSize={5000000}
          changeName
        />
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('certificates.modal-new.field.title.label')}
          name="title"
          autoFocus
          style={{ marginBottom: 20 }}
        />
        <UnformTextarea
          icon={FaSignature}
          type="text"
          label={t('certificates.modal-new.field.description.label')}
          name="description"
          style={{ marginBottom: 20 }}
        />
        <Row>
          <UnformInputMask
            icon={FaCalendar}
            type="text"
            label={t('certificates.modal-new.field.initial-date.label')}
            name="initial_date"
            mask="99/99/9999"
            style={{ marginRight: 20 }}
          />
          <UnformInputMask
            icon={FaCalendar}
            type="text"
            label={t('certificates.modal-new.field.final-date.label')}
            name="final_date"
            mask="99/99/9999"
            style={{ marginRight: 20 }}
          />
          <UnformInput
            icon={FaClock}
            type="number"
            label={t('certificates.modal-new.field.workload.label')}
            name="workload"
          />
        </Row>
        <SaveButton
          type="submit"
          title={t('certificates.modal-new.save')}
          gradient
        />
      </Unform>
    </Modal>
  );
}
