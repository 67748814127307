/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaSave } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';
import SettingItem from './components/SettingItem';
import ModalCreate from './components/ModalCreate';
import ModalEdit from './components/ModalEdit';
import colors from '~/styles/colors';
import Tour from '~/components/Tour';
import TourButton from '~/components/TourButton';
import HasPermission from '~/components/HasPermission';

import {
  notificationConfigRequest,
  notificationConfigSave,
} from '~/store/modules/user/actions';
import { tourSetState } from '~/store/modules/tour/actions';

import { Content, HeaderButton } from './styles';

const Settings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { notificationConfig } = useSelector(state => state.user);
  const { showedSettings } = useSelector(state => state.tour);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [settingDataToEdit, setSettingDataToEdit] = useState(null);
  const [settingPosToEdit, setSettingPosToEdit] = useState(null);
  const [settingType, setSettingType] = useState('');
  const [userSetting, setUserSetting] = useState(null);
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    { selector: '#settings-tests', content: t('tour.settings.tests') },
    { selector: '#settings-concepts', content: t('tour.settings.concepts') },
    { selector: '#settings-works', content: t('tour.settings.works') },
    { selector: '#settings-events', content: t('tour.settings.events') },
    { selector: '#settings-save', content: t('tour.settings.save') },
  ];

  useEffect(() => {
    dispatch(notificationConfigRequest());
    if (!showedSettings) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  useEffect(() => {
    if (notificationConfig) {
      setUserSetting(notificationConfig);
    }
  }, [notificationConfig]);

  const handleDeleteSetting = (setting, pos) => {
    confirmAlert({
      title: t('settings.confirm-alert.delete.title'),
      message: t('settings.confirm-alert.delete.message'),
      buttons: [
        {
          label: t('settings.confirm-alert.delete.buttons.cancel'),
        },
        {
          label: t('settings.confirm-alert.delete.buttons.yes'),
          onClick: () => {
            const joinSetting = {
              ...userSetting,
            };
            joinSetting[setting] = userSetting[setting].filter(
              (_item, index) => index !== pos
            );
            setUserSetting(joinSetting);
          },
        },
      ],
    });
  };

  const renderHeader = () => {
    return (
      <PageHeader
        breadcrumb={[t('settings.breadcrumb.1')]}
        actionElements={() => (
          <>
            <TourButton onClick={() => setTurIsOpen(true)} />
            <HasPermission>
              <HeaderButton
                id="settings-save"
                title={t('settings.save-config')}
                icon={FaSave}
                onClick={() => {
                  dispatch(notificationConfigSave(userSetting));
                }}
                color={colors.secondary}
              />
            </HasPermission>
          </>
        )}
      />
    );
  };

  const renderContent = () => (
    <Content>
      <SettingItem
        id="settings-tests"
        title={t('settings.tests.title')}
        subtitle={t('settings.tests.subtitle')}
        settings={userSetting.tests || []}
        onNew={() => {
          setSettingType('tests');
          setOpenModalCreate(true);
        }}
        onEdit={pos => {
          setSettingType('tests');
          setSettingPosToEdit(pos);
          setSettingDataToEdit(userSetting.tests[pos]);
          setOpenModalEdit(true);
        }}
        onRemove={pos => {
          handleDeleteSetting('tests', pos);
        }}
      />
      <SettingItem
        id="settings-concepts"
        title={t('settings.concepts.title')}
        subtitle={t('settings.concepts.subtitle')}
        settings={userSetting.concepts || []}
        onNew={() => {
          setSettingType('concepts');
          setOpenModalCreate(true);
        }}
        onEdit={pos => {
          setSettingType('concepts');
          setSettingPosToEdit(pos);
          setSettingDataToEdit(userSetting.concepts[pos]);
          setOpenModalEdit(true);
        }}
        onRemove={pos => {
          handleDeleteSetting('concepts', pos);
        }}
      />
      <SettingItem
        id="settings-works"
        title={t('settings.works.title')}
        subtitle={t('settings.works.subtitle')}
        settings={userSetting.works || []}
        onNew={() => {
          setSettingType('works');
          setOpenModalCreate(true);
        }}
        onEdit={pos => {
          setSettingType('works');
          setSettingPosToEdit(pos);
          setSettingDataToEdit(userSetting.works[pos]);
          setOpenModalEdit(true);
        }}
        onRemove={pos => {
          handleDeleteSetting('works', pos);
        }}
      />
      <SettingItem
        id="settings-events"
        title={t('settings.events.title')}
        subtitle={t('settings.events.subtitle')}
        settings={userSetting.events || []}
        onNew={() => {
          setSettingType('events');
          setOpenModalCreate(true);
        }}
        onEdit={pos => {
          setSettingType('events');
          setSettingPosToEdit(pos);
          setSettingDataToEdit(userSetting.events[pos]);
          setOpenModalEdit(true);
        }}
        onRemove={pos => {
          handleDeleteSetting('events', pos);
        }}
      />
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {!!userSetting && renderContent()}
        {openModalCreate && (
          <ModalCreate
            onCLose={() => {
              setOpenModalCreate(false);
              setSettingType('');
            }}
            onSubmit={data => {
              const joinSetting = {
                ...userSetting,
              };
              joinSetting[settingType] = [
                ...(userSetting[settingType] || []),
                {
                  hour: data.hour,
                  periods: data.periods.map(period => parseInt(period, 10)),
                },
              ];
              setUserSetting(joinSetting);
              setOpenModalCreate(false);
              setSettingType('');
            }}
          />
        )}
        {openModalEdit && (
          <ModalEdit
            onCLose={() => {
              setSettingType('');
              setSettingPosToEdit(null);
              setSettingDataToEdit(null);
              setOpenModalEdit(false);
            }}
            onSubmit={data => {
              const joinSetting = {
                ...userSetting,
              };
              joinSetting[settingType] = userSetting[settingType].map(
                (item, index) => {
                  if (index === settingPosToEdit) {
                    return {
                      hour: data.hour,
                      periods: data.periods.map(period => parseInt(period, 10)),
                    };
                  }
                  return item;
                }
              );
              setUserSetting(joinSetting);
              setSettingType('');
              setSettingPosToEdit(null);
              setSettingDataToEdit(null);
              setOpenModalEdit(false);
            }}
            editItem={settingDataToEdit}
          />
        )}
        <Tour
          steps={tourSteps}
          isOpen={turIsOpen}
          onRequestClose={() => setTurIsOpen(false)}
          closeWithMask={false}
          startAt={0}
          onClose={() => {
            dispatch(tourSetState({ showedSettings: true }));
          }}
        />
      </PageContent>
    </>
  );
};

export default Settings;
