import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FaPlus, FaPenNib } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import {
  NewSubject,
  HeaderNewSubject,
  Content,
  SubjectList,
  SubjectBoxButton,
} from './styles';

import SearchBox from '~/components/SearchBox';
import iconSubjects from '~/assets/images/icons/subjects.svg';
import PageContent from '~/components/PageContent';
import Tour from '~/components/Tour';
import TourButton from '~/components/TourButton';
import HasPermission from '~/components/HasPermission';

import CardBox from '~/components/CardBox';
import ModalEdit from './components/ModalEdit';
import ModalCreate from './components/ModalCreate';
import ModalShare from './components/ModalShare';
import ModalExportClasses from './components/ModalExportClasses';

import Route from '~/services/history';
import PageHeader from '~/components/PageHeader';

import {
  subjectRequest,
  subjectSetEdit,
  subjectSetModalCreate,
  deleteSubjectRequest,
} from '~/store/modules/subject/actions';
import { tourSetState } from '~/store/modules/tour/actions';

const Subjects = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { subjects, subjectEdit, subjectModalCreate } = useSelector(
    state => state.subject
  );
  const { showedSubjects } = useSelector(state => state.tour);

  const { periodId } = match.params;
  const [searchValue, setSearchValue] = useState('');
  const [subjectToShare, setSubjectToShare] = useState('');
  const [modalShareOpened, setModalShareOpened] = useState(false);
  const [subjectToExport, setSubjectToExport] = useState(null);
  const [turIsOpen, setTurIsOpen] = useState(false);
  const tourSteps = [
    { selector: '#subject-new', content: t('tour.subjects.new') },
    // { selector: '#subject-search', content: t('tour.subjects.search') },
  ];

  useEffect(() => {
    dispatch(subjectRequest(periodId));
    if (!showedSubjects) setTimeout(() => setTurIsOpen(true), 500);
  }, []);

  const handleDeleteSubject = subject => {
    confirmAlert({
      title: t('subjects.confirm-alert.delete-subject.title'),
      message: t('subjects.confirm-alert.delete-subject.message'),
      buttons: [
        {
          label: t('subjects.confirm-alert.delete-subject.buttons.cancel'),
        },
        {
          label: t('subjects.confirm-alert.delete-subject.buttons.yes'),
          onClick: () => {
            dispatch(deleteSubjectRequest(subject));
          },
        },
      ],
    });
  };

  const renderHeader = () => (
    <PageHeader
      onBack={() => {
        Route.replace('/periods');
      }}
      breadcrumb={[t('subjects.breadcrumb.1'), t('subjects.breadcrumb.2')]}
      actionElements={() => (
        <>
          <TourButton onClick={() => setTurIsOpen(true)} />
          <HasPermission>
            <HeaderNewSubject
              id="subject-new"
              title={t('subjects.new-subject')}
              icon={FaPlus}
              onClick={() => {
                dispatch(subjectSetModalCreate(true));
              }}
            />
          </HasPermission>
        </>
      )}
    />
  );

  const renderSearchBox = () => (
    <SearchBox
      id="subject-search"
      placeholder={t('subjects.search')}
      value={searchValue}
      onChange={val => setSearchValue(val)}
    />
  );

  const renderSubjects = () => {
    const subjectList = subjects.filter(subject => {
      const subjectDescription = subject.description.toUpperCase();
      const subjectFilter = searchValue.toUpperCase();
      return subjectDescription.includes(subjectFilter);
    });
    return (
      <SubjectList
        justifyContent={subjects.length > 3 ? 'space-between' : 'unset'}
      >
        {subjects.length > 0 ? (
          subjectList.map(item => (
            <CardBox
              key={item.id}
              shareAction={() => {
                setSubjectToShare(item.id);
                setModalShareOpened(true);
              }}
              deleteAction={() => {
                handleDeleteSubject(item);
              }}
              editAction={() => {
                dispatch(subjectSetEdit(item));
              }}
              exportPdfAction={() => {
                setSubjectToExport(item.id);
              }}
              sharedBy={item.userShared ? item.userShared.name : ''}
              imageCard={iconSubjects}
              width={250}
            >
              <div className="card-box-title">{item.description}</div>
              <div className="card-box-subtitle">
                {t('subjects.card-item.created-at', { date: item.created_at })}
              </div>
              <SubjectBoxButton
                title={t('subjects.card-item.classes')}
                icon={FaPenNib}
                onClick={() => {
                  Route.replace(`/classes/${periodId}/${item.id}`);
                }}
              />
            </CardBox>
          ))
        ) : (
          <NewSubject
            onClick={() => {
              dispatch(subjectSetModalCreate(true));
            }}
          >
            <FaPlus />
            {t('subjects.new-subject')}
          </NewSubject>
        )}
        {}
      </SubjectList>
    );
  };

  const renderContent = () => (
    <Content>
      {renderSearchBox()}
      {renderSubjects()}
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>
        {renderContent()}
        {subjectEdit && <ModalEdit />}
        {subjectModalCreate && <ModalCreate periodId={periodId} />}
        {modalShareOpened && (
          <ModalShare
            subjectToShare={subjectToShare}
            onClose={() => {
              setSubjectToShare('');
              setModalShareOpened(false);
            }}
          />
        )}
        {!!subjectToExport && (
          <ModalExportClasses
            subjectId={subjectToExport}
            onClose={() => {
              setSubjectToExport(null);
            }}
          />
        )}
        <Tour
          steps={tourSteps}
          isOpen={turIsOpen}
          onRequestClose={() => setTurIsOpen(false)}
          closeWithMask={false}
          startAt={0}
          onClose={() => {
            dispatch(tourSetState({ showedSubjects: true }));
          }}
        />
      </PageContent>
    </>
  );
};

Subjects.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      periodId: PropTypes.string,
    }),
  }).isRequired,
};

export default Subjects;
