import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes, FaSearch } from 'react-icons/fa';

import { Container, Search, Clear } from './styles';

export default function SearchBox({
  placeholder,
  value,
  onChange,
  className,
  autoFocus,
  id,
}) {
  return (
    <Container className={className} id={id}>
      <Search
        placeholder={placeholder}
        value={value}
        className="field-search"
        onChange={e => {
          onChange(e.target.value);
        }}
        autoFocus={autoFocus}
        type="text"
        autoComplete="new-password"
      />
      <FaSearch className="icon-search" />
      {value && (
        <Clear
          type="button"
          onClick={() => onChange('')}
          className="button-clear"
        >
          <FaTimes className="icon-clear" />
        </Clear>
      )}
    </Container>
  );
}

SearchBox.defaultProps = {
  placeholder: 'Buscar...',
  value: '',
  id: '',
  onChange: () => {},
  autoFocus: false,
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
};
