import React from 'react';
import PropTypes from 'prop-types';
import { BiCheck } from 'react-icons/bi';

import { Container } from './styles';

export default function ChatFriendItem({
  friend,
  active,
  onClick,
  lastUpdated,
  badge,
}) {
  return (
    <Container active={active} onClick={onClick}>
      <img
        className="friend-avatar"
        src={friend.avatar_url}
        alt={friend.name}
      />
      <div className="friend-content">
        <div className="friend-name">{friend.name}</div>
        <div className="friend-last-update">
          <BiCheck />
          {lastUpdated}
        </div>
        {badge > 0 && <span className="badge">{badge}</span>}
      </div>
    </Container>
  );
}

ChatFriendItem.defaultProps = {
  friend: null,
  active: false,
  onClick: () => {},
  lastUpdated: '',
  badge: 0,
};

ChatFriendItem.propTypes = {
  friend: PropTypes.shape(),
  active: PropTypes.bool,
  onClick: PropTypes.func,
  lastUpdated: PropTypes.string,
  badge: PropTypes.number,
};
