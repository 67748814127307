/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container, Checkbox } from './styles';

const CheckboxComponent = ({
  name,
  label,
  className,
  disabled,
  style,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <Container className={className} disabled={disabled} style={style}>
      <Checkbox
        id={fieldName}
        {...rest}
        ref={inputRef}
        defaultChecked={defaultValue}
        name={fieldName}
        disabled={disabled}
        type="checkbox"
      />
      <div className="checklabel">
        {label}
        {error && <span className="error">{error}</span>}
      </div>
      <span className="checkmark" />
    </Container>
  );
};

CheckboxComponent.defaultProps = {
  label: '',
  style: null,
  disabled: false,
};

CheckboxComponent.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
};

export default CheckboxComponent;
