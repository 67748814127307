import React from 'react';
import PropTypes from 'prop-types';
import { FaSave } from 'react-icons/fa';
import { Diagram } from '@blink-mind/renderer-react';
// import RichTextEditorPlugin from '@blink-mind/plugin-rich-text-editor';
import { JsonSerializerPlugin } from '@blink-mind/plugin-json-serializer';
import { /* TopicReferencePlugin, */ SearchPlugin } from '@blink-mind/plugins';
import { ThemeSelectorPlugin } from '../Utils/PluginThemeSelector';
// import TopologyDiagramPlugin from '@blink-mind/plugin-topology-diagram';
import Toolbar from '../Toolbar';
import HasPermission from '~/components/HasPermission';
import { generateSimpleModel, generateModelFromJson } from '../Utils';
import '@blink-mind/renderer-react/lib/main.css';

import i18n from '~/i18n';
import { HeaderAction } from './styles';

const plugins = [
  // RichTextEditorPlugin(),
  ThemeSelectorPlugin(),
  // TopicReferencePlugin(),
  SearchPlugin(),
  // TopologyDiagramPlugin(),
  JsonSerializerPlugin(),
];

class Mindmap extends React.Component {
  diagram;

  constructor(props) {
    super(props);
    this.initModel();
  }

  diagramRef = ref => {
    this.diagram = ref;
    this.setState({});
  };

  onClickUndo = () => {
    const props = this.diagram.getDiagramProps();
    const { controller } = props;
    controller.run('undo', props);
  };

  onClickRedo = () => {
    const props = this.diagram.getDiagramProps();
    const { controller } = props;
    controller.run('redo', props);
  };

  handleChangeData = () => {
    const { onSave } = this.props;
    const diagramProps = this.diagram.getDiagramProps();
    const { controller } = diagramProps;
    const json = controller.run('serializeModel', diagramProps);
    const jsonStr = JSON.stringify(json);
    onSave(jsonStr);
  };

  initModel() {
    const { content } = this.props;
    const model = content
      ? generateModelFromJson(content)
      : generateSimpleModel();
    this.state = { model };
  }

  renderDiagram() {
    const { model } = this.state;
    return (
      <Diagram
        ref={this.diagramRef}
        model={model}
        onChange={newModel => {
          this.setState({ model: newModel });
        }}
        plugins={plugins}
      />
    );
  }

  renderToolbar() {
    const props = this.diagram.getDiagramProps();
    const { controller } = props;
    const canUndo = controller.run('canUndo', props);
    const canRedo = controller.run('canRedo', props);
    const toolbarProps = {
      diagram: this.diagram,
      onClickUndo: this.onClickUndo,
      onClickRedo: this.onClickRedo,
      canUndo,
      canRedo,
    };
    return (
      <Toolbar {...toolbarProps}>
        <HasPermission>
          <HeaderAction
            title={i18n.t('class-view.modal-mindmaps.save')}
            icon={FaSave}
            onClick={this.handleChangeData}
          />
        </HasPermission>
      </Toolbar>
    );
  }

  render() {
    return (
      <div className="mindmap">
        {this.diagram && this.renderToolbar()}
        {this.renderDiagram()}
      </div>
    );
  }
}

Mindmap.propTypes = {
  onSave: PropTypes.func.isRequired,
  content: PropTypes.shape().isRequired,
};

export default Mindmap;
