import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FaCircle } from 'react-icons/fa';

import { Container, ContainerHeader, ContainerBody } from './styles';

export default function ChatMessageItem({ message, itsMe }) {
  return (
    <Container>
      <ContainerHeader itsMe={itsMe}>
        <span className="message-date">
          {moment(message.created_at).format('DD/MM/YYYY HH:mm:ss')}
        </span>
        <span className="message-separator">|</span>
        <span className="message-user">{message.user.name}</span>
        <FaCircle />
      </ContainerHeader>
      <ContainerBody itsMe={itsMe}>{message.message}</ContainerBody>
    </Container>
  );
}

ChatMessageItem.defaultProps = {
  message: null,
  itsMe: false,
};

ChatMessageItem.propTypes = {
  message: PropTypes.shape(),
  itsMe: PropTypes.bool,
};
