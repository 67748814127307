import styled, { css } from 'styled-components';
import ReactInputMask from 'react-input-mask';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-top: 10px;

  span.error {
    color: ${colors.secondary};
    font-size: 13px;
    margin-top: 5px;
  }

  .input-field {
    ${props =>
      props.hasIcon &&
      css`
        padding-left: 25px;
      `}
  }
  .input-label {
    ${props =>
      props.hasIcon &&
      css`
        text-indent: 25px;
      `}
    ${props =>
      props.isFocused &&
      css`
        top: -10px;
        pointer-events: none;
        text-indent: 0;
      `}
  }
  .input-icon {
  }
`;

export const Input = styled(ReactInputMask).attrs({
  className: 'input-field',
})`
  border: 0;
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(
    to right,
    ${colors.secondary},
    ${colors.primary}
  );
  outline: none;
  transition: 0.2s ease-in-out;
  width: 100%;
  height: 50px;
  background-color: transparent;
  color: ${colors.white};
  font-size: 14px;
  font-weight: 400;

  &::placeholder {
    color: ${colors.white};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: 0;
    border-bottom: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(
      to right,
      ${colors.secondary},
      ${colors.primary}
    );
    outline: none;
    -webkit-text-fill-color: ${colors.white};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`;

export const Label = styled.label.attrs({
  className: 'input-label',
})`
  font-size: 14px;
  color: ${colors.white};
  font-weight: 400;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  box-sizing: border-box;
  width: 100%;
  height: 20px;
  position: absolute;
  top: 15px;
  left: 0;
  pointer-events: none;
`;

export const PasswordEye = styled.button`
  background-color: transparent;
  border: 0;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: ${colors.white};
`;

export const WrapIcon = styled.div.attrs({
  className: 'input-icon',
})`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 15px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.white};
  font-size: 16px;
  pointer-events: none;
`;
