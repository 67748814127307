import React, { useEffect } from 'react';
import 'react-accessible-accordion/dist/fancy-example.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { RiCoupon3Line } from 'react-icons/ri';
import colors from '~/styles/colors';

import PageHeader from '~/components/PageHeader';
import PageContent from '~/components/PageContent';

import {
  userPlanRequest,
  planRequest,
  myPlanSetState,
} from '~/store/modules/myPlan/actions';
import ModalNewPlan from './components/ModalNewPlan';
import ModalCoupon from './components/ModalCoupon';
import PlanItem from './components/PlanItem';
import { Content, PlanList, HeaderButton } from './styles';

const MyPlan = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { myPlans, plans, couponForm, lifeLong } = useSelector(
    state => state.myPlan
  );

  useEffect(() => {
    dispatch(userPlanRequest());
  }, []);

  const renderHeader = () => {
    return (
      <PageHeader
        breadcrumb={[t('my-plan.breadcrumb.1')]}
        actionElements={() => (
          <>
            {!lifeLong && (
              <HeaderButton
                type="button"
                title={t('my-plan.use-coupon')}
                icon={RiCoupon3Line}
                onClick={() => {
                  dispatch(
                    myPlanSetState({
                      couponForm: {
                        couponToken: '',
                      },
                    })
                  );
                }}
                color={colors.primary}
              />
            )}
            {!lifeLong && (
              <HeaderButton
                type="button"
                title={
                  myPlans.length === 0
                    ? t('my-plan.get-plan.1')
                    : t('my-plan.get-plan.2')
                }
                icon={AiOutlineShoppingCart}
                onClick={() => {
                  dispatch(planRequest());
                }}
                color={colors.secondary}
              />
            )}
          </>
        )}
      />
    );
  };

  const renderContent = () => (
    <Content>
      <PlanList>
        {myPlans.map(i => (
          <PlanItem userPlan={i} />
        ))}
        <PlanList />
      </PlanList>
    </Content>
  );

  return (
    <>
      {renderHeader()}
      <PageContent>{renderContent()}</PageContent>
      {plans.length > 0 && <ModalNewPlan />}
      {!!couponForm && <ModalCoupon />}
    </>
  );
};

export default MyPlan;
