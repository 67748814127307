export default {
  CHAT_CHANNELS_REQUEST: '@chat/CHAT_CHANNELS_REQUEST',
  CHAT_CHANNELS_REQUEST_SUCCESS: '@chat/CHAT_CHANNELS_REQUEST_SUCCESS',
  CHAT_SEND_MESSAGE: '@chat/CHAT_SEND_MESSAGE',
  CHAT_MESSAGE_REQUEST: '@chat/CHAT_MESSAGE_REQUEST',
  CHAT_ADD_NEW_MESSAGE: '@chat/CHAT_ADD_NEW_MESSAGE',
  CHAT_MESSAGE_REQUEST_SUCCESS: '@chat/CHAT_MESSAGE_REQUEST_SUCCESS',
  CHAT_SET_CHANNEL_SELECTED: '@chat/CHAT_SET_CHANNEL_SELECTED',
  CHAT_SET_MODAL_STATUS: '@chat/CHAT_SET_MODAL_STATUS',
  UPDATE_CHANNEL_IN_LIST: '@chat/UPDATE_CHANNEL_IN_LIST',
  CHAT_CHANNELS_SET_READ_REQUEST: '@chat/CHAT_CHANNELS_SET_READ_REQUEST',
};
