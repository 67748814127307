import styled from 'styled-components';
import AvatarComponent from '~/components/Avatar';
import colors from '~/styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border: 1px solid ${colors.light2};
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px ${colors.black}30;
  -moz-box-shadow: 0px 0px 5px 0px ${colors.black}30;
  box-shadow: 0px 0px 5px 0px ${colors.black}30;
`;

export const Image = styled(AvatarComponent).attrs({
  width: 70,
  height: 70,
})``;

export const CardBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;

export const ClassTitle = styled.h1`
  width: 100%;
  max-width: 100%;
  color: ${colors.dark3};
  font-size: 25px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
`;

export const ClassDate = styled.h2`
  width: 100%;
  color: ${colors.dark2};
  font-size: 16px;
  font-weight: 300;
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const ContainerContent = styled.div``;

export const KeywordBox = styled.div`
  margin-top: 10px;
  h2 {
    width: 100%;
    color: ${colors.dark3};
    font-size: 22px;
    font-weight: 500;
  }
  p {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 10px;
    color: ${colors.dark2};
    font-size: 16px;
    font-weight: 400;
  }
`;

export const QuestionBox = styled.div`
  margin-top: 10px;
  h2 {
    width: 100%;
    color: ${colors.dark3};
    font-size: 22px;
    font-weight: 500;
  }
  ul {
    padding: 0 10px;
    li {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 10px;
      border-bottom: 1px solid ${colors.light3};
      color: ${colors.dark2};
      font-size: 16px;
      font-weight: 400;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
`;

export const ConceptBox = styled.div`
  margin-top: 10px;
  h2 {
    width: 100%;
    color: ${colors.dark3};
    font-size: 22px;
    font-weight: 500;
  }
  h3 {
    width: 100%;
    color: ${colors.dark2};
    font-size: 18px;
    font-weight: 400;
    padding-left: 10px;
    margin-top: 10px;
  }
  ul {
    padding: 0 10px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    li {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 10px;
      border-bottom: 1px solid ${colors.light3};
      color: ${colors.dark2};
      font-size: 16px;
      font-weight: 400;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  .concept-fundamental {
    li {
      background-color: ${colors.info}25;
    }
  }
  .concept-success {
    li {
      background-color: ${colors.success}25;
    }
  }
  .concept-warning {
    li {
      background-color: ${colors.warning}25;
    }
  }
  .concept-danger {
    li {
      background-color: ${colors.danger}25;
    }
  }
`;
