import styled from 'styled-components';
import { shade } from 'polished';
import colors from '~/styles/colors';
import TableComponent from '~/components/Table';
import AvatarComponent from '~/components/Avatar';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding-top: 10px;
`;

export const ShareList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

export const Table = styled(TableComponent)`
  tbody {
    > tr {
      > td {
        > svg {
          margin-top: 3px;
        }
        div.table-action {
          > button.table-action-accept {
            background-color: ${colors.primary};
            &:hover {
              background-color: ${shade(0.2, colors.primary)};
            }
          }
        }
      }
    }
  }
`;

export const Image = styled(AvatarComponent).attrs({
  width: 40,
  height: 40,
})``;
