import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { FaSignature, FaCalendar, FaFile, FaClock } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import {
  certificateSetEdit,
  updateCertificateRequest,
} from '~/store/modules/certificate/actions';
import { getValidationErrors } from '~/utils';

import Modal from '~/components/Modal';
import {
  Row,
  SaveButton,
  Unform,
  UnformInput,
  UnformInputMask,
  UnformInputFile,
  UnformTextarea,
} from './styles';

export default function ModalEdit() {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { t } = useTranslation();
  const { certificateEdit } = useSelector(state => state.certificate);

  const schema = Yup.object().shape({
    file_name: Yup.mixed(),
    title: Yup.string().required(
      t('certificates.modal-edit.field.title.message.required')
    ),
    description: Yup.string(),
    workload: Yup.string(),
    initial_date: Yup.string().required(
      t('certificates.modal-edit.field.initial-date.message.required')
    ),
    final_date: Yup.string().required(
      t('certificates.modal-edit.field.final-date.message.required')
    ),
  });

  const handleSubmit = certificate => {
    dispatch(
      updateCertificateRequest({
        id: certificateEdit.id,
        ...certificate,
      })
    );
  };

  return (
    <Modal
      visible
      width="550px"
      height="auto"
      effect="fadeInUp"
      title={t('certificates.modal-edit.title')}
      onClose={() => {
        dispatch(certificateSetEdit(null));
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={certificateEdit}
        ref={formRef}
      >
        <UnformInputFile
          icon={FaFile}
          name="file_name"
          accept="application/pdf,image/jpeg,image/png"
          title={t('certificates.modal-edit.field.file-name.label')}
          style={{ marginBottom: 20 }}
          maxSize={5000000}
          changeName
        />
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('certificates.modal-edit.field.title.label')}
          name="title"
          autoFocus
          style={{ marginBottom: 20 }}
        />
        <UnformTextarea
          icon={FaSignature}
          type="text"
          label={t('certificates.modal-edit.field.description.label')}
          name="description"
          style={{ marginBottom: 20 }}
        />
        <Row>
          <UnformInputMask
            icon={FaCalendar}
            type="text"
            label={t('certificates.modal-edit.field.initial-date.label')}
            name="initial_date"
            mask="99/99/9999"
            style={{ marginRight: 20 }}
          />
          <UnformInputMask
            icon={FaCalendar}
            type="text"
            label={t('certificates.modal-edit.field.final-date.label')}
            name="final_date"
            mask="99/99/9999"
            style={{ marginRight: 20 }}
          />
          <UnformInput
            icon={FaClock}
            type="number"
            label={t('certificates.modal-edit.field.workload.label')}
            name="workload"
          />
        </Row>
        <SaveButton
          type="submit"
          title={t('certificates.modal-edit.save')}
          gradient
        />
      </Unform>
    </Modal>
  );
}
