import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';
import { Unform, UnformCheckbox, UnformSelect, ButtonSave } from './styles';

import { friendRequest } from '~/store/modules/friend/actions';
import { sharedCreate } from '~/store/modules/shared/actions';
import { getValidationErrors } from '~/utils';

export default function ModalShare({ onClose, classToShare }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { friends } = useSelector(state => state.friend);
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    friend: Yup.string().required(
      t('classes.modal-share.field.friend.message.required')
    ),
    classKeywords: Yup.bool(),
    classQuestions: Yup.bool(),
    editTogether: Yup.bool(),
  });

  useEffect(() => {
    dispatch(friendRequest());
  }, []);

  const [formInputs, setFormInputs] = useState({
    subjects: false,
    classes: false,
    classKeywords: false,
    classQuestions: false,
    editTogether: false,
  });

  const handleSubmit = share => {
    const dataPost = {
      user_id_to: share.friend,
      content_id: classToShare,
      type: 'class',
      config: {
        classKeywords: share.classKeywords,
        classQuestions: share.classQuestions,
        editTogether: share.editTogether,
      },
    };
    dispatch(sharedCreate(dataPost));
    onClose();
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('classes.modal-share.title')}
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={formInputs}
        ref={formRef}
      >
        <UnformSelect
          name="friend"
          label={t('classes.modal-share.field.friend.label')}
          style={{ marginBottom: 20 }}
          options={friends.map(item => ({ value: item.id, label: item.name }))}
        />
        <UnformCheckbox
          name="classKeywords"
          label={t('classes.modal-share.field.check.keywords')}
          checked={formInputs.classKeywords}
          onChange={evnt => {
            setFormInputs({
              ...formInputs,
              classKeywords: evnt.target.checked,
            });
          }}
        />
        <UnformCheckbox
          name="classQuestions"
          label={t('classes.modal-share.field.check.questions')}
          checked={formInputs.classQuestions}
          onChange={evnt => {
            setFormInputs({
              ...formInputs,
              classQuestions: evnt.target.checked,
            });
          }}
        />
        <small>{t('classes.modal-share.observation')}</small>
        <UnformCheckbox
          name="editTogether"
          label={t('classes.modal-share.field.check.editTogether')}
          checked={formInputs.editTogether}
          onChange={evnt => {
            setFormInputs({
              ...formInputs,
              editTogether: evnt.target.checked,
            });
          }}
          style={{ marginTop: 20, marginBottom: 0 }}
        />
        <ButtonSave
          type="submit"
          title={t('classes.modal-share.share')}
          gradient
        />
      </Unform>
    </Modal>
  );
}

ModalShare.defaultProps = {
  onClose: () => {},
  classToShare: '',
};

ModalShare.propTypes = {
  onClose: PropTypes.func,
  classToShare: PropTypes.string,
};
