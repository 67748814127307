import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import history from '~/services/history';
import { myPlanSetState, userPlanRequest, verifyExpired } from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* requestUserPlan() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'users/plan/all');
    // Stop loading
    yield put(stopLoader());

    yield put(myPlanSetState({ myPlans: response.data }));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());

    toast.error(i18n.t('store.myPlan.requestUserPlan.error'));
  }
}

export function* planVerifyExpired() {
  try {
    const { data } = yield call(api.get, 'users/plan/all');

    const expiredSubscription = data[0].expired_subscription;
    const daysLeft = data[0].days_left;
    const lifeLong = data[0].plan.life_long;

    if (daysLeft <= 5) {
      let message = i18n.t(
        'store.myPlan.planVerifyExpired.alert-due.will-due',
        { daysLeft }
      );
      if (expiredSubscription) {
        message = i18n.t(
          'store.myPlan.planVerifyExpired.alert-due.already-due'
        );
      }

      confirmAlert({
        title: i18n.t(`my-plan.plan-name.${data[0].plan.language_key}`),
        message,
        buttons: [
          {
            label: i18n.t('store.myPlan.planVerifyExpired.alert-due.not-now'),
          },
          {
            label: i18n.t('store.myPlan.planVerifyExpired.alert-due.renew'),
            onClick: () => {
              history.push('/my-plan');
            },
          },
        ],
      });
    }

    yield put(myPlanSetState({ expiredSubscription, daysLeft, lifeLong }));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());

    toast.error(i18n.t('store.myPlan.planVerifyExpired.error'));
  }
}

export function* planRequest() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'plans');
    // Stop loading
    yield put(stopLoader());

    yield put(myPlanSetState({ plans: response.data }));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());

    toast.error(i18n.t('store.myPlan.planRequest.error'));
  }
}

export function* planCheckout({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { checkoutData } = payload;
    yield call(api.post, 'checkouts', checkoutData);
    // Stop loading
    yield put(stopLoader());

    yield put(myPlanSetState({ plans: [] }));
    yield put(userPlanRequest());
    yield put(verifyExpired());
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.myPlan.planCheckout.error'));
  }
}

export function* couponCheck({ payload }) {
  const { couponForm } = payload;
  // Start loading
  yield put(startLoader());
  try {
    yield call(api.post, 'userCoupons', couponForm);
    // Stop loading
    yield put(stopLoader());

    yield put(myPlanSetState({ couponForm: null }));
    yield put(userPlanRequest());
    yield put(verifyExpired());
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.myPlan.couponCheck.error'));
  }
}

export default all([
  takeLatest(types.USER_PLAN_REQUEST, requestUserPlan),
  takeLatest(types.PLANS_REQUEST, planRequest),
  takeLatest(types.CHECKOUT, planCheckout),
  takeLatest(types.VERIFY_EXPIRED, planVerifyExpired),
  takeLatest(types.USE_COUPON, couponCheck),
]);
