import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import history from '~/services/history';

import {
  // Class
  classRequestSuccess,
  classesRequestSuccess,
  classesRequest,
  // classRequest,
  classSetModalCreate,
  classSetEdit,
  // Question
  classQuestionRequestSuccess,
  classQuestionRequest,
  classQuestionSet,
  // Keyword
  classKeywordRequestSuccess,
  classKeywordRequest,
  classKeywordSet,
  // Files
  classFileRequestSuccess,
  classFileRequest,
  classFileSetCreate,
  // Mindmap
  classMindmapRequestSuccess,
  classMindmapRequest,
  classMindmapSet,
  classMindmapEditorSet,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

// Class
export function* requestClasses({ payload }) {
  // Start loading
  yield put(startLoader());
  const { subjectId } = payload;
  try {
    const response = yield call(api.get, `classes/subject/${subjectId}`);
    const classes = response.data.map(item => ({
      ...item,
      created_at: moment(item.created_at).format('DD/MM/YYYY HH:mm:ss'),
      updated_at: moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss'),
    }));
    // Stop loading
    yield put(stopLoader());

    yield put(classesRequestSuccess(classes));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.request-classes.error'));
    history.push('/');
  }
}

export function* remove({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { classe } = payload;

    yield call(api.delete, `classes/${classe.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classesRequest(classe.subject_id));

    toast.success(i18n.t('store.class.remove.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.remove.error'));
  }
}

export function* request({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { classId } = payload;
    const response = yield call(api.get, `classes/${classId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.request.error'));
    history.push('/');
  }
}

export function* update({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...classe } = payload.classe;
    yield call(api.put, `classes/${id}`, classe);
    // Stop loading
    yield put(stopLoader());
    yield put(classesRequest(classe.subject_id));

    yield put(classSetEdit(null));
    toast.success(i18n.t('store.class.update.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.update.error'));
  }
}

export function* create({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { classe } = payload;
    yield call(api.post, `classes`, classe);
    // Stop loading
    yield put(stopLoader());

    yield put(classSetModalCreate(false));
    yield put(classesRequest(classe.subject_id));
    toast.success(i18n.t('store.class.create.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.create.error'));
  }
}

// Question
export function* requestQuestion({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { classId } = payload;
    const response = yield call(api.get, `classquestions/class/${classId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classQuestionRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.request-question.error'));
  }
}

export function* createQuestion({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { question } = payload;
    yield call(api.post, 'classquestions', question);
    // Stop loading
    yield put(stopLoader());

    yield put(classQuestionRequest(question.class_id));
    toast.success(i18n.t('store.class.create-question.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.create-question.error'));
  }
}

export function* updateQuestion({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { question_id: questionId, ...question } = payload.question;
    yield call(api.put, `classquestions/${questionId}`, question);
    // Stop loading
    yield put(stopLoader());

    yield put(classQuestionRequest(question.class_id));
    yield put(classQuestionSet(null));
    toast.success(i18n.t('store.class.update-question.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.update-question.error'));
  }
}

export function* deleteQuestion({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { question } = payload;

    yield call(api.delete, `classquestions/${question.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classQuestionRequest(question.class_id));
    yield put(classQuestionSet(null));
    toast.success(i18n.t('store.class.delete-question.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.delete-question.error'));
  }
}

// Keyword
export function* requestKeyword({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { classId } = payload;
    const response = yield call(api.get, `classkeywords/class/${classId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classKeywordRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.request-keyword.error'));
  }
}

export function* createKeyword({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { keyword } = payload;
    yield call(api.post, 'classkeywords', keyword);
    // Stop loading
    yield put(stopLoader());

    yield put(classKeywordRequest(keyword.class_id));
    toast.success(i18n.t('store.class.create-keyword.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.create-keyword.error'));
  }
}

export function* updateKeyword({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { keyword_id: keywordId, ...keyword } = payload.keyword;
    yield call(api.put, `classkeywords/${keywordId}`, keyword);
    // Stop loading
    yield put(stopLoader());

    yield put(classKeywordRequest(keyword.class_id));
    yield put(classKeywordSet(null));
    toast.success(i18n.t('store.class.update-keyword.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.update-keyword.error'));
  }
}

export function* deleteKeyword({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { keyword } = payload;

    yield call(api.delete, `classkeywords/${keyword.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classKeywordRequest(keyword.class_id));
    yield put(classKeywordSet(null));
    toast.success(i18n.t('store.class.delete-keyword.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.delete-keyword.error'));
  }
}

// File
export function* requestFile({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { classId } = payload;
    const response = yield call(api.get, `classfiles/class/${classId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classFileRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.request-file.error'));
  }
}

export function* createFile({ payload }) {
  try {
    toast.warn(i18n.t('store.class.create-file.warn'));
    const { classId, file } = payload;
    yield call(api.post, `classfiles`, file);
    yield put(classFileSetCreate(null));
    yield put(classFileRequest(classId));
    toast.success(i18n.t('store.class.create-file.success'));
  } catch (error) {
    toast.error(i18n.t('store.class.create-file.error'));
  }
}

export function* updateFile({ payload }) {
  try {
    const { fileData } = payload;
    yield call(api.put, `classfiles/${fileData.id}`, fileData);
    toast.success(i18n.t('store.class.create-file.success'));
    yield put(classFileRequest(fileData.class_id));
  } catch (error) {
    toast.error(i18n.t('store.class.create-file.error'));
  }
}

export function* deleteFile({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { file } = payload;
    yield call(api.delete, `classfiles/${file.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classFileRequest(file.class_id));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.delete-file.error'));
  }
}

// Mindmap
export function* requestMindmap({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { classId } = payload;
    const response = yield call(api.get, `classmindmaps/class/${classId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classMindmapRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.request-mindmap.error'));
  }
}

export function* createMindmap({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { mindmap } = payload;
    const { data } = yield call(api.post, 'classmindmaps', mindmap);
    // Stop loading
    yield put(stopLoader());

    yield put(classMindmapRequest(mindmap.class_id));
    yield put(classMindmapEditorSet(data));
    yield put(classMindmapSet(null));
    toast.success(i18n.t('store.class.create-mindmap.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.create-mindmap.error'));
  }
}

export function* updateMindmap({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { mindmap_id: mindmapId, ...mindmap } = payload.mindmap;
    yield call(api.put, `classmindmaps/${mindmapId}`, mindmap);
    // Stop loading
    yield put(stopLoader());

    yield put(classMindmapRequest(mindmap.class_id));
    // yield put(classMindmapEditorSet(null));
    yield put(classMindmapSet(null));
    toast.success(i18n.t('store.class.update-mindmap.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.update-mindmap.error'));
  }
}

export function* deleteMindmap({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { mindmap } = payload;

    yield call(api.delete, `classmindmaps/${mindmap.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(classMindmapRequest(mindmap.class_id));
    yield put(classMindmapSet(null));
    toast.success(i18n.t('store.class.delete-mindmap.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.class.delete-mindmap.error'));
  }
}

export default all([
  // Class
  takeLatest(types.CLASSES_REQUEST, requestClasses),
  takeLatest(types.DELETE_CLASS_REQUEST, remove),
  takeLatest(types.CLASS_REQUEST, request),
  takeLatest(types.UPDATE_CLASS_REQUEST, update),
  takeLatest(types.CREATE_CLASS_REQUEST, create),
  // Question
  takeLatest(types.QUESTIONS_REQUEST, requestQuestion),
  takeLatest(types.CREATE_QUESTION_REQUEST, createQuestion),
  takeLatest(types.UPDATE_QUESTION_REQUEST, updateQuestion),
  takeLatest(types.DELETE_QUESTION_REQUEST, deleteQuestion),
  // Keyword
  takeLatest(types.KEYWORDS_REQUEST, requestKeyword),
  takeLatest(types.CREATE_KEYWORD_REQUEST, createKeyword),
  takeLatest(types.UPDATE_KEYWORD_REQUEST, updateKeyword),
  takeLatest(types.DELETE_KEYWORD_REQUEST, deleteKeyword),
  // File
  takeLatest(types.FILES_REQUEST, requestFile),
  takeLatest(types.FILES_CREATE, createFile),
  takeLatest(types.FILES_UPDATE, updateFile),
  takeLatest(types.FILES_DELETE, deleteFile),
  // Mindmap
  takeLatest(types.MINDMAP_REQUEST, requestMindmap),
  takeLatest(types.CREATE_MINDMAP_REQUEST, createMindmap),
  takeLatest(types.UPDATE_MINDMAP_REQUEST, updateMindmap),
  takeLatest(types.DELETE_MINDMAP_REQUEST, deleteMindmap),
]);
