import config from '~/config';

let timezoneUser = null;

export const setTimezoneFromUser = timezone => {
  if (timezone) {
    const tz = config.timezones.find(i => i.value === timezone);
    timezoneUser = tz || null;
  } else {
    timezoneUser = null;
  }
};

export const getTimezoneFromUser = () => timezoneUser;
