export default {
  // Base colors
  primary: '#921E5C',
  secondary: '#F38021',
  third: '#A8544D',
  accent1: '#5C0C3C',
  accent2: '#172048',
  accent3: '#43072A',

  // Dark colors
  black: '#000000',
  dark1: '#9e9e9e',
  dark2: '#606060',
  dark3: '#404040',
  dark4: '#707070',

  // Light colors
  white: '#FFFFFF',
  light1: '#E5E5E5',
  light2: '#D8D7D9',
  light3: '#c3c3c3',

  // Others colors
  success: '#00CD9B',
  danger: '#EE765E',
  warning: '#ff9650',
  info: '#4DC1DF',
};
