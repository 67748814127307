import React from 'react';
import Proptypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

import { Container, ModalClose, ModalWrap, PageHeader } from './styles';

export default function Modal({
  visible,
  width,
  height,
  effect,
  onClickAway,
  title,
  onClose,
  children,
  overflow,
  className,
}) {
  const renderHeader = () => (
    <PageHeader
      breadcrumb={[title]}
      actionElements={() => (
        <ModalClose type="button" onClick={onClose}>
          <FaTimes />
        </ModalClose>
      )}
    />
  );

  return (
    <Container
      visible={visible}
      width={width}
      height={height}
      effect={effect}
      onClickAway={onClickAway}
      className={className}
    >
      <ModalWrap overflow={overflow}>
        {renderHeader()}
        {children}
      </ModalWrap>
    </Container>
  );
}

Modal.defaultProps = {
  visible: false,
  width: '80%',
  height: '80%',
  effect: 'fadeInUp',
  onClickAway: () => {},
  title: '',
  onClose: () => {},
  overflow: true,
};

Modal.propTypes = {
  className: Proptypes.string.isRequired,
  visible: Proptypes.bool,
  width: Proptypes.string,
  height: Proptypes.string,
  effect: Proptypes.string,
  onClickAway: Proptypes.func,
  title: Proptypes.string,
  onClose: Proptypes.func,
  children: Proptypes.element.isRequired,
  overflow: Proptypes.bool,
};
