import React from 'react';
import { TiInfoLarge } from 'react-icons/ti';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

function TourButton({ ...rest }) {
  const { t } = useTranslation();
  return (
    <>
      <Container
        {...rest}
        data-tip={t('tour.btn-enable-tour')}
        data-for="tour-tooltip"
      >
        <TiInfoLarge />
      </Container>
      <ReactTooltip
        id="tour-tooltip"
        type="dark"
        place="bottom"
        effect="solid"
      />
    </>
  );
}

export default TourButton;
