import styled from 'styled-components';
import colors from '~/styles/colors';

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;

  .agenda-box {
    padding: 5px;
    border: 0;
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid ${colors.primary}80;
    }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid ${colors.primary}80;
    }
    .rbc-agenda-view {
      border: 0;
      > .rbc-agenda-table {
        border: 0;
        .rbc-header {
          padding: 5px;
          border: 0;
          font-size: 15px;
          font-weight: 500;
          color: ${colors.accent3};
        }
      }
      > .rbc-agenda-content {
        .rbc-agenda-table {
          border: 0;
          tbody {
            tr {
              td {
                font-size: 14px;
                font-weight: 400;
                color: ${colors.accent3};
              }
            }
          }
        }
      }
    }
    .rbc-month-view {
      border: 0;
      .rbc-month-header {
        .rbc-header {
          padding: 5px 0;
          border: 0;
          span {
            font-size: 15px;
            font-weight: 500;
            color: ${colors.accent3};
          }
        }
      }
      .rbc-month-row + .rbc-month-row {
        border-top: 1px solid ${colors.primary}80;
      }
      .rbc-month-row {
        .rbc-row-bg {
          .rbc-off-range-bg {
            background: ${colors.primary}30;
          }
          .rbc-today {
            background: ${colors.secondary}30;
          }
          .rbc-day-bg + .rbc-day-bg {
            border-left: 1px solid ${colors.primary}80;
          }
        }
        .rbc-row-content {
          .rbc-row {
            .rbc-date-cell {
              a {
                font-size: 14px;
                font-weight: 500;
                color: ${colors.accent3};
              }
            }
          }
        }
      }
    }
  }
`;

export const CalendarToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.primary};
  width: 100%;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 10px;
  > h2 {
    color: ${colors.white};
    font-size: 18px;
    font-weight: 400;
    height: 40px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  > div.calendar-toolbar-action {
    height: 40px;
    display: flex;
    padding: 5px;
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colors.white};
      background-color: ${colors.accent3};
      font-size: 14px;
      font-weight: 400;
      border: 0;
      height: 30px;
      margin: 0;
      width: 100px;
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        margin-right: 1px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`;
