import styled from 'styled-components';
import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.white};
  min-width: 800px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const ClearButton = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    height: 35,
    fontSize: 14,
  },
})``;

export const ResultList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
