/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { FaAngleDoubleRight, FaArrowLeft } from 'react-icons/fa';

import { Container, HeaderTitle, HeaderAction, HeaderBack } from './styles';

export default function PageHeader({
  breadcrumb,
  actionElements,
  onBack,
  className,
}) {
  return (
    <Container className={className}>
      <HeaderTitle className="header-title">
        {onBack && (
          <HeaderBack type="button" onClick={onBack}>
            <FaArrowLeft />
          </HeaderBack>
        )}
        {breadcrumb.map((item, index) => (
          <span key={index}>
            {item}
            {index + 1 < breadcrumb.length && <FaAngleDoubleRight size={20} />}
          </span>
        ))}
      </HeaderTitle>
      {actionElements && (
        <HeaderAction className="header-action">
          {actionElements()}
        </HeaderAction>
      )}
    </Container>
  );
}

PageHeader.defaultProps = {
  breadcrumb: [],
  actionElements: null,
  onBack: null,
};

PageHeader.propTypes = {
  breadcrumb: PropTypes.arrayOf(PropTypes.string),
  actionElements: PropTypes.func,
  onBack: PropTypes.func,
  className: PropTypes.string.isRequired,
};
