import types from './types';

export function faqRequest() {
  return {
    type: types.FAQ_REQUEST,
  };
}

export function faqRequestSuccess(faqs) {
  return {
    type: types.FAQ_REQUEST_SUCCESS,
    payload: { faqs },
  };
}
