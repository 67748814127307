/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  events: [],
  eventEdit: null,
  eventModalCreate: false,
};

export default function agenda(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.AGENDA_REQUEST_SUCCESS: {
        draft.events = action.payload.events;
        break;
      }
      case types.AGENDA_SET_UPDATE: {
        draft.eventEdit = action.payload.eventEdit;
        break;
      }
      case types.AGENDA_SET_MODAL_CREATE: {
        draft.eventModalCreate = action.payload.eventModalCreate;
        break;
      }
      default:
    }
  });
}
