/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Proptypes from 'prop-types';
import { ImUndo2, ImRedo2 } from 'react-icons/im';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

import ToolbarItemOpen from './ItemOpen';
import ToolbarItemLayout from './ItemLayout';
import ToolbarItemTheme from './ItemTheme';
import ToolbarItemExport from './ItemExport';
import ToolbarItemSearch from './ItemSearch';
import './Toolbar.css';

import { Container } from './styles';

const Toolbar = props => {
  const { t } = useTranslation();
  const { onClickUndo, onClickRedo, canUndo, canRedo, children } = props;
  return (
    <Container>
      <div className="container-buttons">
        <ToolbarItemOpen {...props} />
        <ToolbarItemExport {...props} />
        <ToolbarItemTheme {...props} />
        <ToolbarItemLayout {...props} />
        <ToolbarItemSearch {...props} />
        <button
          type="button"
          className="toolbar-menu-item"
          disabled={!canUndo}
          onClick={onClickUndo}
          data-tip={t('class-view.modal-mindmaps.toolbar.buttonUndo')}
        >
          <ImUndo2 />
        </button>
        <button
          type="button"
          className="toolbar-menu-item"
          disabled={!canRedo}
          onClick={onClickRedo}
          data-tip={t('class-view.modal-mindmaps.toolbar.buttonRedo')}
        >
          <ImRedo2 />
        </button>
        <ReactTooltip />
      </div>
      <div className="container-actions">{children}</div>
    </Container>
  );
};

Toolbar.propTypes = {
  onClickUndo: Proptypes.func.isRequired,
  onClickRedo: Proptypes.func.isRequired,
  canUndo: Proptypes.bool.isRequired,
  canRedo: Proptypes.bool.isRequired,
  children: Proptypes.element.isRequired,
};

export default Toolbar;
