/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';
import authTypes from '../auth/types';
import { setTimezoneFromUser } from '~/services/timezone';

const INITIAL_STATE = {
  profile: null,
  notificationConfig: null,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case authTypes.SIGN_IN_SUCCESS: {
        draft.profile = action.payload.user;
        setTimezoneFromUser(action.payload.user.locale_config.tzCode);
        break;
      }
      case authTypes.SIGN_OUT: {
        draft.profile = null;
        setTimezoneFromUser(null);
        break;
      }
      case types.UPDATE_PROFILE_SUCCESS: {
        draft.profile = action.payload.profile;
        setTimezoneFromUser(action.payload.profile.locale_config.tzCode);
        break;
      }
      case types.NOTIFICATION_CONFIG_REQUEST_SUCCESS: {
        draft.notificationConfig = action.payload.notificationConfig;
        break;
      }
      default:
    }
  });
}
