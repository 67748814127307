/* eslint-disable no-param-reassign */
import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  token: null,
  refreshToken: null,
  signed: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case types.SIGN_IN_SUCCESS: {
        draft.token = action.payload.token;
        draft.refreshToken = action.payload.refreshToken;
        draft.signed = true;
        break;
      }
      case types.SIGN_FAILURE: {
        break;
      }
      case types.SIGN_OUT: {
        draft.token = null;
        draft.refreshToken = null;
        draft.signed = false;
        break;
      }
      case types.SET_SIGNED: {
        draft.signed = action.payload.signed;
        break;
      }
      default:
    }
  });
}
