import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { FaSignature, FaCalendar, FaTrash } from 'react-icons/fa';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import {
  agendaSetUpdate,
  updateAgendaRequest,
  deleteAgendaRequest,
} from '~/store/modules/agenda/actions';
import { getValidationErrors } from '~/utils';

import Modal from '~/components/Modal';
import {
  SaveButton,
  Unform,
  UnformInput,
  UnformInputMask,
  UnformTextarea,
  ExitButton,
} from './styles';

export default function ModalEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    title: Yup.string().required(
      t('agenda.modal-edit.field.title.message.required')
    ),
    description: Yup.string().required(
      t('agenda.modal-edit.field.description.message.required')
    ),
    initial_date: Yup.string().required(
      t('agenda.modal-edit.field.initial-date.message.required')
    ),
  });

  const { eventEdit } = useSelector(state => state.agenda);

  const handleSubmit = event => {
    dispatch(
      updateAgendaRequest({
        id: eventEdit.id,
        ...event,
        initial_date: moment(event.initial_date, 'DD/MM/YYYY HH:mm').format(
          'YYYY-MM-DD HH:mm:00Z'
        ),
      })
    );
  };

  const handleDeleteEvent = () => {
    confirmAlert({
      title: t('agenda.modal-edit.confirm-alert.delete.title'),
      message: t('agenda.modal-edit.confirm-alert.delete.message'),
      buttons: [
        {
          label: t('agenda.modal-edit.confirm-alert.delete.buttons.cancel'),
        },
        {
          label: t('agenda.modal-edit.confirm-alert.delete.buttons.yes'),
          onClick: () => {
            dispatch(deleteAgendaRequest(eventEdit.id));
          },
        },
      ],
    });
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('agenda.modal-edit.title')}
      onClose={() => {
        dispatch(agendaSetUpdate(null));
      }}
    >
      <>
        <ExitButton
          icon={FaTrash}
          title={t('agenda.modal-edit.delete')}
          onClick={() => {
            handleDeleteEvent();
          }}
        />
        <Unform
          onSubmit={async data => {
            try {
              formRef.current.setErrors({});
              await schema.validate(data, { abortEarly: false });
              handleSubmit(data);
            } catch (err) {
              formRef.current.setErrors(getValidationErrors(err));
            }
          }}
          initialData={{
            title: eventEdit.title,
            description: eventEdit.description,
            initial_date: moment(eventEdit.initial_date).format(
              'DD/MM/YYYY HH:mm'
            ),
          }}
          ref={formRef}
        >
          <UnformInput
            icon={FaSignature}
            type="text"
            label={t('agenda.modal-edit.field.title.label')}
            name="title"
            autoFocus
            style={{ marginBottom: 20 }}
          />
          <UnformInputMask
            icon={FaCalendar}
            type="text"
            label={t('agenda.modal-edit.field.initial-date.label')}
            name="initial_date"
            mask="99/99/9999 99:99"
            style={{ marginBottom: 20 }}
          />
          <UnformTextarea
            icon={FaSignature}
            type="text"
            label={t('agenda.modal-edit.field.description.label')}
            name="description"
            style={{ marginBottom: 20 }}
          />
          <SaveButton
            type="submit"
            title={t('agenda.modal-edit.save')}
            gradient
          />
        </Unform>
      </>
    </Modal>
  );
}
