import styled from 'styled-components';
import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const Button = styled(ButtonComponent).attrs({
  color: colors.secondary,
  style: {
    height: 30,
    fontSize: 12,
  },
})``;
