import timezones from './timezones';
import formatDates from './dates';

const development = {
  API_URI: 'http://127.0.0.1:3333',
  API_WS: 'ws://127.0.0.1:3333',
  EDITOR_URI: 'http://127.0.0.1:3001',
  SITE_URL: 'https://hippocampusacademic.com',
};

const production = {
  API_URI: 'https://service.hippocampusacademic.com',
  API_WS: 'wss://service.hippocampusacademic.com',
  EDITOR_URI: 'https://editor.hippocampusacademic.com',
  SITE_URL: 'https://hippocampusacademic.com',
};

export default {
  ...(process.env.NODE_ENV === 'development' ? development : production),
  timezones,
  formatDates,
};
