import styled, { css } from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 30px;
  min-height: 25px;
  font-size: 14px;
  color: ${colors.white};
  font-weight: 400;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  div.checklabel {
    display: flex;
    flex-direction: column;
    span.error {
      color: ${colors.secondary};
      font-size: 13px;
      margin-top: 5px;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border-radius: 4px;
    border: 1px solid ${colors.white};
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`;

export const Checkbox = styled.input``;
