/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { VscSymbolColor } from 'react-icons/vsc';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import colors from '~/styles/colors';
import defaultThemeImg from '../../Images/default.jpg';
import theme1Img from '../../Images/theme1.jpg';
import theme2Img from '../../Images/theme2.jpg';
import theme3Img from '../../Images/theme3.jpg';
import theme4Img from '../../Images/theme4.jpg';
import theme5Img from '../../Images/theme5.jpg';

const ToolbarItemTheme = ({ diagram }) => {
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const onClickSetTheme = themeKey => {
    const diagramProps = diagram.getDiagramProps();
    const { controller } = diagramProps;
    controller.run('setTheme', { ...diagramProps, themeKey });
  };
  const themes = [
    ['default', defaultThemeImg],
    ['theme1', theme1Img],
    ['theme2', theme2Img],
    ['theme3', theme3Img],
    ['theme4', theme4Img],
    ['theme5', theme5Img],
  ];

  const BoxThemes = styled.div`
    width: 600px;
    height: 400px;
    overflow-y: auto;
    background-color: ${colors.white};
    border: 1px solid ${colors.primary};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .box-themes-item {
      width: 100%;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  `;

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={['bottom']}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={0}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={colors.primary}
          arrowSize={10}
        >
          <BoxThemes>
            {themes.map(theme => (
              <div
                key={theme[0]}
                className="box-themes-item"
                onClick={() => onClickSetTheme(theme[0])}
              >
                <img src={theme[1]} alt={theme[0]} />
              </div>
            ))}
          </BoxThemes>
        </ArrowContainer>
      )}
    >
      <button
        type="button"
        className="toolbar-menu-item"
        onClick={() => setIsPopoverOpen(true)}
        data-tip={t('class-view.modal-mindmaps.toolbar.buttonTheme')}
      >
        <VscSymbolColor />
      </button>
    </Popover>
  );
};

ToolbarItemTheme.propTypes = {
  diagram: Proptypes.shape().isRequired,
};

export default ToolbarItemTheme;
