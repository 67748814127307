import React, { useRef } from 'react';
import * as Yup from 'yup';
import { FaSignature } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';

import {
  createQuestionRequest,
  updateQuestionRequest,
} from '~/store/modules/class/actions';
import { getValidationErrors } from '~/utils';

import { Unform, UnformInput, ButtonSave } from './styles';

export default function ModalQuestions({ onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { classe, questionForm } = useSelector(state => state.classe);

  const schema = Yup.object().shape({
    description: Yup.string().required(
      t('class-view.modal-questions.field.description.message.required')
    ),
  });

  const handleCreate = async ({ description }) => {
    dispatch(
      createQuestionRequest({
        class_id: classe.id,
        description,
      })
    );
    formRef.current.reset();
  };

  const handleUpdate = async ({ description }) => {
    dispatch(
      updateQuestionRequest({
        class_id: classe.id,
        description,
        question_id: questionForm.id,
      })
    );
    onClose();
  };

  return (
    <Modal
      visible
      width="650px"
      height="auto"
      effect="fadeInUp"
      title={t('class-view.modal-questions.title')}
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            if (questionForm.id) {
              handleUpdate(data);
            } else {
              handleCreate(data);
            }
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={questionForm}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('class-view.modal-questions.field.description.label')}
          name="description"
          autoFocus
          style={{ marginRight: 20 }}
        />
        <ButtonSave
          type="submit"
          title={t('class-view.modal-questions.save')}
        />
      </Unform>
    </Modal>
  );
}

ModalQuestions.propTypes = {
  onClose: PropTypes.func.isRequired,
};
