import types from './types';

export function friendRequest() {
  return {
    type: types.FRIEND_REQUEST,
  };
}

export function friendRequestSuccess(friends) {
  return {
    type: types.FRIEND_REQUEST_SUCCESS,
    payload: { friends },
  };
}

export function deleteFriendRequest(friendId) {
  return {
    type: types.DELETE_FRIEND_REQUEST,
    payload: { friendId },
  };
}

export function friendSearchRequest() {
  return {
    type: types.FRIEND_SEARCH_REQUEST,
  };
}

export function friendSearchRequestSuccess(friendsBySearch) {
  return {
    type: types.FRIEND_SEARCH_REQUEST_SUCCESS,
    payload: { friendsBySearch },
  };
}

export function friendInviteFriendRequest(friendId) {
  return {
    type: types.FRIEND_INVITE_FRIEND_REQUEST,
    payload: { friendId },
  };
}

export function friendInvitesRequest() {
  return {
    type: types.FRIEND_INVITES_REQUEST,
  };
}

export function friendInvitesRequestSuccess(friendsInvites) {
  return {
    type: types.FRIEND_INVITES_REQUEST_SUCCESS,
    payload: { friendsInvites },
  };
}

export function friendInvitesRequestAccept(friendId) {
  return {
    type: types.FRIEND_INVITES_REQUEST_ACCEPT,
    payload: { friendId },
  };
}

export function friendSetSearch(searchFriend) {
  return {
    type: types.FRIEND_SET_SEARCH,
    payload: { searchFriend },
  };
}
