import types from './types';

export function studyContentRequest(testId) {
  return {
    type: types.STUDY_CONTENT_REQUEST,
    payload: { testId },
  };
}

export function studyContentRequestSuccess(testContent) {
  return {
    type: types.STUDY_CONTENT_REQUEST_SUCCESS,
    payload: { testContent },
  };
}

export function studyContentExportPdf(config) {
  return {
    type: types.STUDY_CONTENT_EXPORT_PDF,
    payload: { config },
  };
}

export function studyAllTestsRequest() {
  return {
    type: types.STUDY_ALL_TESTS_REQUEST,
  };
}

export function studyAllTestsRequestSuccess(allTests) {
  return {
    type: types.STUDY_ALL_TESTS_REQUEST_SUCCESS,
    payload: { allTests },
  };
}
