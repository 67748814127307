import styled from 'styled-components';
import { shade } from 'polished';
import ButtonComponent from '~/components/Button';
import TableComponent from '~/components/Table';
import colors from '~/styles/colors';

export const HeaderButton = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginLeft: 10,
  },
})``;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const DriveList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
`;

export const Table = styled(TableComponent)`
  tbody {
    > tr {
      > td {
        > svg {
          margin-top: 3px;
        }
        div.table-action {
          > button.table-action-download {
            background-color: ${colors.primary};
            &:hover {
              background-color: ${shade(0.2, colors.primary)};
            }
          }
          > button.table-action-view {
            background-color: ${colors.primary};
            &:hover {
              background-color: ${shade(0.2, colors.primary)};
            }
          }
          > button.table-action-share {
            background-color: ${colors.primary};
            &:hover {
              background-color: ${shade(0.2, colors.primary)};
            }
          }
        }
      }
    }
  }
`;
