import { Model, createKey } from '@blink-mind/core';
import { defaultTheme } from './PluginThemeSelector';

export const downloadFile = (url, filename) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
};

export function generateSimpleModel() {
  const rootKey = createKey();
  const jsonData = {
    rootTopicKey: rootKey,
    editorRootTopicKey: rootKey,
    focusKey: rootKey,
    extData:{},
    topics:[
       {
          key: rootKey,
          parentKey: null,
          subKeys: [],
          collapse: false,
          style: null,
          blocks: [{ type:"CONTENT", data:"Hippocampus" }]
       }
    ],
    config:{
      readOnly: false,
      allowUndo: true,
      layoutDir: 2,
      theme: defaultTheme
    },
    formatVersion: null
  };

  // const model = Model.create({ rootTopicKey: rootKey, topics: [{ key: rootKey, blocks: [{ type: 'CONTENT', data: 'Hippocampus' }]}]});

  return Model.fromJSON(jsonData);
}

export function generateModelFromJson(jsonData) {
  return Model.fromJSON(jsonData);
}
