import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Avatar({ src, width, height, padding, className }) {
  return (
    <Container
      width={width}
      height={height}
      src={src}
      padding={padding}
      className={className}
    />
  );
}

Avatar.defaultProps = {
  width: 30,
  height: 30,
  padding: 0,
};

Avatar.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  padding: PropTypes.number,
  className: PropTypes.string.isRequired,
};
