import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import BlankContent from '~/components/BlankContent';
import Route from '~/services/history';
import PageHeader from '~/components/PageHeader';
import {
  searchSetFilter,
  searchRequestSuccess,
} from '~/store/modules/search/actions';
import SearchItem from './components/SearchItem';
import PageContent from '~/components/PageContent';

import { Container, ClearButton, ResultList } from './styles';

const Search = () => {
  const dispatch = useDispatch();
  const { results } = useSelector(state => state.search);

  const renderHeader = () => {
    return (
      <PageHeader
        breadcrumb={['Busca indexada']}
        actionElements={() => (
          <ClearButton
            type="button"
            title="Limpar busca"
            icon={FaTimes}
            gradient
            onClick={() => {
              dispatch(searchSetFilter(''));
              dispatch(searchRequestSuccess([]));
            }}
          />
        )}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      <PageContent>
        <ResultList>
          {results.length > 0 ? (
            results.map(item => (
              <SearchItem
                key={item.id}
                title={item.title}
                type={item.type}
                onClick={() => {
                  switch (item.type) {
                    case 'periods': {
                      Route.replace(`/subjects/${item.period_id}`);
                      break;
                    }
                    case 'subjects': {
                      Route.replace(
                        `/classes/${item.period_id}/${item.subject_id}`
                      );
                      break;
                    }
                    case 'classes': {
                      Route.replace(
                        `/classes/${item.period_id}/${item.subject_id}/${item.id}`
                      );
                      break;
                    }
                    default:
                      break;
                  }
                  dispatch(searchSetFilter(''));
                }}
              />
            ))
          ) : (
            <BlankContent>Nenhum resultado</BlankContent>
          )}
        </ResultList>
      </PageContent>
    </>
  );
};

export default Search;
