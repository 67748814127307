import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { DiagramLayoutType } from '@blink-mind/core';
import { Icon } from '@blink-mind/renderer-react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import styled from 'styled-components';
import { VscListTree } from 'react-icons/vsc';
import { useTranslation } from 'react-i18next';

import colors from '~/styles/colors';

const ToolbarItemLayout = ({ diagram }) => {
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const layoutDirs = [
    [
      DiagramLayoutType.LEFT_AND_RIGHT,
      t('class-view.modal-mindmaps.toolbar.buttonAlign.left-right'),
      'layout-left-and-right',
    ],
    [
      DiagramLayoutType.LEFT_TO_RIGHT,
      t('class-view.modal-mindmaps.toolbar.buttonAlign.right'),
      'layout-right',
    ],
    [
      DiagramLayoutType.RIGHT_TO_LEFT,
      t('class-view.modal-mindmaps.toolbar.buttonAlign.left'),
      'layout-left',
    ],
  ];

  const onClickSetLayout = layoutDir => {
    const diagramProps = diagram.getDiagramProps();
    const { controller } = diagramProps;
    controller.run('setLayoutDir', { ...diagramProps, layoutDir });
  };

  const BoxMenu = styled.div`
    width: 250px;
    overflow-y: auto;
    background-color: ${colors.white};
    border: 1px solid ${colors.primary};
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    > button {
      display: flex;
      align-items: center;
      background-color: transparent;
      width: 100%;
      padding: 10px 20px;
      border: 0;
      border-bottom: 1px solid ${colors.primary};
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: ${colors.dark4};
      &:hover {
        background-color: ${colors.primary}20;
      }
      &:last-child {
        border-bottom: 0;
      }
      > span {
        margin-right: 5px;
      }
    }
  `;

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={['bottom']}
      onClickOutside={() => setIsPopoverOpen(false)}
      padding={0}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowColor={colors.primary}
          arrowSize={10}
        >
          <BoxMenu>
            {layoutDirs.map(dir => (
              <button
                type="button"
                key={dir[1]}
                onClick={() => onClickSetLayout(dir[0])}
              >
                {Icon(dir[2])}
                {dir[1]}
              </button>
            ))}
          </BoxMenu>
        </ArrowContainer>
      )}
    >
      <button
        type="button"
        className="toolbar-menu-item"
        onClick={() => setIsPopoverOpen(true)}
        data-tip={t('class-view.modal-mindmaps.toolbar.buttonAlign.title')}
      >
        <VscListTree />
      </button>
    </Popover>
  );
};

ToolbarItemLayout.propTypes = {
  diagram: Proptypes.shape().isRequired,
};

export default ToolbarItemLayout;
