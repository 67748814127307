import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';
import UnformTextareaComponent from '~/components/Unform/Textarea';
import UnformInputMaskComponent from '~/components/Unform/InputMask';

import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const SaveButton = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
`;

export const UnformInput = styled(UnformInputComponent)``;

export const UnformTextarea = styled(UnformTextareaComponent)``;

export const UnformInputMask = styled(UnformInputMaskComponent)``;

export const ExitButton = styled(ButtonComponent).attrs({
  style: {
    height: 25,
    width: 100,
    fontSize: 14,
    marginTop: 10,
    marginRight: 10,
    alignSelf: 'flex-end',
  },
  color: colors.danger,
  type: 'button',
})``;
