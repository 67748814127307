/* eslint-disable no-console */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FaPaperPlane } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import ChatMessageItem from './components/ChatMessageItem';
import ChatFriendItem from './components/ChatFriendItem';
import {
  chatMessageRequest,
  chatSendMessage,
  chatChannelsSetReadRequest,
} from '~/store/modules/chat/actions';
import { getValidationErrors } from '~/utils';

import {
  Container,
  LeftPanel,
  RightPanel,
  ChatMessageBox,
  SendMessage,
  MessagesChatBox,
  Unform,
  Textarea,
  Modal,
  SearchBox,
} from './styles';

const schema = Yup.object().shape({
  message: Yup.string().required('A mensagem é obrigatória!'),
});
export default function ModalChat({ onClose }) {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const { channels, messages, channelSelectedId } = useSelector(
    state => state.chat
  );
  const { profile } = useSelector(state => state.user);
  const [value, setValue] = useState('');

  const handleChatScrollOnBottom = useCallback(() => {
    if (messages.length > 0) {
      const lasMessage = messages[messages.length - 1];
      if (lasMessage.user.id !== profile.id) {
        dispatch(chatChannelsSetReadRequest(channelSelectedId));
      }
    }
  }, [channelSelectedId, messages, profile]);

  const chatRef = useBottomScrollListener(handleChatScrollOnBottom);

  useEffect(() => {
    if (chatRef && messages && channelSelectedId) {
      if (chatRef.current.scrollHeight === chatRef.current.clientHeight) {
        handleChatScrollOnBottom();
      } else {
        chatRef.current.scrollTop =
          chatRef.current.scrollHeight - chatRef.current.clientHeight;
      }
    }
  }, [chatRef, messages, channelSelectedId]);

  const handleSubmitMessage = ({ message }) => {
    dispatch(
      chatSendMessage({
        userChannelId: channelSelectedId,
        message,
        type: 'text',
      })
    );
    formRef.current.reset();
  };

  const getFriend = channel => {
    if (profile.id === channel.user1_id) {
      return channel.user2;
    }
    if (profile.id === channel.user2_id) {
      return channel.user1;
    }
    return null;
  };

  const getBadge = channel => {
    if (profile.id === channel.user1_id) {
      return channel.unread_user1;
    }
    if (profile.id === channel.user2_id) {
      return channel.unread_user2;
    }
    return null;
  };

  const renderLeftPanel = () => (
    <LeftPanel>
      <SearchBox value={value} onChange={val => setValue(val)} />
      <ChatMessageBox>
        {channels.map(item => (
          <ChatFriendItem
            key={item.id}
            friend={getFriend(item)}
            badge={getBadge(item)}
            active={item.id === channelSelectedId}
            lastUpdated={moment(item.updated_at).format('DD/MM/YYYY HH:mm')}
            onClick={() => {
              dispatch(chatMessageRequest(item.id));
            }}
          />
        ))}
      </ChatMessageBox>
    </LeftPanel>
  );

  const renderRightPanel = () => (
    <RightPanel>
      <MessagesChatBox ref={chatRef}>
        {messages.map(item => (
          <ChatMessageItem
            key={item.id}
            itsMe={item.user_id === profile.id}
            message={item}
          />
        ))}
      </MessagesChatBox>
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmitMessage(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        ref={formRef}
      >
        <Textarea
          type="text"
          placeholder="Mensagem..."
          name="message"
          autoFocus
          onKeyPress={e => {
            if (e.keyCode === 13 || e.which === 13) {
              formRef.current.submitForm();
            }
          }}
        />
        <SendMessage
          id="submit-message-chat"
          type="submit"
          title="Enviar"
          icon={FaPaperPlane}
          gradient
        />
      </Unform>
    </RightPanel>
  );

  return (
    <Modal
      visible
      width="70%"
      height="70%"
      effect="fadeInUp"
      title="Conversas"
      onClose={() => {
        onClose();
      }}
    >
      <Container>
        {renderLeftPanel()}
        {!!channelSelectedId && renderRightPanel()}
      </Container>
    </Modal>
  );
}

ModalChat.defaultProps = {
  onClose: () => {},
};

ModalChat.propTypes = {
  onClose: PropTypes.func,
};
