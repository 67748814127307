/* eslint-disable no-console */
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { FaSignature } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';

import {
  driveUpdateFolderRequest,
  driveFolderSetEdit,
} from '~/store/modules/drive/actions';
import { getValidationErrors } from '~/utils';

import { ButtonSave, Unform, UnformInput } from './styles';

export default function ModalEdit() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { folderEdit } = useSelector(state => state.drive);

  const schema = Yup.object().shape({
    title: Yup.string().required(
      t('drive.modal-edit-folder.field.title.message.required')
    ),
  });

  const handleSubmit = drive => {
    dispatch(
      driveUpdateFolderRequest({
        ...folderEdit,
        title: drive.title,
      })
    );
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('drive.modal-edit-folder.title')}
      onClose={() => {
        dispatch(driveFolderSetEdit(null));
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={folderEdit}
        ref={formRef}
      >
        <UnformInput
          icon={FaSignature}
          type="text"
          label={t('drive.modal-edit-folder.field.title.label')}
          name="title"
          autoFocus
        />
        <ButtonSave
          type="submit"
          title={t('drive.modal-edit-folder.save')}
          gradient
        />
      </Unform>
    </Modal>
  );
}
