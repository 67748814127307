import React, { useEffect } from 'react';
import { Switch, Route as RouteComponent } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { signOut } from '~/store/modules/auth/actions';

import Route from './Route';

// Public pages
import SignIn from '~/pages/Auth/SignIn';
import SignUp from '~/pages/Auth/SignUp';
import Forgot from '~/pages/Auth/Forgot';

// Private Pages
import Main from '~/pages/Main';
import Friends from '~/pages/Friends';
import Help from '~/pages/Help';
import Profile from '~/pages/Profile';
import Periods from '~/pages/Periods';
import Subjects from '~/pages/Subjects';
import Classes from '~/pages/Classes';
import ClassDetails from '~/pages/Classes/Pages/Details';
import Agenda from '~/pages/Agenda';
import Certificates from '~/pages/Certificates';
import Drive from '~/pages/Drive';
import SharedWithMe from '~/pages/SharedWithMe';
import StudyContent from '~/pages/StudyContent';
import StudyForTest from '~/pages/StudyForTest';
import Settings from '~/pages/Settings';
import MyPlan from '~/pages/MyPlan';

const SignOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(signOut());
  });

  return <div />;
};

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/signIn" component={SignIn} />
      <Route exact path="/signUp" component={SignUp} />
      <Route exact path="/forgot" component={Forgot} />
      <Route exact path="/" component={Main} isPrivate />
      <Route exact path="/friends" component={Friends} isPrivate />
      <Route exact path="/help" component={Help} isPrivate />
      <Route exact path="/profile" component={Profile} isPrivate />
      <Route exact path="/periods" component={Periods} isPrivate />
      <Route exact path="/subjects/:periodId" component={Subjects} isPrivate />
      <Route
        exact
        path="/classes/:periodId/:subjectId"
        component={Classes}
        isPrivate
      />
      <Route
        exact
        path="/classes/:periodId/:subjectId/:classId"
        component={ClassDetails}
        isPrivate
      />
      <Route exact path="/agenda" component={Agenda} isPrivate />
      <Route exact path="/certificates" component={Certificates} isPrivate />
      <Route exact path="/drive" component={Drive} isPrivate />
      <Route exact path="/shared-with-me" component={SharedWithMe} isPrivate />
      <Route
        exact
        path="/study-content/:testId"
        component={StudyContent}
        isPrivate
      />
      <Route exact path="/study-for-test" component={StudyForTest} isPrivate />
      <Route exact path="/settings" component={Settings} isPrivate />
      <Route exact path="/my-plan" component={MyPlan} isPrivate />
      <RouteComponent exact path="/signOut" component={SignOut} />
    </Switch>
  );
}
