import React, { useRef } from 'react';
import * as Yup from 'yup';
import { RiCoupon3Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '~/components/Modal';

import {
  useCoupon as checkCoupon,
  myPlanSetState,
} from '~/store/modules/myPlan/actions';

import { getValidationErrors } from '~/utils';

import { Unform, UnformInput, ButtonSave } from './styles';

export default function ModalCoupon() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { couponForm } = useSelector(state => state.myPlan);

  const schema = Yup.object().shape({
    couponToken: Yup.string().required(
      t('my-plan.modal-coupon.field.couponToken.required')
    ),
  });

  const handleSubmit = async data => {
    dispatch(checkCoupon(data));
    // formRef.current.reset();
  };

  const renderForm = () => (
    <Unform
      onSubmit={async data => {
        try {
          formRef.current.setErrors({});
          await schema.validate(data, { abortEarly: false });
          handleSubmit(data);
        } catch (err) {
          formRef.current.setErrors(getValidationErrors(err));
        }
      }}
      initialData={couponForm}
      ref={formRef}
    >
      <UnformInput
        icon={RiCoupon3Line}
        type="text"
        label={t('my-plan.modal-coupon.field.couponToken.label')}
        name="couponToken"
        autoFocus
        style={{ marginRight: 20 }}
      />
      <ButtonSave type="submit" title={t('my-plan.modal-coupon.use')} />
    </Unform>
  );

  return (
    <Modal
      visible
      width="350px"
      height="auto"
      effect="fadeInUp"
      title={t('my-plan.modal-coupon.title')}
      onClose={() => {
        dispatch(myPlanSetState({ couponForm: null }));
      }}
    >
      {renderForm()}
    </Modal>
  );
}

ModalCoupon.propTypes = {};
