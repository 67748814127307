import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformInputComponent from '~/components/Unform/Input';
import CheckboxGroupComponent from '~/components/Unform/CheckboxGroup';

import ButtonComponent from '~/components/Button';
import colors from '~/styles/colors';

export const ButtonSave = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    marginTop: 30,
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  > h2 {
    margin-top: 20px;
    color: ${colors.white};
    font-size: 25px;
    font-weight: 300;
  }
  > small {
    margin-top: 10px;
    color: ${colors.white};
    font-size: 16px;
    font-weight: 300;
  }
`;

export const UnformInput = styled(UnformInputComponent)``;

export const CheckboxGroup = styled(CheckboxGroupComponent)`
  margin-top: 10px;
`;
