import styled from 'styled-components';
import colors from '~/styles/colors';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: ${colors.primary};
  background: linear-gradient(
    90deg,
    ${colors.accent2} -20%,
    ${colors.accent1} 120%
  );
`;

export const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 240px;
  min-width: 240px;
`;

export const ContentPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow: auto;
  position: relative;
`;

export const BarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  img {
    height: 30px;
    object-fit: contain;
  }
`;
