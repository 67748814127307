import types from './types';

export function agendaRequest() {
  return {
    type: types.AGENDA_REQUEST,
  };
}

export function agendaRequestSuccess(events) {
  return {
    type: types.AGENDA_REQUEST_SUCCESS,
    payload: { events },
  };
}

export function createAgendaRequest(event) {
  return {
    type: types.AGENDA_CREATE,
    payload: { event },
  };
}

export function updateAgendaRequest(event) {
  return {
    type: types.AGENDA_UPDATE,
    payload: { event },
  };
}

export function deleteAgendaRequest(eventId) {
  return {
    type: types.AGENDA_DELETE,
    payload: { eventId },
  };
}

export function agendaSetUpdate(eventEdit) {
  return {
    type: types.AGENDA_SET_UPDATE,
    payload: { eventEdit },
  };
}

export function agendaSetModalCreate(eventModalCreate) {
  return {
    type: types.AGENDA_SET_MODAL_CREATE,
    payload: { eventModalCreate },
  };
}
