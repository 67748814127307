import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa';

import Modal from '~/components/Modal';
import { getValidationErrors } from '~/utils';
import { friendRequest } from '~/store/modules/friend/actions';
import { sharedCreate } from '~/store/modules/shared/actions';

import { Unform, ButtonSave, UnformSelect } from './styles';

export default function ModalShare({ onClose, driveToShare }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef(null);
  const { friends } = useSelector(state => state.friend);

  const schema = Yup.object().shape({
    friend: Yup.string().required(
      t('drive.modal-share.field.friend.message.required')
    ),
  });

  useEffect(() => {
    dispatch(friendRequest());
  }, []);

  const handleSubmit = share => {
    const dataPost = {
      user_id_to: share.friend,
      content_id: driveToShare,
      type: 'drive',
      config: {},
    };
    dispatch(sharedCreate(dataPost));
    onClose();
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('drive.modal-share.title')}
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formRef}
      >
        <UnformSelect
          icon={FaUser}
          label={t('drive.modal-share.field.friend.label')}
          name="friend"
          options={friends.map(item => ({ value: item.id, label: item.name }))}
        />
        <ButtonSave
          type="submit"
          title={t('drive.modal-share.share')}
          gradient
        />
      </Unform>
    </Modal>
  );
}

ModalShare.defaultProps = {
  onClose: () => {},
  driveToShare: '',
};

ModalShare.propTypes = {
  onClose: PropTypes.func,
  driveToShare: PropTypes.string,
};
