import types from './types';

export function driveRequest(driveId) {
  return {
    type: types.DRIVE_REQUEST,
    payload: { driveId },
  };
}

export function driveRequestSuccess(drive) {
  return {
    type: types.DRIVE_REQUEST_SUCCESS,
    payload: { drive },
  };
}

export function driveSetModalFolderCreate(driveModalFolderCreate) {
  return {
    type: types.DRIVE_SET_MODAL_FOLDER_CREATE,
    payload: { driveModalFolderCreate },
  };
}

export function driveCreateFolderRequest(folder) {
  return {
    type: types.DRIVE_CREATE_FOLDER_REQUEST,
    payload: { folder },
  };
}

export function driveSetModalFileCreate(driveModalFileCreate) {
  return {
    type: types.DRIVE_SET_MODAL_FILE_CREATE,
    payload: { driveModalFileCreate },
  };
}

export function driveCreateFileRequest(file) {
  return {
    type: types.DRIVE_CREATE_FILE_REQUEST,
    payload: { file },
  };
}

export function driveUpdateFolderRequest(folder) {
  return {
    type: types.DRIVE_UPDATE_FOLDER_REQUEST,
    payload: { folder },
  };
}

export function driveFolderSetEdit(folderEdit) {
  return {
    type: types.DRIVE_FOLDER_SET_EDIT,
    payload: { folderEdit },
  };
}

export function driveDeleteRequest(drive) {
  return {
    type: types.DRIVE_DELETE_REQUEST,
    payload: { drive },
  };
}
