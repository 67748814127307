import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import CheckboxGroupComponent from '~/components/Unform/CheckboxGroup';
import UnformInputMaskComponent from '~/components/Unform/InputMask';
import UnformSelectComponent from '~/components/Unform/Select';

import ButtonComponent from '~/components/Button';
import colors from '~/styles/colors';

export const ButtonSave = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    marginTop: 20,
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  > h2 {
    margin: 20px 0;
    color: ${colors.white};
    font-size: 20px;
    font-weight: 300;
  }
  > small {
    margin-top: 10px;
    color: ${colors.white};
    font-size: 16px;
    font-weight: 300;
  }
`;

export const UnformCheckboxGroup = styled(CheckboxGroupComponent)`
  margin-bottom: 10px;
`;

export const UnformInputMask = styled(UnformInputMaskComponent)``;

export const UnformSelect = styled(UnformSelectComponent)``;
