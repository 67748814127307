/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';

import { Container, InputTextarea } from './styles';

const Textarea = ({ name, className, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [inputIsFocused, setInputIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  // new-password
  return (
    <Container className={className} isFocused={inputIsFocused || !!inputValue}>
      <InputTextarea
        ref={inputRef}
        type="text"
        rows={3}
        {...rest}
        name={name}
        defaultValue={inputValue}
        onBlur={() => setInputIsFocused(false)}
        onFocus={() => setInputIsFocused(true)}
        onChange={e => setInputValue(e.target.value)}
      />
      {error && <span className="error">{error}</span>}
    </Container>
  );
};

Textarea.defaultProps = {
  label: '',
  name: '',
  icon: null,
  style: null,
};

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.func,
  style: PropTypes.shape(),
  className: PropTypes.string.isRequired,
};

export default Textarea;
