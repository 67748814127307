/* eslint-disable no-console */
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Modal from '~/components/Modal';
import { studyContentExportPdf } from '~/store/modules/studyContent/actions';
import { getValidationErrors } from '~/utils';

import { ButtonExport, Unform, UnformCheckbox } from './styles';

const schema = Yup.object().shape({
  subjects: Yup.bool(),
  classes: Yup.bool(),
  classKeywords: Yup.bool(),
  classQuestions: Yup.bool(),
});

export default function ModalShare({ onClose, testToExport }) {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  const [formInputs, setFormInputs] = useState({
    classes: true,
    classKeywords: true,
    classQuestions: true,
  });

  const handleSubmit = share => {
    const dataPost = {
      subject_test_id: testToExport,
      classes: share.classes,
      classKeywords: share.classKeywords,
      classQuestions: share.classQuestions,
    };
    dispatch(studyContentExportPdf(dataPost));
    onClose();
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title="Gerar PDF"
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={formInputs}
        ref={formRef}
      >
        <UnformCheckbox
          disabled={false}
          name="classes"
          label="Aulas"
          checked={formInputs.classes}
          onChange={evnt => {
            setFormInputs({
              ...formInputs,
              classes: evnt.target.checked,
            });
          }}
        />
        <UnformCheckbox
          disabled={false}
          name="classKeywords"
          label="Palavras-chave"
          checked={formInputs.classKeywords}
          onChange={evnt => {
            setFormInputs({
              ...formInputs,
              classKeywords: evnt.target.checked,
            });
          }}
        />
        <UnformCheckbox
          disabled={false}
          name="classQuestions"
          label="Perguntas"
          checked={formInputs.classQuestions}
          onChange={evnt => {
            setFormInputs({
              ...formInputs,
              classQuestions: evnt.target.checked,
            });
          }}
        />
        <small>** Selecione acima o que deseja exportar no PDF.</small>
        <ButtonExport type="submit" title="Gerar" gradient />
      </Unform>
    </Modal>
  );
}

ModalShare.defaultProps = {
  onClose: () => {},
  testToExport: '',
};

ModalShare.propTypes = {
  onClose: PropTypes.func,
  testToExport: PropTypes.string,
};
