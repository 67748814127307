/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Proptypes from 'prop-types';
import { OpType } from '@blink-mind/core';
import { FOCUS_MODE_SEARCH } from '@blink-mind/plugins';
import { BsSearch } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const ToolbarItemSearch = ({ diagram }) => {
  const { t } = useTranslation();
  const onClickSearch = () => {
    const diagramProps = diagram.getDiagramProps();
    const { controller } = diagramProps;

    controller.run('operation', {
      ...diagramProps,
      opType: OpType.SET_FOCUS_MODE,
      focusMode: FOCUS_MODE_SEARCH,
    });
  };
  return (
    <button
      type="button"
      className="toolbar-menu-item"
      onClick={onClickSearch}
      data-tip={t('class-view.modal-mindmaps.toolbar.buttonSearch')}
    >
      <BsSearch />
    </button>
  );
};

ToolbarItemSearch.propTypes = {
  diagram: Proptypes.shape().isRequired,
};

export default ToolbarItemSearch;
