import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformCheckboxComponent from '~/components/Unform/Checkbox';
import UnformSelectComponent from '~/components/Unform/Select';

import colors from '~/styles/colors';
import ButtonComponent from '~/components/Button';

export const PeriodSave = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  style: {
    marginTop: 30,
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  small {
    margin-top: 10px;
    color: ${colors.white};
    font-size: 16px;
    font-weight: 300;
  }
`;

export const UnformCheckbox = styled(UnformCheckboxComponent)`
  margin-bottom: 10px;
`;

export const UnformSelect = styled(UnformSelectComponent)``;
