export default [
  {
    label: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    unCode: '004',
  },
  {
    label: 'ALA	Aland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
    unCode: '248',
  },
  {
    label: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
    unCode: '008',
  },
  {
    label: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
    unCode: '012',
  },
  {
    label: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
    unCode: '016',
  },
  {
    label: 'Andorra',
    alpha2: 'AD',
    alpha3: 'AND',
    unCode: '020',
  },
  {
    label: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
    unCode: '024',
  },
  {
    label: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
    unCode: '660',
  },
  {
    label: 'Antarctica',
    alpha2: 'AQ',
    alpha3: 'ATA',
    unCode: '010',
  },
  {
    label: 'Antigua and Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
    unCode: '028',
  },
  {
    label: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
    unCode: '032',
  },
  {
    label: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
    unCode: '051',
  },
  {
    label: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
    unCode: '533',
  },
  {
    label: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    unCode: '036',
  },
  {
    label: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    unCode: '040',
  },
  {
    label: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
    unCode: '031',
  },
  {
    label: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
    unCode: '044',
  },
  {
    label: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    unCode: '048',
  },
  {
    label: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
    unCode: '050',
  },
  {
    label: 'Barbados',
    alpha2: 'BB',
    alpha3: 'BRB',
    unCode: '052',
  },
  {
    label: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
    unCode: '112',
  },
  {
    label: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    unCode: '056',
  },
  {
    label: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    unCode: '084',
  },
  {
    label: 'Benin',
    alpha2: 'BJ',
    alpha3: 'BEN',
    unCode: '204',
  },
  {
    label: 'Bermuda',
    alpha2: 'BM',
    alpha3: 'BMU',
    unCode: '060',
  },
  {
    label: 'Bhutan',
    alpha2: 'BT',
    alpha3: 'BTN',
    unCode: '064',
  },
  {
    label: 'Bolivia',
    alpha2: 'BO',
    alpha3: 'BOL',
    unCode: '068',
  },
  {
    label: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
    unCode: '070',
  },
  {
    label: 'Botswana',
    alpha2: 'BW',
    alpha3: 'BWA',
    unCode: '072',
  },
  {
    label: 'Bouvet Island',
    alpha2: 'BV',
    alpha3: 'BVT',
    unCode: '074',
  },
  {
    label: 'Brasil',
    alpha2: 'BR',
    alpha3: 'BRA',
    unCode: '076',
  },
  {
    label: 'British Virgin Islands',
    alpha2: 'VG',
    alpha3: 'VGB',
    unCode: '092',
  },
  {
    label: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
    unCode: '086',
  },
  {
    label: 'Brunei Darussalam',
    alpha2: 'BN',
    alpha3: 'BRN',
    unCode: '096',
  },
  {
    label: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    unCode: '100',
  },
  {
    label: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
    unCode: '854',
  },
  {
    label: 'Burundi',
    alpha2: 'BI',
    alpha3: 'BDI',
    unCode: '108',
  },
  {
    label: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
    unCode: '116',
  },
  {
    label: 'Cameroon',
    alpha2: 'CM',
    alpha3: 'CMR',
    unCode: '120',
  },
  {
    label: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    unCode: '124',
  },
  {
    label: 'Cape Verde',
    alpha2: 'CV',
    alpha3: 'CPV',
    unCode: '132',
  },
  {
    label: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
    unCode: '136',
  },
  {
    label: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
    unCode: '140',
  },
  {
    label: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
    unCode: '148',
  },
  {
    label: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
    unCode: '152',
  },
  {
    label: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
    unCode: '156',
  },
  {
    label: 'Hong Kong, SAR China',
    alpha2: 'HK',
    alpha3: 'HKG',
    unCode: '344',
  },
  {
    label: 'Macao, SAR China',
    alpha2: 'MO',
    alpha3: 'MAC',
    unCode: '446',
  },
  {
    label: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
    unCode: '162',
  },
  {
    label: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
    unCode: '166',
  },
  {
    label: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
    unCode: '170',
  },
  {
    label: 'Comoros',
    alpha2: 'KM',
    alpha3: 'COM',
    unCode: '174',
  },
  {
    label: 'Congo (Brazzaville)',
    alpha2: 'CG',
    alpha3: 'COG',
    unCode: '178',
  },
  {
    label: 'Congo, (Kinshasa)',
    alpha2: 'CD',
    alpha3: 'COD',
    unCode: '180',
  },
  {
    label: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
    unCode: '184',
  },
  {
    label: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
    unCode: '188',
  },
  {
    label: "Côte d'Ivoire",
    alpha2: 'CI',
    alpha3: 'CIV',
    unCode: '384',
  },
  {
    label: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    unCode: '191',
  },
  {
    label: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
    unCode: '192',
  },
  {
    label: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    unCode: '196',
  },
  {
    label: 'Czech Republic',
    alpha2: 'CZ',
    alpha3: 'CZE',
    unCode: '203',
  },
  {
    label: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    unCode: '208',
  },
  {
    label: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
    unCode: '262',
  },
  {
    label: 'Dominica',
    alpha2: 'DM',
    alpha3: 'DMA',
    unCode: '212',
  },
  {
    label: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
    unCode: '214',
  },
  {
    label: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
    unCode: '218',
  },
  {
    label: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
    unCode: '818',
  },
  {
    label: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    unCode: '222',
  },
  {
    label: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
    unCode: '226',
  },
  {
    label: 'Eritrea',
    alpha2: 'ER',
    alpha3: 'ERI',
    unCode: '232',
  },
  {
    label: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    unCode: '233',
  },
  {
    label: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
    unCode: '231',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    alpha3: 'FLK',
    unCode: '238',
  },
  {
    label: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
    unCode: '234',
  },
  {
    label: 'Fiji',
    alpha2: 'FJ',
    alpha3: 'FJI',
    unCode: '242',
  },
  {
    label: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    unCode: '246',
  },
  {
    label: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    unCode: '250',
  },
  {
    label: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
    unCode: '254',
  },
  {
    label: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
    unCode: '258',
  },
  {
    label: 'French Southern Territories',
    alpha2: 'TF',
    alpha3: 'ATF',
    unCode: '260',
  },
  {
    label: 'Gabon',
    alpha2: 'GA',
    alpha3: 'GAB',
    unCode: '266',
  },
  {
    label: 'Gambia',
    alpha2: 'GM',
    alpha3: 'GMB',
    unCode: '270',
  },
  {
    label: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
    unCode: '268',
  },
  {
    label: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    unCode: '276',
  },
  {
    label: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
    unCode: '288',
  },
  {
    label: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
    unCode: '292',
  },
  {
    label: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    unCode: '300',
  },
  {
    label: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
    unCode: '304',
  },
  {
    label: 'Grenada',
    alpha2: 'GD',
    alpha3: 'GRD',
    unCode: '308',
  },
  {
    label: 'Guadeloupe',
    alpha2: 'GP',
    alpha3: 'GLP',
    unCode: '312',
  },
  {
    label: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
    unCode: '316',
  },
  {
    label: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
    unCode: '320',
  },
  {
    label: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
    unCode: '831',
  },
  {
    label: 'Guinea',
    alpha2: 'GN',
    alpha3: 'GIN',
    unCode: '324',
  },
  {
    label: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
    unCode: '624',
  },
  {
    label: 'Guyana',
    alpha2: 'GY',
    alpha3: 'GUY',
    unCode: '328',
  },
  {
    label: 'Haiti',
    alpha2: 'HT',
    alpha3: 'HTI',
    unCode: '332',
  },
  {
    label: 'Heard and Mcdonald Islands',
    alpha2: 'HM',
    alpha3: 'HMD',
    unCode: '334',
  },
  {
    label: 'Holy See (Vatican City State)',
    alpha2: 'VA',
    alpha3: 'VAT',
    unCode: '336',
  },
  {
    label: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
    unCode: '340',
  },
  {
    label: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    unCode: '348',
  },
  {
    label: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
    unCode: '352',
  },
  {
    label: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    unCode: '356',
  },
  {
    label: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
    unCode: '360',
  },
  {
    label: 'Iran, Islamic Republic of',
    alpha2: 'IR',
    alpha3: 'IRN',
    unCode: '364',
  },
  {
    label: 'Iraq',
    alpha2: 'IQ',
    alpha3: 'IRQ',
    unCode: '368',
  },
  {
    label: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    unCode: '372',
  },
  {
    label: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
    unCode: '833',
  },
  {
    label: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    unCode: '376',
  },
  {
    label: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    unCode: '380',
  },
  {
    label: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
    unCode: '388',
  },
  {
    label: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    unCode: '392',
  },
  {
    label: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
    unCode: '832',
  },
  {
    label: 'Jordan',
    alpha2: 'JO',
    alpha3: 'JOR',
    unCode: '400',
  },
  {
    label: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    unCode: '398',
  },
  {
    label: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
    unCode: '404',
  },
  {
    label: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
    unCode: '296',
  },
  {
    label: 'Korea (North)',
    alpha2: 'KP',
    alpha3: 'PRK',
    unCode: '408',
  },
  {
    label: 'Korea (South)',
    alpha2: 'KR',
    alpha3: 'KOR',
    unCode: '410',
  },
  {
    label: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    unCode: '414',
  },
  {
    label: 'Kyrgyzstan',
    alpha2: 'KG',
    alpha3: 'KGZ',
    unCode: '417',
  },
  {
    label: 'Lao PDR',
    alpha2: 'LA',
    alpha3: 'LAO',
    unCode: '418',
  },
  {
    label: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    unCode: '428',
  },
  {
    label: 'Lebanon',
    alpha2: 'LB',
    alpha3: 'LBN',
    unCode: '422',
  },
  {
    label: 'Lesotho',
    alpha2: 'LS',
    alpha3: 'LSO',
    unCode: '426',
  },
  {
    label: 'Liberia',
    alpha2: 'LR',
    alpha3: 'LBR',
    unCode: '430',
  },
  {
    label: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
    unCode: '434',
  },
  {
    label: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
    unCode: '438',
  },
  {
    label: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    unCode: '440',
  },
  {
    label: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    unCode: '442',
  },
  {
    label: 'Macedonia, Republic of',
    alpha2: 'MK',
    alpha3: 'MKD',
    unCode: '807',
  },
  {
    label: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
    unCode: '450',
  },
  {
    label: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
    unCode: '454',
  },
  {
    label: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
    unCode: '458',
  },
  {
    label: 'Maldives',
    alpha2: 'MV',
    alpha3: 'MDV',
    unCode: '462',
  },
  {
    label: 'Mali',
    alpha2: 'ML',
    alpha3: 'MLI',
    unCode: '466',
  },
  {
    label: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    unCode: '470',
  },
  {
    label: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
    unCode: '584',
  },
  {
    label: 'Martinique',
    alpha2: 'MQ',
    alpha3: 'MTQ',
    unCode: '474',
  },
  {
    label: 'Mauritania',
    alpha2: 'MR',
    alpha3: 'MRT',
    unCode: '478',
  },
  {
    label: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
    unCode: '480',
  },
  {
    label: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
    unCode: '175',
  },
  {
    label: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    unCode: '484',
  },
  {
    label: 'Micronesia, Federated States of',
    alpha2: 'FM',
    alpha3: 'FSM',
    unCode: '583',
  },
  {
    label: 'Moldova',
    alpha2: 'MD',
    alpha3: 'MDA',
    unCode: '498',
  },
  {
    label: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    unCode: '492',
  },
  {
    label: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
    unCode: '496',
  },
  {
    label: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
    unCode: '499',
  },
  {
    label: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
    unCode: '500',
  },
  {
    label: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
    unCode: '504',
  },
  {
    label: 'Mozambique',
    alpha2: 'MZ',
    alpha3: 'MOZ',
    unCode: '508',
  },
  {
    label: 'Myanmar',
    alpha2: 'MM',
    alpha3: 'MMR',
    unCode: '104',
  },
  {
    label: 'Namibia',
    alpha2: 'NA',
    alpha3: 'NAM',
    unCode: '516',
  },
  {
    label: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
    unCode: '520',
  },
  {
    label: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
    unCode: '524',
  },
  {
    label: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    unCode: '528',
  },
  {
    label: 'Netherlands Antilles',
    alpha2: 'AN',
    alpha3: 'ANT',
    unCode: '530',
  },
  {
    label: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
    unCode: '540',
  },
  {
    label: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    unCode: '554',
  },
  {
    label: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
    unCode: '558',
  },
  {
    label: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
    unCode: '562',
  },
  {
    label: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
    unCode: '566',
  },
  {
    label: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
    unCode: '570',
  },
  {
    label: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
    unCode: '574',
  },
  {
    label: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
    unCode: '580',
  },
  {
    label: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    unCode: '578',
  },
  {
    label: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    unCode: '512',
  },
  {
    label: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
    unCode: '586',
  },
  {
    label: 'Palau',
    alpha2: 'PW',
    alpha3: 'PLW',
    unCode: '585',
  },
  {
    label: 'Palestinian Territory',
    alpha2: 'PS',
    alpha3: 'PSE',
    unCode: '275',
  },
  {
    label: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
    unCode: '591',
  },
  {
    label: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
    unCode: '598',
  },
  {
    label: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
    unCode: '600',
  },
  {
    label: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
    unCode: '604',
  },
  {
    label: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
    unCode: '608',
  },
  {
    label: 'Pitcairn',
    alpha2: 'PN',
    alpha3: 'PCN',
    unCode: '612',
  },
  {
    label: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    unCode: '616',
  },
  {
    label: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    unCode: '620',
  },
  {
    label: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    unCode: '630',
  },
  {
    label: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    unCode: '634',
  },
  {
    label: 'Réunion',
    alpha2: 'RE',
    alpha3: 'REU',
    unCode: '638',
  },
  {
    label: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    unCode: '642',
  },
  {
    label: 'Russian Federation',
    alpha2: 'RU',
    alpha3: 'RUS',
    unCode: '643',
  },
  {
    label: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
    unCode: '646',
  },
  {
    label: 'Saint-Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
    unCode: '652',
  },
  {
    label: 'Saint Helena',
    alpha2: 'SH',
    alpha3: 'SHN',
    unCode: '654',
  },
  {
    label: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
    unCode: '659',
  },
  {
    label: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
    unCode: '662',
  },
  {
    label: 'Saint-Martin (French part)',
    alpha2: 'MF',
    alpha3: 'MAF',
    unCode: '663',
  },
  {
    label: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
    unCode: '666',
  },
  {
    label: 'Saint Vincent and Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
    unCode: '670',
  },
  {
    label: 'Samoa',
    alpha2: 'WS',
    alpha3: 'WSM',
    unCode: '882',
  },
  {
    label: 'San Marino',
    alpha2: 'SM',
    alpha3: 'SMR',
    unCode: '674',
  },
  {
    label: 'Sao Tome and Principe',
    alpha2: 'ST',
    alpha3: 'STP',
    unCode: '678',
  },
  {
    label: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
    unCode: '682',
  },
  {
    label: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
    unCode: '686',
  },
  {
    label: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
    unCode: '688',
  },
  {
    label: 'Seychelles',
    alpha2: 'SC',
    alpha3: 'SYC',
    unCode: '690',
  },
  {
    label: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
    unCode: '694',
  },
  {
    label: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    unCode: '702',
  },
  {
    label: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    unCode: '703',
  },
  {
    label: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    unCode: '705',
  },
  {
    label: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
    unCode: '090',
  },
  {
    label: 'Somalia',
    alpha2: 'SO',
    alpha3: 'SOM',
    unCode: '706',
  },
  {
    label: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    unCode: '710',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
    unCode: '239',
  },
  {
    label: 'South Sudan',
    alpha2: 'SS',
    alpha3: 'SSD',
    unCode: '728',
  },
  {
    label: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    unCode: '724',
  },
  {
    label: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    unCode: '144',
  },
  {
    label: 'Sudan',
    alpha2: 'SD',
    alpha3: 'SDN',
    unCode: '736',
  },
  {
    label: 'Suriname',
    alpha2: 'SR',
    alpha3: 'SUR',
    unCode: '740',
  },
  {
    label: 'Svalbard and Jan Mayen Islands',
    alpha2: 'SJ',
    alpha3: 'SJM',
    unCode: '744',
  },
  {
    label: 'Swaziland',
    alpha2: 'SZ',
    alpha3: 'SWZ',
    unCode: '748',
  },
  {
    label: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    unCode: '752',
  },
  {
    label: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    unCode: '756',
  },
  {
    label: 'Syrian Arab Republic (Syria)',
    alpha2: 'SY',
    alpha3: 'SYR',
    unCode: '760',
  },
  {
    label: 'Taiwan, Republic of China',
    alpha2: 'TW',
    alpha3: 'TWN',
    unCode: '158',
  },
  {
    label: 'Tajikistan',
    alpha2: 'TJ',
    alpha3: 'TJK',
    unCode: '762',
  },
  {
    label: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    alpha3: 'TZA',
    unCode: '834',
  },
  {
    label: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
    unCode: '764',
  },
  {
    label: 'Timor-Leste',
    alpha2: 'TL',
    alpha3: 'TLS',
    unCode: '626',
  },
  {
    label: 'Togo',
    alpha2: 'TG',
    alpha3: 'TGO',
    unCode: '768',
  },
  {
    label: 'Tokelau',
    alpha2: 'TK',
    alpha3: 'TKL',
    unCode: '772',
  },
  {
    label: 'Tonga',
    alpha2: 'TO',
    alpha3: 'TON',
    unCode: '776',
  },
  {
    label: 'Trinidad and Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
    unCode: '780',
  },
  {
    label: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
    unCode: '788',
  },
  {
    label: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
    unCode: '792',
  },
  {
    label: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
    unCode: '795',
  },
  {
    label: 'Turks and Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
    unCode: '796',
  },
  {
    label: 'Tuvalu',
    alpha2: 'TV',
    alpha3: 'TUV',
    unCode: '798',
  },
  {
    label: 'Uganda',
    alpha2: 'UG',
    alpha3: 'UGA',
    unCode: '800',
  },
  {
    label: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
    unCode: '804',
  },
  {
    label: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    unCode: '784',
  },
  {
    label: 'United Kingdom',
    alpha2: 'GB',
    alpha3: 'GBR',
    unCode: '826',
  },
  {
    label: 'United States of America',
    alpha2: 'US',
    alpha3: 'USA',
    unCode: '840',
  },
  {
    label: 'US Minor Outlying Islands',
    alpha2: 'UM',
    alpha3: 'UMI',
    unCode: '581',
  },
  {
    label: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
    unCode: '858',
  },
  {
    label: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
    unCode: '860',
  },
  {
    label: 'Vanuatu',
    alpha2: 'VU',
    alpha3: 'VUT',
    unCode: '548',
  },
  {
    label: 'Venezuela (Bolivarian Republic)',
    alpha2: 'VE',
    alpha3: 'VEN',
    unCode: '862',
  },
  {
    label: 'Viet Nam',
    alpha2: 'VN',
    alpha3: 'VNM',
    unCode: '704',
  },
  {
    label: 'Virgin Islands, US',
    alpha2: 'VI',
    alpha3: 'VIR',
    unCode: '850',
  },
  {
    label: 'Wallis and Futuna Islands',
    alpha2: 'WF',
    alpha3: 'WLF',
    unCode: '876',
  },
  {
    label: 'Western Sahara',
    alpha2: 'EH',
    alpha3: 'ESH',
    unCode: '732',
  },
  {
    label: 'Yemen',
    alpha2: 'YE',
    alpha3: 'YEM',
    unCode: '887',
  },
  {
    label: 'Zambia',
    alpha2: 'ZM',
    alpha3: 'ZMB',
    unCode: '894',
  },
  {
    label: 'Zimbabwe',
    alpha2: 'ZW',
    alpha3: 'ZWE',
    unCode: '716',
  },
];
