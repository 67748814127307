import styled from 'styled-components';

import { Form as UnformComponent } from '@unform/web';
import UnformInputFileComponent from '~/components/Unform/InputFile';
import UnformInputComponent from '~/components/Unform/Input';
import UnformSelectComponent from '~/components/Unform/Select';

import ButtonComponent from '~/components/Button';
import AvatarComponent from '~/components/Avatar';
import colors from '~/styles/colors';

export const HeaderButton = styled(ButtonComponent).attrs({
  style: {
    height: 35,
    fontSize: 14,
    marginLeft: 10,
  },
})``;

// form
export const Unform = styled(UnformComponent)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
`;

export const UnformInputFile = styled(UnformInputFileComponent)``;

export const UnformInput = styled(UnformInputComponent)``;

export const UnformSelect = styled(UnformSelectComponent)``;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 50px 20px;
`;

export const ButtonSave = styled(ButtonComponent).attrs({
  color: colors.primary,
  color2: colors.secondary,
  gradient: true,
  style: {
    height: 35,
    fontSize: 14,
  },
})``;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
`;

export const AvatarBox = styled.div`
  display: flex;
  flex: 1;
  img {
    margin-bottom: 10px;
  }
`;

export const Avatar = styled(AvatarComponent).attrs({
  width: 150,
  height: 150,
})``;

export const ImageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  max-width: 200px;
`;

export const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 50px;
  padding-left: 15px;
  > h1 {
    display: flex;
    align-items: center;
    font-weight: 300;
    color: ${colors.white};
  }
  > h2 {
    font-weight: 400;
    color: ${colors.secondary};
  }
`;

export const ProfileTitle = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 100;
  height: 70px;
  color: ${colors.white};
  letter-spacing: 2px;
`;
