/* eslint-disable no-console */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import moment from 'moment';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import {
  studyContentRequestSuccess,
  studyAllTestsRequestSuccess,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* request({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { testId } = payload;

    const response = yield call(api.get, `studyplans/subjecttest/${testId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(studyContentRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.study-content.request.error'));
  }
}

export function* exportPdf({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { config } = payload;
    const response = yield call(api.post, 'studyplans', config, {
      responseType: 'blob',
    });

    FileSaver.saveAs(
      response.data,
      `download_${moment().format('DDMMYYYY')}.pdf`
    );
    // Stop loading
    yield put(stopLoader());
  } catch (error) {
    console.log('error', error);
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.study-content.export-pdf.error'));
  }
}

export function* requestAllTests() {
  // Start loading
  yield put(startLoader());
  try {
    const { data } = yield call(api.get, `subjecttests`);
    // Stop loading
    yield put(stopLoader());

    const newTests = [];
    if (data.length > 0) {
      data.forEach(prd => {
        if (prd.subjects.length > 0) {
          prd.subjects.forEach(sbj => {
            if (sbj.subjectTests.length > 0) {
              sbj.subjectTests.forEach(tst => {
                newTests.push({
                  id: tst.id,
                  test: tst.description,
                  subject: sbj.description,
                  period: prd.description,
                  date: moment(tst.date).format('DD/MM/YYYY'),
                });
              });
            }
          });
        }
      });
    }

    yield put(studyAllTestsRequestSuccess(newTests));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.study-content.request.error'));
  }
}

export default all([
  takeLatest(types.STUDY_CONTENT_REQUEST, request),
  takeLatest(types.STUDY_CONTENT_EXPORT_PDF, exportPdf),
  takeLatest(types.STUDY_ALL_TESTS_REQUEST, requestAllTests),
]);
