import React, { useState, useRef } from 'react';
import { FaEnvelope, FaKey, FaLock } from 'react-icons/fa';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import api from '~/services/api';
import history from '~/services/history';
import { startLoader, stopLoader } from '~/store/modules/loader/actions';
import { getValidationErrors } from '~/utils';

import {
  Container,
  EmailBoxTitle,
  CodeBoxTitle,
  ResetBoxTitle,
  ButtonLogin,
  LinkRegister,
  RegisterBox,
  ButtonAction,
  // form forgot
  Unform,
  UnformInput,
  UnformInputMask,
} from './styles';

export default function Forgot() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formEmailRef = useRef(null);
  const formCodeRef = useRef(null);
  const formResetRef = useRef(null);

  const schemaFormEmail = Yup.object().shape({
    email: Yup.string()
      .email(t('auth.forgot.form-email.field.email.message.email'))
      .required(t('auth.forgot.form-email.field.email.message.required')),
  });
  const schemaFormCode = Yup.object().shape({
    code: Yup.string().required(
      t('auth.forgot.form-code.field.code.message.required')
    ),
  });
  const schemaFormReset = Yup.object().shape({
    password: Yup.string().required(
      t('auth.forgot.form-reset.field.password.message.required')
    ),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      t('auth.forgot.form-reset.field.password-confirmation.message.oneOf')
    ),
  });

  const [screenVisible, setScreenVisible] = useState('FormEmail');
  const [userEmail, setUserEmail] = useState('');
  const [code, setCode] = useState('');

  const handleSubmitFormEmail = async data => {
    setUserEmail(data.email);
    dispatch(startLoader());

    try {
      await api.post('passwordreset', {
        email: data.email,
      });
      toast.success(
        `${t('auth.forgot.form-email.toast.success')} ${data.email}`
      );
      setScreenVisible('FormCode');
      dispatch(stopLoader());
    } catch (error) {
      dispatch(stopLoader());
      toast.error(t('auth.forgot.form-email.toast.error'));
    }
  };

  const handleSubmitFormCode = data => {
    setCode(data.code.match(/\d+/g).join([]));
    setScreenVisible('FormReset');
  };

  const handleSubmitFormReset = async data => {
    dispatch(startLoader());
    try {
      await api.put('passwordreset', {
        email: userEmail,
        code,
        password: data.password,
      });
      toast.success(t('auth.forgot.form-reset.toast.success'));
      dispatch(stopLoader());
      history.push('/signIn');
    } catch (error) {
      dispatch(stopLoader());
      toast.error(t('auth.forgot.form-reset.toast.error'));
    }
  };

  const renderFormEmail = () => (
    <>
      <EmailBoxTitle>{t('auth.forgot.form-email.title')}</EmailBoxTitle>
      <Unform
        onSubmit={async data => {
          try {
            formEmailRef.current.setErrors({});
            await schemaFormEmail.validate(data, { abortEarly: false });
            handleSubmitFormEmail(data);
          } catch (err) {
            formEmailRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formEmailRef}
      >
        <UnformInput
          icon={FaEnvelope}
          type="text"
          label={t('auth.forgot.form-email.field.email.label')}
          name="email"
        />
        <ButtonLogin
          type="submit"
          title={t('auth.forgot.form-email.submit')}
          gradient
        />
        <RegisterBox>
          {t('auth.forgot.form-email.back-to-login')}
          <LinkRegister to="/signIn">
            {t('auth.forgot.form-email.login')}
          </LinkRegister>
        </RegisterBox>
      </Unform>
    </>
  );

  const renderFormCode = () => (
    <>
      <CodeBoxTitle>{t('auth.forgot.form-code.title')}</CodeBoxTitle>
      <Unform
        onSubmit={async data => {
          try {
            formCodeRef.current.setErrors({});
            await schemaFormCode.validate(data, { abortEarly: false });
            handleSubmitFormCode(data);
          } catch (err) {
            formCodeRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formCodeRef}
      >
        <UnformInputMask
          icon={FaKey}
          type="text"
          label={t('auth.forgot.form-code.field.code.label')}
          name="code"
          mask="9 9 9 9 9 9"
        />
        <ButtonLogin
          type="submit"
          title={t('auth.forgot.form-code.recover')}
          gradient
        />
        <RegisterBox>
          {t('auth.forgot.form-code.back-to-change-password-page')}
          <ButtonAction
            onClick={() => {
              setScreenVisible('FormEmail');
            }}
          >
            {t('auth.forgot.form-code.back')}
          </ButtonAction>
        </RegisterBox>
      </Unform>
    </>
  );

  const renderFormReset = () => (
    <>
      <ResetBoxTitle>{t('auth.forgot.form-reset.title')}</ResetBoxTitle>
      <Unform
        onSubmit={async data => {
          try {
            formResetRef.current.setErrors({});
            await schemaFormReset.validate(data, { abortEarly: false });
            handleSubmitFormReset(data);
          } catch (err) {
            formResetRef.current.setErrors(getValidationErrors(err));
          }
        }}
        initialData={{}}
        ref={formResetRef}
      >
        <UnformInput
          icon={FaLock}
          type="password"
          label={t('auth.forgot.form-reset.field.password.label')}
          name="password"
          style={{ marginBottom: 10 }}
        />
        <UnformInput
          icon={FaLock}
          type="password"
          label={t('auth.forgot.form-reset.field.password-confirmation.label')}
          name="passwordConfirmation"
        />
        <ButtonLogin
          type="submit"
          title={t('auth.forgot.form-reset.change-password')}
          gradient
        />
        <RegisterBox>
          {t('auth.forgot.form-reset.back-to-the-verification-page')}
          <ButtonAction
            onClick={() => {
              setScreenVisible('FormCode');
            }}
          >
            {t('auth.forgot.form-reset.back')}
          </ButtonAction>
        </RegisterBox>
      </Unform>
    </>
  );

  return (
    <Container>
      {screenVisible === 'FormEmail' && renderFormEmail()}
      {screenVisible === 'FormCode' && renderFormCode()}
      {screenVisible === 'FormReset' && renderFormReset()}
    </Container>
  );
}
