export default {
  // Subject
  SUBJECT_REQUEST: '@subject/SUBJECT_REQUEST',
  SUBJECT_REQUEST_SUCCESS: '@subject/SUBJECT_REQUEST_SUCCESS',
  SUBJECT_SET_EDIT: '@subject/SUBJECT_SET_EDIT',
  UPDATE_SUBJECT_REQUEST: '@subject/UPDATE_SUBJECT_REQUEST',
  SUBJECT_SET_MODAL_CREATE: '@subject/SUBJECT_SET_MODAL_CREATE',
  CREATE_SUBJECT_REQUEST: '@subject/CREATE_SUBJECT_REQUEST',
  DELETE_SUBJECT_REQUEST: '@subject/DELETE_SUBJECT_REQUEST',
  // Test
  TEST_SET: '@subject/TEST_SET',
  TESTS_REQUEST: '@subject/TESTS_REQUEST',
  TESTS_REQUEST_SUCCESS: '@subject/TESTS_REQUEST_SUCCESS',
  CREATE_TEST_REQUEST: '@subject/CREATE_TEST_REQUEST',
  UPDATE_TEST_REQUEST: '@subject/UPDATE_TEST_REQUEST',
  DELETE_TEST_REQUEST: '@subject/DELETE_TEST_REQUEST',
  // Work
  WORK_SET: '@subject/WORK_SET',
  WORKS_REQUEST: '@subject/WORKS_REQUEST',
  WORKS_REQUEST_SUCCESS: '@subject/WORKS_REQUEST_SUCCESS',
  CREATE_WORK_REQUEST: '@subject/CREATE_WORK_REQUEST',
  UPDATE_WORK_REQUEST: '@subject/UPDATE_WORK_REQUEST',
  DELETE_WORK_REQUEST: '@subject/DELETE_WORK_REQUEST',
  // PDF
  EXPORT_PDF_CLASSES: '@class/EXPORT_PDF_CLASSES',
};
