import React from 'react';
import Proptypes from 'prop-types';
import flagEn from '~/assets/images/flags/en.svg';
import flagEs from '~/assets/images/flags/es.svg';
import flagPt from '~/assets/images/flags/pt.svg';
import i18n from '~/i18n';

import { Wrapper, ScreenBoxLogo, ScreenBoxPage, TranslateBox } from './styles';

export default function AuthLayout({ children }) {
  return (
    <Wrapper>
      <ScreenBoxLogo />
      <ScreenBoxPage>{children}</ScreenBoxPage>
      <TranslateBox>
        <button
          type="button"
          onClick={() => {
            i18n.changeLanguage('en');
          }}
        >
          <img src={flagEn} alt="En" />
        </button>
        <button
          type="button"
          onClick={() => {
            i18n.changeLanguage('es');
          }}
        >
          <img src={flagEs} alt="Es" />
        </button>
        <button
          type="button"
          onClick={() => {
            i18n.changeLanguage('pt');
          }}
        >
          <img src={flagPt} alt="Pt" />
        </button>
      </TranslateBox>
    </Wrapper>
  );
}

AuthLayout.propTypes = {
  children: Proptypes.element.isRequired,
};
