import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import {
  periodRequestSuccess,
  periodSetEdit,
  periodRequest,
  periodSetModalCreate,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* request() {
  // Start loading
  yield put(startLoader());
  try {
    const response = yield call(api.get, 'periods');
    const periods = response.data.map(item => ({
      ...item,
      initial_date: moment(item.initial_date).format('DD/MM/YYYY'),
      final_date: moment(item.final_date).format('DD/MM/YYYY'),
    }));
    // Stop loading
    yield put(stopLoader());

    yield put(periodRequestSuccess(periods));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.period.request.error'));
  }
}

export function* update({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...period } = payload.period;

    yield call(api.put, `periods/${id}`, {
      ...period,
      initial_date: moment(period.initial_date, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      final_date: moment(period.final_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    });
    // Stop loading
    yield put(stopLoader());

    yield put(periodSetEdit(null));
    yield put(periodRequest());

    toast.success(i18n.t('store.period.update.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.period.update.error'));
  }
}

export function* create({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { period } = payload;

    yield call(api.post, 'periods', {
      ...period,
      initial_date: moment(period.initial_date, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      final_date: moment(period.final_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    });
    // Stop loading
    yield put(stopLoader());

    yield put(periodSetModalCreate(false));
    yield put(periodRequest());

    toast.success(i18n.t('store.period.create.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.period.create.error'));
  }
}

export function* remove({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { periodId } = payload;

    yield call(api.delete, `periods/${periodId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(periodRequest());
    toast.success(i18n.t('store.period.remove.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.period.remove.error'));
  }
}

export default all([
  takeLatest(types.PERIOD_REQUEST, request),
  takeLatest(types.UPDATE_PERIOD_REQUEST, update),
  takeLatest(types.CREATE_PERIOD_REQUEST, create),
  takeLatest(types.DELETE_PERIOD_REQUEST, remove),
]);
