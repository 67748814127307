import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import i18n from '~/i18n';
import types from './types';

import api from '~/services/api';
import {
  driveRequestSuccess,
  driveSetModalFolderCreate,
  driveRequest,
  driveFolderSetEdit,
  driveSetModalFileCreate,
} from './actions';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';

export function* request({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { driveId } = payload;
    const response = yield call(api.get, `userdrives/${driveId}`);
    // Stop loading
    yield put(stopLoader());

    yield put(driveRequestSuccess(response.data));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.drive.request.error'));
  }
}

export function* createFolder({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { folder } = payload;

    yield call(api.post, 'userdrives', folder);
    // Stop loading
    yield put(stopLoader());

    yield put(driveSetModalFolderCreate(false));
    yield put(driveRequest(folder.user_drive_id));

    toast.success(i18n.t('store.drive.create-folder.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.drive.create-folder.error'));
  }
}

export function* updateFolder({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { id, ...folder } = payload.folder;

    yield call(api.put, `userdrives/${id}`, {
      title: folder.title,
    });
    // Stop loading
    yield put(stopLoader());

    yield put(driveFolderSetEdit(null));
    yield put(driveRequest(folder.user_drive_id));

    toast.success(i18n.t('store.drive.update-folder.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.drive.update-folder.error'));
  }
}

export function* remove({ payload }) {
  // Start loading
  yield put(startLoader());
  const { drive } = payload;
  try {
    yield call(api.delete, `userdrives/${drive.id}`);
    // Stop loading
    yield put(stopLoader());

    yield put(driveRequest(drive.user_drive_id));
    toast.success(
      i18n.t('store.drive.remove.success', {
        item:
          drive.type === 'folder'
            ? i18n.t('store.drive.remove.success-folder')
            : i18n.t('store.drive.remove.success-file'),
      })
    );
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(
      i18n.t('store.drive.remove.error', {
        item:
          drive.type === 'folder'
            ? i18n.t('store.drive.remove.error-folder')
            : i18n.t('store.drive.remove.error-file'),
      })
    );
  }
}

export function* createFile({ payload }) {
  // Start loading
  yield put(startLoader());
  try {
    const { file } = payload;

    yield call(api.post, 'userdrives', file);
    // Stop loading
    yield put(stopLoader());

    yield put(driveSetModalFileCreate(false));
    yield put(driveRequest(file.user_drive_id));

    toast.success(i18n.t('store.drive.create-file.success'));
  } catch (error) {
    // Stop loading
    yield put(stopLoader());
    toast.error(i18n.t('store.drive.create-file.error'));
  }
}

export default all([
  takeLatest(types.DRIVE_REQUEST, request),
  takeLatest(types.DRIVE_CREATE_FOLDER_REQUEST, createFolder),
  takeLatest(types.DRIVE_UPDATE_FOLDER_REQUEST, updateFolder),
  takeLatest(types.DRIVE_DELETE_REQUEST, remove),
  takeLatest(types.DRIVE_CREATE_FILE_REQUEST, createFile),
]);
