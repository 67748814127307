import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { periodRequest } from '~/store/modules/period/actions';

import Modal from '~/components/Modal';
import { getValidationErrors } from '~/utils';
import { Unform, UnformSelect, ButtonSave } from './styles';

export default function ModalAcceptClass({ onClose, onAccept, sharedItem }) {
  const dispatch = useDispatch();
  const { periods } = useSelector(state => state.period);
  const { t } = useTranslation();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    subject_id: Yup.string().required(
      t('shared-with-me.modal-accept-class.field.subject.message.required')
    ),
  });

  useEffect(() => {
    dispatch(periodRequest());
  }, []);

  const handleSubmit = data => {
    const dataPost = {
      id: sharedItem.id,
      accepted: true,
      subject_id: data.subject_id,
    };
    onAccept(dataPost);
  };

  return (
    <Modal
      visible
      width="400px"
      height="auto"
      effect="fadeInUp"
      title={t('shared-with-me.modal-accept-class.title')}
      overflow={false}
      onClose={() => {
        onClose();
      }}
    >
      <Unform
        onSubmit={async data => {
          try {
            formRef.current.setErrors({});
            await schema.validate(data, { abortEarly: false });
            handleSubmit(data);
          } catch (err) {
            formRef.current.setErrors(getValidationErrors(err));
          }
        }}
        ref={formRef}
      >
        <UnformSelect
          name="subject_id"
          label={t('shared-with-me.modal-accept-class.field.subject.label')}
          options={periods.map(item => ({
            label: item.description,
            options: item.subjects.map(subject => ({
              value: subject.id,
              label: subject.description,
            })),
          }))}
        />
        <ButtonSave
          type="submit"
          title={t('shared-with-me.modal-accept-class.accept')}
          gradient
        />
      </Unform>
    </Modal>
  );
}

ModalAcceptClass.defaultProps = {
  onClose: () => {},
  onAccept: () => {},
  sharedItem: null,
};

ModalAcceptClass.propTypes = {
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  sharedItem: PropTypes.shape(),
};
