import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import period from './period/reducer';
import subject from './subject/reducer';
import classe from './class/reducer';
import friend from './friend/reducer';
import loader from './loader/reducer';
import certificate from './certificate/reducer';
import drive from './drive/reducer';
import faq from './faq/reducer';
import shared from './shared/reducer';
import agenda from './agenda/reducer';
import studyContent from './studyContent/reducer';
import chat from './chat/reducer';
import search from './search/reducer';
import dashboard from './dashboard/reducer';
import tour from './tour/reducer';
import myPlan from './myPlan/reducer';

export default combineReducers({
  auth,
  user,
  period,
  subject,
  classe,
  friend,
  loader,
  certificate,
  drive,
  faq,
  shared,
  agenda,
  studyContent,
  chat,
  search,
  dashboard,
  tour,
  myPlan,
});
