import styled from 'styled-components';
import colors from '~/styles/colors';
import Button from '~/components/Button';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primary};
  background: linear-gradient(
    0deg,
    ${colors.primary} 0%,
    ${colors.accent2} 100%
  );
  height: 100%;
`;

export const ScreenBoxPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  background-color: ${colors.white};
  -webkit-box-shadow: 0px 7px 30px 1px ${colors.black}50;
  -moz-box-shadow: 0px 7px 30px 1px ${colors.black}50;
  box-shadow: 0px 7px 30px 1px ${colors.black}50;
  border-radius: 5px;
  padding: 10px;
  h1 {
    color: ${colors.primary};
    font-size: 22px;
  }
  p {
    color: ${colors.dark3};
    text-align: justify;
    font-size: 16px;
  }
`;

export const BrowserBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
`;

export const BrowserItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid ${colors.primary};
  border-radius: 5px;
  img {
    width: 100px;
    height: 100px;
  }
`;

export const BrowserButton = styled(Button)`
  height: 30px;
  margin-top: 10px;
  font-size: 14px;
`;
