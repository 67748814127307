import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FaMicrophoneAlt, FaSquareFull } from 'react-icons/fa';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';

import { startLoader, stopLoader } from '~/store/modules/loader/actions';
import { Button } from './styles';

export default function ButtonRecorder({ onData }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isRecord, setIsRecord] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [refTimer, setRefTimer] = useState(null);

  const stopRecording = async () => {
    dispatch(startLoader());
    await recorder.stopRecording();
    const blob = await recorder.getBlob();
    setRecorder(null);
    setIsRecord(false);
    onData(blob);
    dispatch(stopLoader());
    if (refTimer) clearTimeout(refTimer);
  };

  const startRecording = async () => {
    if (refTimer) clearTimeout(refTimer);
    setIsRecord(true);
    dispatch(startLoader());
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const tmpRecorder = new RecordRTCPromisesHandler(stream, {
      type: 'audio',
      mimeType: 'audio/wav',
      recorderType: StereoAudioRecorder,
      disableLogs: true,
      numberOfAudioChannels: 1,
      desiredSampRate: 16000,
    });
    tmpRecorder.startRecording();
    setRecorder(tmpRecorder);
    setIsRecord(true);
    dispatch(stopLoader());
    const timer = setTimeout(async () => {
      await tmpRecorder.stopRecording();
      const blob = await tmpRecorder.getBlob();
      setRecorder(null);
      setIsRecord(false);
      onData(blob);
      startRecording();
    }, 1000 * 60 * 15);
    setRefTimer(timer);
  };

  return recorder && isRecord ? (
    <Button
      onClick={stopRecording}
      title="Terminar gravação"
      icon={FaSquareFull}
    />
  ) : (
    <Button
      title={t('class-view.button-recorder.title')}
      icon={FaMicrophoneAlt}
      onClick={() => {
        confirmAlert({
          title: t(
            'class-view.button-recorder.confirm-alert.record-audio.title'
          ),
          message: t(
            'class-view.button-recorder.confirm-alert.record-audio.message'
          ),
          buttons: [
            {
              label: t(
                'class-view.button-recorder.confirm-alert.record-audio.buttons.cancel'
              ),
            },
            {
              label: t(
                'class-view.button-recorder.confirm-alert.record-audio.buttons.yes'
              ),
              onClick: startRecording,
            },
          ],
        });
      }}
    />
  );
}

ButtonRecorder.defaultProps = {
  onData: () => {},
};

ButtonRecorder.propTypes = {
  onData: PropTypes.func,
};
